const imageSources = [
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/alpha.webp" , title: "Alpha", id: 0 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/lumina.webp" , title: "Lumina", id: 1 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/quantum.webp" , title: "Quantum", id: 2 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/myst.webp" , title: "Myst", id: 3 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/serenity.webp" , title: "Serenity", id: 4 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/nova.webp" , title: "Nova", id: 5 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/catalyst.webp" , title: "Catalyst", id: 6 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/phantom.webp" , title: "Phantom", id: 7 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/zenith.webp" , title: "Zenith", id: 8 },
    {image: "https://cybergems.blob.core.windows.net/cgnftcatalog/infinity.webp" , title: "Infinity", id: 9 },
];

export {imageSources}