import {Link, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, Typography, Divider, Grid, ListItemText, Card, Box, CardActionArea, DialogActions, Button, TextField, Alert, IconButton, InputAdornment, Tooltip} from '@mui/material';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import { FileUploader } from "react-drag-drop-files";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ThumbnailCropperDialogComponent from './thumbnail-cropper-dialog.component';
import ConfirmationDialog from './confirmation-dialog.component';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { convertFileSizeToBytes, formatBytes } from '../../../utils/fileSizeToBytesUtils';
import { useSnackbar } from 'notistack';
import EditActionPopperComponent from '../edit-action-popper';
import useWidth from "../../../hooks/useWidth";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // padding: 38
}));


export default function AttachMemoryDialogComponent (props) {
    const { open, close, handleUpload, openThumbnailCropper, setOpenThumbnailCropper, details, attachMemory, 
        isLoadingAttachMemory, successAttachment, setOpen, setMemoryAttach, setHasAttachedMemory } = props;

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    const [file, setFile] = useState(null);
    const [uploadThumbnail, setUploadThumbnail] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [viewCroppedImage, setViewCroppedImage] = useState(null);
    const [title, setTitle] = useState("");
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [titleRequired, setTitleRequired] = useState(false);
    const [thumbnail, setThumbnail] = useState(null)
    const [thumbnailRequired, setThumbnailRequired] = useState(false);

    const {enqueueSnackbar} = useSnackbar()
    const breakpoints = useWidth();

    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
    }

    const handleOpenConfirmationModal = () => {
        if(!title){
            setTitleRequired(true)
        } else  if (!croppedImage) {
            setThumbnailRequired(true)
        } else {
            setOpenConfirmationModal(true);
            close();
        }
    }

    const labelData = [
        {label: 'Gem Name', value: details?.catalog?.name},
        {label: 'Description', value: details?.catalog?.description},
        {label: 'Owner', value: details?.owner_address},
        {label: 'Token ID', value: details?.catalog?.tokenID},
    ];

    const addWhiteBackground = (image) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        // Set canvas dimensions to match image dimensions
        canvas.width = image.width;
        canvas.height = image.height;
    
        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0);
    
        // Check if the image has transparent pixels
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;
        let hasTransparentPixels = false;
        for (let i = 3; i < pixels.length; i += 4) {
            if (pixels[i] < 255) {
                hasTransparentPixels = true;
                break;
            }
        }
    
        if (hasTransparentPixels) {
            // Add white background
            ctx.fillStyle = '#FFFFFF'; // White color
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
    
        // Convert canvas back to blob
        return new Promise((resolve) => {
            canvas.toBlob(resolve, 'image/jpeg');
        });
    };
    

    const validateThumbnail = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileUpload(e.target.files[0]);
        setOpenThumbnailCropper(true)
    }

    const handleChange = (file) => {
       
        let sizeFile = file?.size;
        let fileSizes = formatBytes(sizeFile);
        let fileBytes = convertFileSizeToBytes(fileSizes);

        if (fileBytes < 10000000 || fileBytes == 10000000) {
            setFileUploaded(file)
            setViewCroppedImage(null)
            const uploadedFile = file;

            if (uploadedFile.type.startsWith('video/') || uploadedFile.type.startsWith('image/')) {
                setUploadThumbnail({ file: uploadedFile, type: uploadedFile.type });
                if(uploadedFile.type.startsWith('image/')){
                    setOpenThumbnailCropper(true);
                    setFileUpload(uploadedFile)
                } 
            } else {
                alert('Please upload a valid video or image file.');
            }

        } else {
            enqueueSnackbar("File size exceeds 10MB, please choose another file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleTextInput = (e) => {
        setTitle(e.target.value)
    }

    const onSaveCropImageAvatar = (image) => {
        setCroppedImage(image)
        setViewCroppedImage(URL.createObjectURL(image))
    }

    const handleEditImage = () => {
        setOpenThumbnailCropper(true)
    }

    const Preview = ({ file, type }) => {
        if (type.startsWith('video/')) {
          return (
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                border: "dashed 2.2px #0178f2",
                borderRadius: "5px",
                cursor: "pointer",
                flexGrow: 0,
                height: "400px",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear",
                fontFamily: 'GucciSans'
              }}
            >
              <video controls width="75%" style={{ height: 300, objectFit: "contain" }}>
                <source src={URL.createObjectURL(file)} type={type} />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        } else if (type.startsWith('image/')) {
          return (
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                border: "dashed 2.2px #0178f2",
                borderRadius: "5px",
                cursor: "pointer",
                flexGrow: 0,
                height: "210px",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear",
                fontFamily: 'GucciSans'
              }}
            >
              <img alt="CyberGems - A collection of 8,888 NFT gems" src={URL.createObjectURL(file)} style={{ height: 160, width: "75%", objectFit: "contain" }} />
            </div>
          );
        } else {
          return null;
        }
      };

    return(
        <>
            <Dialog open={open} onClose={close} maxWidth={"md"} fullWidth={"md"} 
                PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto"}}}
            >
                <DialogTitle className="dialog-bg" sx={{fontSize: 14, fontWeight: 600, fontWeight:'bold', fontFamily: 'GucciSans'}}>ATTACH MEMORY</DialogTitle>
                <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
                >
                    <CloseIcon sx={{color:'white'}} />
                </IconButton>
                <Divider/>
                <DialogContent sx={{m: 2, mt: 0, mb: 0, display:'inline-table'}} dividers>
                    <Grid container>
                        
                        {labelData.map((label, index)=>(
                            <Grid item xs={12} sm={6}>
                            <ListItemText secondaryTypographyProps={{ textOverflow:'ellipsis', overflow:'hidden',fontFamily: 'GucciSans' }} primary={<Typography sx={{fontWeight:'bold', fontFamily: 'GucciSans', fontSize: 13, fontFamily: 'GucciSans'}} >{label.label}</Typography>} secondary={label.value}/>
                            </Grid>
                        ))}
                        
                    </Grid>
                </DialogContent>
                <DialogContent spacing={2} sx={{m: 2, mt: 0}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} sx={{mb: 2}}>
                            <Alert severity="info" sx={{fontSize: 12, fontFamily: 'GucciSans'}}>
                                Create a compelling memory title, upload the file, and carefully select a captivating thumbnail to capture your cherished moment.
                            </Alert>

                            <TextField
                                id="standard-multiline-flexible"
                                defaultValue={title}
                                onChange={handleTextInput}
                                sx={{mt: 2, mb: -1, fontFamily: 'GucciSans',
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "rgb(1, 120, 242)",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "rgb(1, 120, 242",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "rgb(1, 120, 242)",
                                        },
                                      },
                                    }}
                                variant="outlined"
                                InputLabelProps={{style: {fontSize: 12, fontFamily: 'GucciSans'}}}
                                inputProps={{style: {padding: "10px", fontSize: 12, marginLeft: "-18px", marginRight: "6px", fontFamily: 'GucciSans'}}}
                                InputProps={{
                                    startAdornment: 
                                        <InputAdornment position="start">
                                            <p style={{fontWeight: "bold", fontSize: 12, marginRight: 5, color: "#fff", marginLeft: "-5px",fontFamily: 'GucciSans' }}>
                                                <span style={{color: "red", fontSize: 15, fontFamily: 'GucciSans'}}>*</span>Memory Title:
                                            </p>
                                        </InputAdornment>
                                    ,
                                }}
                                helperText={
                                    <p style={{fontSize: 12, color: "#bf5a4c", marginBottom: 0, marginTop: 2, marginLeft: "-14px", fontFamily: 'GucciSans'}}>
                                        {(titleRequired) && "Memory title is required"}
                                    </p>
                                }
                            />
                        </Grid>

                        {/* File Uploader */}
                        <Grid item xs={12} sm={12}>
                            <FileUploader
                                    multiple={false}
                                    name="file"
                                    label="Drag and Drop file here or click here to upload"
                                    handleChange={handleChange}
                                    children={
                                        <>
                                        {(fileUploaded) ? 
                                            <Preview file={uploadThumbnail.file} type={uploadThumbnail.type} />

                                        :
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2px #0178f2",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "280px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear",
                                                fontFamily: 'GucciSans'
                                            }}
                                        >
                                            <div style={{display:'inline', textAlign:'center', }}>
                                            <DownloadIcon sx={{width: 30, height: 30, color:'gray'}}/>
                                            <p style={{color: "#gray", fontSize: 12, marginTop: 0, fontFamily: 'GucciSans'}}>
                                                Drag and Drop file here <br/> or Click here to Upload
                                            </p>
                                            </div>
                                            
                                        </div>
                                        }
                                        </>
                                    }
                            />
                            {!fileUploaded &&
                                <Typography sx={{textAlign:'end', fontSize: 12, mt: 1, color: 'red', fontFamily: 'GucciSans'}}>10MB file size limit</Typography>
                            }
                        </Grid>

                        {/* Thumbnail */}
                        <Grid item xs={12} sm={12} sx={{mt: 2}}>
                            {fileUploaded && 
                                <>
                                    <Typography sx={{fontWeight:'bold', mb: 1,mt: 1, fontSize: 14, fontFamily: 'GucciSans'}}>
                                        Thumbnail Preview
                                    {(thumbnailRequired && !croppedImage) && <Typography sx={{fontSize: 12, color: 'red', fontFamily: 'GucciSans'}}>Thumbnail Required</Typography>}
                                    </Typography> 
                                    
                                    <Masonry columns={{ xs: 2, sm: 4 }} spacing={2}>
                                        {fileUploaded.type.startsWith('video/') ? 
                                            <Card sx={{textAlign:'center'}}>
                                                {viewCroppedImage ? 
                                                    <Box sx={{ position: 'relative', height: '110px' }}>
                                                        <Box sx={{ height: '110px' }}>
                                                            <Box
                                                                onClick={handleEditImage}
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    fontSize: 10,
                                                                    padding: 0, width: 'auto', color:'gray',
                                                                    textAlign:'end'
                                                                }}
                                                            >
                                                                <Tooltip title="Edit" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                                                    <MoreHorizIcon/>
                                                                </Tooltip>
                                                            </Box>
                                                            <img src={viewCroppedImage} style={{ width: '100%', objectFit: 'contain' }}/>
                                                        </Box>
                                                        <img className="btn-cancel"
                                                            src={viewCroppedImage}
                                                            style={{ width: '100%', height: '110px', objectFit: 'contain' }}
                                                        />
                                                    </Box>
                                            
                                                :
                                                    <CardActionArea sx={{ height:"112px"}}>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            id="upload-thumbnail"
                                                            onChange={validateThumbnail}
                                                            hidden
                                                        />
                                                        <label style={{ cursor: "pointer" }} htmlFor="upload-thumbnail" >
                                                            <AddPhotoAlternateIcon/>
                                                            <Typography sx={{fontSize: 13, fontFamily: 'GucciSans'}}>
                                                                Upload thumbnail
                                                            </Typography>   
                                                        </label>
                                                        
                                                    </CardActionArea>
                                                }
                                            </Card>
                                        :
                                            <Card sx={{bgcolor:'transparent', boxShadow:'unset'}}>
                                                <Box style={{ height: '150px' }}>
                                                    <Box
                                                        sx={{
                                                            cursor:'pointer',
                                                            fontSize: 10,
                                                            padding: 0, width: 'auto', color:'gray',
                                                            textAlign:'end'
                                                        }}
                                                    >
                                                        <Tooltip title="Edit" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                                            <EditActionPopperComponent handleEditImage={handleEditImage}/>
                                                        </Tooltip>
                                                    </Box>
                                                    <img src={viewCroppedImage} style={{ width: '100%', objectFit: 'contain', height: '110px', backgroundColor: 'aliceblue' }}/>
                                                </Box>
                                            </Card>
                                        }
                                    </Masonry>
                                </>
                            }
                        </Grid>
                    </Grid>

                    <FormGroup sx={{ml: 0.2}}>
                        <FormControlLabel 
                            control={
                                <Checkbox size="small" checked={checked} onChange={(e) => handleCheckboxChange(e)} />
                            }
                            label={
                                <Typography sx={{fontFamily: "GucciSans", fontSize: 14, mt: (breakpoints == "xs" || breakpoints == "sm") ? "10px" : {}}}>
                                    I have read and agree to the <Link href= "/user-agreement" underline="none" target="_blank" style={{fontSize: 14}}>
                                        User Agreement.
                                    </Link>
                                </Typography>
                            } 
                        />
                    </FormGroup>
                </DialogContent>

                <DialogActions>
                    <button className={(!checked) ? "upload-btn-disabled" : "btn-donate"} onClick={()=>handleOpenConfirmationModal()} style={{fontFamily: "GucciSans"}}>
                        Upload
                    </button>
                </DialogActions>

                <ThumbnailCropperDialogComponent validateThumbnail={validateThumbnail} openThumbnailCropper={openThumbnailCropper} file={fileUpload} fileUploaded={fileUploaded} setOpenThumbnailCropper={setOpenThumbnailCropper} onSave={onSaveCropImageAvatar} details={details} />
            </Dialog>

            <ConfirmationDialog open={openConfirmationModal} attachMemory={attachMemory} titleRequired={titleRequired} setTitleRequired={setTitleRequired}
                setOpenConfirmationModal={setOpenConfirmationModal} tokenId={details?.catalog?.tokenID} memory={fileUploaded} setOpen={setOpen}
                thumbnail={croppedImage} memoryTitle={title} isLoadingAttachMemory={isLoadingAttachMemory} successAttachment={successAttachment} setMemoryAttach={setMemoryAttach} setHasAttachedMemory={setHasAttachedMemory}
            />
        </>
    )
}