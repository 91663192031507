import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSnackbar } from "notistack";


export default function RetryConfirmationModal (props) {
    const {openConfirmationModal, setOpenConfirmationModal, isLoadingRetryCOA, tokenID, retryCOA, cybergemRetryCOA} = props;
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        setOpenConfirmationModal(false)
    }

    const handleConfirm = () => {
        retryCOA(tokenID)
    }

    useEffect(()=>{
        if(cybergemRetryCOA?.success){
            enqueueSnackbar("Success!", { 
                variant: 'success',
                autoHideDuration: 3000
            });
            window.location.reload()
        }
    },[cybergemRetryCOA])

    return(
        <Dialog open={openConfirmationModal} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto"}}}>
            <DialogTitle className="dialog-bg" sx={{fontSize: 14, fontWeight: 600, bgcolor:'#0178f2', color:'white', p: 1.5, pt: 1, pb: 1, fontFamily: 'GucciSans'}}>CONFIRMATION</DialogTitle>
            <Divider/>
            <DialogContent sx={{textAlign:'center'}}>
                <Typography sx={{fontSize: 13, p: 2, pb: 0, color: 'gray', fontFamily: 'GucciSans'}}>
                    You wish to retry viewing the certificate. Click "Confirm" to continue.
                </Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent:'center', mb: 3}}>
                {isLoadingRetryCOA ?
                    <CircularProgress color="secondary" />
                :
                    <>
                        <button className="btn-cancel" onClick={handleClose} style={{fontFamily: 'GucciSans'}} >
                            Cancel
                        </button>
                        <button className="btn-donate" onClick={handleConfirm} style={{fontFamily: 'GucciSans'}} >
                            Confirm
                        </button>
                    </>
                }
                
            </DialogActions>
        </Dialog>
    )
}