import React from "react";
import {Skeleton, Card, CardContent, CardActionArea} from "@mui/material";

const ProfileSkeleton = (props) => {

    const {isDarkMode} = props;
    
    return (
        <Card sx={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "12px", border: "0.1px solid #80808012", 
            backgroundColor: "#acbbfa0a",
            // backgroundColor: (isDarkMode === "false") ? {} : "#acbbfa0a"
        }}>
            <CardActionArea disableRipple style={{width: "100%", padding: 0}}>
                <Skeleton animation="wave" variant="rect" sx={{height: 230}} />
            </CardActionArea>

            <CardContent sx={{textAlign: "start"}}>
                <Skeleton animation="wave" variant="text" sx={{width: "55%"}} />  

                <Skeleton animation="wave" variant="text" sx={{width: "100%"}} />  

                <Skeleton animation="wave" variant="text" sx={{width: "100%"}} />  
            </CardContent>
        </Card>
    )
}
      
export default ProfileSkeleton