import React, {useEffect, useState} from "react";
import {Skeleton, Box, Menu, MenuItem, CardActionArea, Tooltip, Button, Paper, Divider, Grid, Typography, Card, CardMedia, CardContent, Pagination} from "@mui/material";
import useCybergemABIContract from "../../hooks/useCybergemABIContract";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router";
import {MetaMaskAvatar} from "react-metamask-avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewMemoryIcon from "../../icons/album.png";
import AttachMemoryDialogComponent from "../attach-memory/dialog/attach-memory-dialog.component";
import useCybergemNft from "../../hooks/useCybergemNft";
import WhiteViewMemoryIcon from "../../icons/album-white.png";
import EthereumIcon from "../../icons/ethereum.png";
import GrayEthereumIcon from "../../icons/gray-ethereum.png";
import ProfileSkeleton from "./skeleton/profile.skeleton";
import NoItemsIcon from "../../icons/profile-no-data.png";

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import AttachMemory from "../../icons/attachment-float.png";
import AttachMemoryDarkMode from "../../icons/attachment.png";
import SuccessCOAIcon from "../../icons/success-coa.png";
import FailedCOAIcon from "../../icons/failed.png";
import PendingCOAIcon from "../../icons/pending.png";
import SuccessCOAReverseIcon from "../../icons/success-coa-reverse.png";
import FailedCOAReverseIcon from "../../icons/failed-reverse-coa.png";
import PendingCOAReverseIcon from "../../icons/pending-reverse-coa.png";
import RetryConfirmationModal from "../view-memory/dialog/retry-confirmation-modal";

const ProfileComponent = (props) => {

    const {profile, isFetchingProfile, breakpoints} = props;

    const navigate = useNavigate();

    const {ownedTokens, getOwnerTokenIds} = useCybergemABIContract();
    const {isFetchingOwnedTokens, userOwnedCatalogList, getUserCatalogListByTokenList} = useAuth();

    const isDarkMode = localStorage.getItem("darkMode");
    const isUserLoggedIn = localStorage.getItem("auth_token");
    // const liveCoaURL = "https://salmon-desert-0600ffb1e.4.azurestaticapps.net";
    const liveCoaURL = "https://cybergems.com";
    // const localCoaURL = "http://localhost:3000";

    useEffect(() => {
        if (profile) {
            getTokenIdsFromAccount();
        }
    }, [profile])

    useEffect(() => {
        if (ownedTokens !== 0) {
            getUserCatalogListByTokenList(ownedTokens);
        }
    }, [ownedTokens])
    

    const getTokenIdsFromAccount = () => {
        getOwnerTokenIds(profile?.wallet);
    }


    const handleRightClick = (event) => {
        event.preventDefault();
    }


    function MoreMenu ({gem, tokenId, isDarkMode}) {
        const {attachMemory, cybergemDetailViewData, getViewCybergem, isLoadingAttachMemory, successAttachment, retryCOA, cybergemRetryCOA, isLoadingRetryCOA} = useCybergemNft();

        const [open, setOpen] = useState(false);
        const [openThumbnailCropper, setOpenThumbnailCropper] = useState(false);
        const [isMemoryAttach, setMemoryAttach] = useState(false);
        const [hasAttachedMemory, setHasAttachedMemory] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = Boolean(anchorEl);
        const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

        useEffect(() => {
            if (tokenId) {
                getViewCybergem(tokenId);
            }
        }, [tokenId])
    
        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }
       
        const handleClose = () => {
            setAnchorEl(null);
        }

        const handleViewCOA = () => {
            let liveTabURL =  liveCoaURL + "/view-coa?tokenId=" + tokenId

            window.location.href = liveTabURL;
            
        }

        const handleOpenModal = () => {
            setOpen(true);
            setAnchorEl(null);
        }

        const handleCloseModal = () => {
            setOpen(false);
        }

        const handleUpload = () => {
            setOpen(false);
        }

        const handleOpenConfirmationModal = () => {
            setOpenConfirmationModal(true)
        }

    
        return (
            <> 
                <Tooltip title="More" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                    <Button variant="contained" disableRipple id="demo-positioned-button" aria-haspopup="true" 
                        aria-controls={open ? "demo-positioned-menu" : undefined} 
                        onClick={(e) => handleClick(e)} 
                        aria-expanded={open ? "true" : undefined}
                        sx={{
                            // backgroundColor: "#acbbfa45",
                            backgroundColor: "#2324257a",
                            padding: "0px 0px 0px 0px", minWidth: 20, margin: "10px 10px 1px 1px",
                            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgb(0 0 0 / 32%), 0px 1px 5px 0px rgb(0 0 0 / 3%)"
                        }} 
                    >
                        <MoreVertIcon sx={{
                            color: "#fff"
                            // color: (isDarkMode === "false") ? {} : "#fff"}} 
                        }} />
                    </Button>
                </Tooltip>
    
                <Menu sx={{borderRadius: "10px"}}
                    open={openMenu} onClose={handleClose}
                    id="demo-positioned-menu" 
                    aria-labelledby="demo-positioned-button" 
                    anchorEl={anchorEl} 
                    anchorOrigin={{vertical: "top", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}}
                >
                    {(gem?.memory_thumbnail) &&
                        <>
                            {cybergemDetailViewData?.catalog?.coa_status === "S" ?
                                <MenuItem sx={{ fontSize: 14, fontFamily: 'GucciSans' }} onClick={(e) => handleViewCOA()}>
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px" }} 
                                        src={SuccessCOAReverseIcon} 
                                        // src={(isDarkMode === "false") ? SuccessCOAIcon : SuccessCOAReverseIcon} 
                                    />
                                    View Certificate
                                </MenuItem>
                            :
                                cybergemDetailViewData?.catalog?.coa_status === "P"  ?
                                    <MenuItem sx={{fontSize: 14, cursor: "default", fontFamily: 'GucciSans'}}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px" }} 
                                            src={PendingCOAReverseIcon} 
                                            // src={(isDarkMode === "false") ? PendingCOAIcon : PendingCOAReverseIcon} 
                                        />
                                        Pending Certificate
                                    </MenuItem>
                            :  
                                <MenuItem sx={{ fontSize: 14 , fontFamily: 'GucciSans'}}>
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px"}} 
                                        src={FailedCOAReverseIcon} 
                                        // src={(isDarkMode === "false") ? FailedCOAIcon : FailedCOAReverseIcon} 
                                        onClick={handleOpenConfirmationModal}
                                    />
                                    Regenerate Certificate
                                </MenuItem>
                            }
                        </>
                    }

                    {(gem?.memory_thumbnail) &&
                        <MenuItem sx={{fontSize: 14, fontFamily: 'GucciSans'}}
                            onClick={(e) => navigate("/view-memory?tokenId=" + tokenId)}
                        >
                            <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 22, marginTop: "-2px", marginRight: "5px"}} 
                                src={WhiteViewMemoryIcon} 
                                // src={(isDarkMode === "false") ? ViewMemoryIcon : WhiteViewMemoryIcon} 
                            />
                            View Memory
                        </MenuItem>
                    }

                    {/* {(isUserLoggedIn && gem?.memory_thumbnail === null) &&
                        <MenuItem sx={{fontSize: 14, fontFamily: 'GucciSans'}}
                            onClick={(e) => handleOpenModal(e)} 
                        >
                            <img alt="" style={{marginRight: "8px", height: 18}} 
                                src={AttachMemoryDarkMode} 
                            />
                            Attach Memory
                        </MenuItem>
                    } */}

                    <MenuItem sx={{fontSize: 14, fontFamily: 'GucciSans'}}
                        onClick={(e) => navigate(`/${tokenId}`)}
                    >
                        <ExpandCircleDownOutlinedIcon sx={{marginRight: "8px", rotate: '270deg',
                            color: "#fff", 
                            // color: (isDarkMode === "false") ? "#097ff4f2" : "#fff", 
                        }} />
                        See More
                    </MenuItem>
                </Menu>

                <AttachMemoryDialogComponent 
                    open={open} setOpen={setOpen} isLoadingAttachMemory={isLoadingAttachMemory}
                    details={cybergemDetailViewData} 
                    close={handleCloseModal} 
                    handleUpload={handleUpload}
                    attachMemory={attachMemory}
                    openThumbnailCropper={openThumbnailCropper} 
                    setOpenThumbnailCropper={setOpenThumbnailCropper} 
                    successAttachment={successAttachment}
                    setMemoryAttach={setMemoryAttach} 
                    setHasAttachedMemory={setHasAttachedMemory}
                />

                <RetryConfirmationModal
                    openConfirmationModal={openConfirmationModal} 
                    setOpenConfirmationModal={setOpenConfirmationModal}
                    retryCOA={retryCOA} 
                    isLoadingRetryCOA={isLoadingRetryCOA}
                    tokenID={gem?.catalog?.tokenID} 
                    cybergemRetryCOA={cybergemRetryCOA}
                />
            </>
        )
    }

    function GemCard ({gem, isDarkMode}) {

        return (
            <Card sx={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "12px", border: "0.1px solid #80808012", 
                backgroundColor: "#acbbfa0a"
                // backgroundColor: (isDarkMode === "false") ? {} : "#acbbfa0a"
            }}>
                <CardActionArea disableRipple style={{width: "100%", padding: 0}}>
                    <Box sx={{width: "100%", justifyContent: "end", display: "flex", mb: "-50px"}}>
                        <MoreMenu 
                            gem={gem} 
                            tokenId={gem?.catalog?.tokenID} 
                            isDarkMode={isDarkMode} 
                        />
                    </Box>

                    <div onContextMenu={handleRightClick}>
                        <CardMedia component="img" alt="CyberGems - A collection of 8,888 NFT gems" height="100%" image={gem?.img} sx={{cursor: "pointer", }}
                            // onClick={() => window.location.href = `/${gem?.catalog?.tokenID}`}
                            onClick={(e) => navigate(`/${gem?.catalog?.tokenID}`)}  
                        />
                    </div>

                    {(gem?.memory_thumbnail) &&
                        <Box sx={{textAlign: "start"}}>
                            <img alt="CyberGems - A collection of 8,888 NFT gems" src={gem?.memory_thumbnail}  onContextMenu={handleRightClick}
                                onClick={() => navigate("/view-memory?tokenId=" + gem?.catalog?.tokenID)}
                                style={{
                                    width: "40px",
                                    marginTop: "-21px",
                                    marginLeft: "12px",
                                    backgroundColor: "#fff",
                                    padding: "3px",
                                    position: "absolute",
                                    height: "40px",
                                }}
                            /> 
                        </Box>
                    }
                </CardActionArea>

                <CardContent sx={{textAlign: "start", mt: 1.2}}>
                    <Typography variant="h5" component="div" sx={{fontWeight: "bold", fontSize: 16, fontFamily: 'GucciSans'}}>
                        #{gem?.catalog?.tokenID} {gem?.catalog?.name}
                    </Typography>

                    <Typography variant="body2" sx={{fontSize: 14, fontFamily: 'GucciSans',
                        color: "#fff",
                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : "#fff",
                    }}>
                        {gem?.catalog?.series} Series
                    </Typography>

                    <Typography variant="body2" sx={{fontSize: 14, fontFamily: 'GucciSans',
                        color: "#fff",
                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : "#fff",
                    }}>
                        {gem?.catalog?.variation}
                    </Typography>
                </CardContent>
            </Card>
        )
    }


    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userOwnedCatalogList.slice(indexOfFirstItem, indexOfLastItem);
  
    const paginate = pageNumber => setCurrentPage(pageNumber);


    return (
        <>
            <Paper elevation={0} sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: 4,
                border: "1px solid #8080802e",
                ml: (breakpoints == "xs" || breakpoints == "md") ? {} : 5
            }}>
                <Box sx={{}}> 
                    <Grid container sx={{paddingTop: "24px", borderTopLeftRadius: "16px", borderTopRightRadius: "16px",
                        backgroundColor: "#acbbfa17"
                    }}>
                        {(isFetchingProfile) ?
                            <>
                                <Grid item xs={12}>
                                    <Skeleton animation="wave" variant="circular" 
                                        sx={{margin: "auto", height: (breakpoints == "xs") ? 80 : 100, width: (breakpoints == "xs") ? 80 : 100}} 
                                    />
                                </Grid>

                                <Box sx={{margin: "auto", mt: 2, mb: 3}}>
                                    <Skeleton animation="wave" variant="text" 
                                        sx={{margin: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? 150 : 400}} 
                                    />  
                                </Box>
                            </>

                        :
                            <>
                                <Grid item xs={12}>
                                    <MetaMaskAvatar
                                        address={profile?.wallet}
                                        size={(breakpoints == "xs") ? 80 : 100} 
                                        className="metamask-avatar"
                                    />
                                </Grid>

                                <Box sx={{margin: "auto", mt: 2, mb: 3}}>
                                    <Grid item xs={12} sx={{display: "flex"}}>  
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 24}} 
                                            src={GrayEthereumIcon} 
                                            // src={(isDarkMode === "false") ? EthereumIcon : GrayEthereumIcon} 
                                        />

                                        &nbsp;

                                        <Tooltip arrow title={profile?.wallet} placement="top" sx={{visibility: (breakpoints === "xs" || breakpoints === "sm") ? "visible" : "hidden", fontFamily: 'GucciSans'}}>
                                            <Typography sx={{fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                                                width: (breakpoints === "xs" || breakpoints === "sm") ? 160 : {}, fontFamily: 'GucciSans'}}
                                            >
                                                {profile?.wallet}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Box>
                            </>
                        }
                    </Grid>

                    <Divider sx={{borderBottomWidth: "1.5px", borderColor: "rgb(0 0 0 / 5%)"}} />
                </Box>

                <Grid container spacing={3} sx={{margin: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? "90%" : "85%", mb: 5, mt: 1}}>
                    {(isFetchingOwnedTokens) ?
                        [0, 1, 2, 3].map((gem, index) => {
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={3} xl={3} sx={{paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "8px !important" : {}}}>
                                    <ProfileSkeleton isDarkMode={isDarkMode} breakpoints={breakpoints} />
                                </Grid>
                            )
                        })

                    :
                    (!isFetchingOwnedTokens && currentItems.length !== 0) ?
                        currentItems.map((gem, index) => (
                            <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={index} 
                                sx={{paddingLeft: (breakpoints === "xs" || breakpoints === "sm") ? "8px !important" : {}}}
                            >
                                <GemCard gem={gem} isDarkMode={isDarkMode} />
                            </Grid>
                        )) 
                    :
                    <Box sx={{ margin: "auto", display: "inline-grid"}}>
                      <img alt="CyberGems - A collection of 8,888 NFT gems" src={NoItemsIcon}
                        style={{
                          width: (breakpoints === "xs" || breakpoints === "sm") ? 150 : 200,
                          height: (breakpoints === "xs" || breakpoints === "sm") ? 150 : 200,
                          marginLeft: (breakpoints === "xs" || breakpoints === "sm") ? 0 : "34px"
                        }}
                      />
          
                      <Typography sx={{ ml: (breakpoints === "xs" || breakpoints === "sm") ? 0 : "23px", mt: (breakpoints === "xs" || breakpoints === "sm") ? "-40px" : "-54px", fontSize: 14, fontFamily: 'GucciSans' }}>
                        No items to display
                      </Typography>
                    </Box>
                }
                </Grid>
                {!isFetchingOwnedTokens && userOwnedCatalogList.length > itemsPerPage && (
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination count={Math.ceil(userOwnedCatalogList.length / itemsPerPage)} page={currentPage} onChange={(event, value) => paginate(value)} />
                    </Grid>
                )}
                <br/>
            </Paper>
        </>
    )
}   

export default ProfileComponent