import { useEffect, useState } from "react";

import { Alert, Box, Button, FormControlLabel, Grid, IconButton, Menu, MenuItem, Stack, Switch, Typography, useMediaQuery, useTheme } from "@mui/material"
import { styled } from '@mui/material/styles';

import AccountPopper from "./account-popper"
import useCybergemABIContract from "../hooks/useCybergemABIContract";
import useMetamaskProvider from "../hooks/useMetamaskProvider";

import CybergemLogo from '../icons/cybergem-logo.png';
import CybergemLogoReverse from '../icons/cybergem-logo-reverse.png';
import menuIcon from '../icons/menuv2.png';
import digitalIcon from '../icons/wallet.png';
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import AppsIcon from "@mui/icons-material/Apps";
import { isMobile } from 'react-device-detect';


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 52,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 7,
        padding: 0,
        transform: 'translateX(3px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(18px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                //   backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                backgroundColor: 'transparent',
                border: '1px solid #aab4be'
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: 18,
        height: 18,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#000',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        //   backgroundColor: theme.palette.mode === 'dark' ? '#0e0e0e' : '#aab4be',
        backgroundColor: 'transparent',
        border: '1px solid #aab4be',
        borderRadius: 20 / 2,
    },
}));


const AppbarComponent = (props) => {

    const { toggleDarkMode, darkMode } = props;
    const { ownedTokens, getOwnerTokenIds } = useCybergemABIContract();
    const { connectMetamask, wallet, signature, isMetamaskConnected, isMetamaskInstalled, switchToSepoliaTestnet, isNetworkChanged, networkName } = useMetamaskProvider();
    const { signIn, jwt } = useAuth();

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => { setAnchorEl(event.currentTarget); }
    const handleCloseMenu = () => { setAnchorEl(null); }

    const metamask = JSON.parse(localStorage.getItem("metamask"))
    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"))

    let wallet_address = metamask?.wallet_address;

    const storedValue = localStorage.getItem('isNetworkChanged');
    const networkNameValue = localStorage.getItem('networkName');

    const isAuthToken = localStorage.getItem("auth_token");
    const currentBlockchainNetwork = localStorage.getItem("currentBlockchainNetwork");


    useEffect(() => {
        const metamask = {
            wallet_address: wallet,
            signature,
        }

        if (wallet && signature) {

            localStorage.setItem("metamask", JSON.stringify(metamask));

        }
    }, [wallet, signature])

    useEffect(() => {
        if (isAuthToken) {
            getOwnerTokenIds(wallet_address);
        }
    }, [isAuthToken])


    const handleSwitchToSepoliaNetwork = (e) => {
        if (storedValue === 'true' || ((storedValue === "false" || storedValue == null) && currentBlockchainNetwork !== "Sepolia")) {
            switchToSepoliaTestnet();
        } else {
            // Handle the case where the condition is not met, if necessary
        }
    };


    // const openMetamask = async () => {
    //     // Replace 'metamask://' with the correct deep link for Metamask if necessary
    //     const deepLink = Platform.select({
    //         ios: 'metamask://',
    //         android: 'market://details?id=io.metamask',
    //     });
    
    //     try {
    //         await Linking.openURL(deepLink);
    //     } catch (err) {
    //         console.error('Failed to open Metamask:', err);
    //     }
    // };

    return (
        <Grid container sx={{ padding: 1, pt: 0, pb: 0 }} >
            <Grid item xs={4} sm={6} sx={{ padding: 2, paddingLeft: 0, paddingRight: 0, textAlign: 'start', alignSelf: 'center' }} >
                <img src={isDarkmode ? CybergemLogoReverse : CybergemLogo} alt="CyberGems - A collection of 8,888 NFT gems" style={{ width: 'auto', height: isSmScreen ? 24 : 55, cursor: 'pointer' }} onClick={() => window.location.href = "/"} />
            </Grid>
            <Grid item xs={8} sm={6} sx={{ textAlign: 'end', padding: 2, paddingLeft: 0, paddingRight: 0, justifyContent: 'flex-end', alignSelf: 'center' }} >
                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto", justifyContent: 'flex-end', alignContent: 'center', alignItems: 'center', alignSelf: 'center', }}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: "10px", alignSelf: 'center' }}>
                        <MaterialUISwitch checked={darkMode} onClick={toggleDarkMode} />
                    </Stack>

                    {isSmScreen ? null : <>&nbsp;</>  }

                    {isAuthToken ?
                        <Box sx={{ justifyContent: 'flex-end', display: "flex", zIndex: 190 }}>
                            <Button startIcon={<WalletOutlinedIcon sx={{ color: isDarkmode ? '#fff' : "#0e0e0e", width: isSmScreen ? 20 : 28, height: isSmScreen ? 20 : 28 }} />}
                                sx={{
                                    borderRadius: '12px',
                                    padding: '0px 1px',
                                    position: 'relative',
                                    height: '48px',
                                    minWidth: '48px',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'transparent',
                                    color: '#0e0e0e',
                                    transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    fontFamily: 'GucciSans'


                                }}
                                onClick={(e) => handleSwitchToSepoliaNetwork(e)}
                            >
                                <Typography sx={{ fontSize: isSmScreen ? 12 : 16, color: isDarkmode ? '#fff' : "#0e0e0e", fontWeight: 540, textTransform: 'none', fontFamily: 'GucciSans' }}>
                                    {(storedValue === 'true' || ((storedValue === "false" || storedValue == null) && currentBlockchainNetwork !== "Sepolia")) ?
                                        'Switch Network'
                                        :
                                        `${ownedTokens?.length} CGM`
                                    }
                                </Typography>
                            </Button>

                        </Box>
                        :
                        <Box sx={{ justifyContent: 'flex-end', display: "flex", zIndex: 190 }}>
                            <Button startIcon={<WalletOutlinedIcon sx={{ color: isDarkmode ? '#fff' : "#0e0e0e", width: 28, height: 28 }} />}
                                sx={{
                                    borderRadius: '12px',
                                    padding: '0px 1px',
                                    position: 'relative',
                                    height: '48px',
                                    minWidth: '48px',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'transparent',
                                    color: '#0e0e0e',
                                    transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    fontFamily: 'GucciSans'

                                }}
                                onClick={() => {
                                    if(isMobile){
                                        if (isMetamaskInstalled) {
                                            connectMetamask();
                                        } else {
                                            window.open('https://metamask.app.link/dapp/https://cybergems.com');
                                        }
                                    } else {
                                        if (isMetamaskInstalled) {
                                            connectMetamask();
                                        } else {
                                            window.open("https://metamask.io/download/", "_blank");
                                        }
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: 16, color: isDarkmode ? '#fff' : "#0e0e0e", fontWeight: 540, cursor: 'pointer', fontFamily: 'GucciSans' }} >LOGIN</Typography>
                            </Button>
                        </Box>
                    }

                    {isSmScreen ? null : <>&nbsp;</>  }

                    <Box sx={{ justifyContent: 'flex-end', display: 'flex', zIndex: 190 }}>
                        <Button
                            sx={{
                                borderRadius: '12px',
                                padding: '0px 1px',
                                position: 'relative',
                                height: '30px',
                                minWidth: '30px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: 'transparent',
                                color: '#0e0e0e',
                                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                                fontFamily: 'GucciSans'

                            }}
                            onClick={handleOpenMenu}
                        >
                            <AccountCircleOutlinedIcon sx={{ color: isDarkmode ? '#fff' : '#0e0e0e', width: isSmScreen ? 20 : 28, height: isSmScreen ? 20 : 28 }} />
                        </Button>
                    </Box>
                </Stack>
            </Grid>

            <AccountPopper anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />

            {storedValue === 'true' &&
                <Alert variant="filled" severity="warning"
                    style={{
                        position: 'absolute',
                        top: isSmScreen ? 55 : 65,
                        left: 0,
                        width: '100%',
                        zIndex: 9999,
                        color: isDarkmode ? "#fff" : "#000",
                        backgroundColor: "rgb(242, 153, 74)",
                        fontSize:  isSmScreen ? 7.2 : 12,
                        overflow: 'hidden',
                        paddingTop: 2,
                        paddingBottom: 2,
                        alignItems: 'center',
                        lineHeight:  isSmScreen ? '10px' : '18px',
                        height: isSmScreen ? 30 : 36,
                        textAlign:  isSmScreen ? 'start' : 'center',
                        justifyContent: 'center',
                        fontFamily: 'GucciSans'
                    }}
                >
                    You're viewing data from the test network, but your wallet is connected to ({networkName ? networkName : networkNameValue}) network. To use Cybergems, please switch to the Sepolia network.
                </Alert>
            }

            {(storedValue === "false" || storedValue == null) && currentBlockchainNetwork !== "Sepolia" && isMetamaskInstalled &&
                <Alert variant="filled" severity="warning"
                    style={{
                        position: 'absolute',
                        top: isSmScreen ? 55 : 65,
                        left: 0,
                        width: '100%',
                        zIndex: 9999,
                        color: isDarkmode ? "#fff" : "#000",
                        backgroundColor: "rgb(242, 153, 74)",
                        fontSize:  isSmScreen ? 7.2 : 12,
                        overflow: 'hidden',
                        paddingTop: 2,
                        paddingBottom: 2,
                        alignItems: 'center',
                        lineHeight:  isSmScreen ? '10px' : '18px',
                        height: isSmScreen ? 30 : 36,
                        textAlign:  isSmScreen ? 'start' : 'center',
                        justifyContent: 'center',
                        fontFamily: 'GucciSans'
                    }}
                >
                    You're viewing data from the test network, but your wallet is connected to {currentBlockchainNetwork} network. To use Cybergems, please switch to the Sepolia network.
                </Alert>
            }

        </Grid>
    )
}

export default AppbarComponent