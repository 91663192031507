import { useEffect, useState } from "react";
import {Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme, Switch} from "@mui/material"
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountPopper from "./account-popper"
import useCybergemABIContract from "../hooks/useCybergemABIContract";
import useMetamaskProvider from "../hooks/useMetamaskProvider-v2";
import CybergemLogoReverse from '../icons/cybergem-logo-reverse.png';
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {styled} from "@mui/material/styles";
import {useAllSeriesStore} from "../zustand/useAllSeriesStore";
import useSessionStorage from "../hooks/useSessionStorage";
import useWidth from "../hooks/useWidth";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: (theme.palette.mode === "dark") ? "#1890ff" : "#fff",
            
            '& + .MuiSwitch-track': {
                opacity: 20,
                // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#000",
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const AppbarComponent = (props) => {

    const {toggleDarkMode, darkMode, isRefreshing} = props;

    const breakpoints = useWidth();

    const navigate = useNavigate();
    const {ownedTokens, getOwnerTokenIds} = useCybergemABIContract();

    const { wallet, signature, currentNetworkName, currentWallet, isMetamaskConnected, isMetamaskInstalled, connectMetamask, switchToSepoliaTestnet } = useMetamaskProvider();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleOpenMenu = (event) => { setAnchorEl(event.currentTarget); }
    const handleCloseMenu = () => { setAnchorEl(null); }
    const [anchorEl, setAnchorEl] = useState(null);
    const { getProfile, profile, errorProfile } = useAuth();
    const [clicked, setClicked] = useState(false);

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"))
    const isAuthToken = localStorage.getItem("auth_token");
    const url = window.location.pathname;

    const {isAllSeries, setAllSeries} = useAllSeriesStore();
    const {deleteDataFromSessionStorage} = useSessionStorage();

    const metamaskAppLink = process.env.REACT_APP_METAMASK_APP_LINK;

    useEffect(() => {
        if (wallet == null || signature == null) return;
        const metamask = {
            wallet_address: wallet,
            signature,
        }
        localStorage.setItem("metamask", JSON.stringify(metamask));
    }, [wallet, signature])

    useEffect(() => {
        if (!isMetamaskConnected) return;
        window.location.reload();
    }, [isMetamaskConnected])

    useEffect(() => {
        if (profile == null) return;
        if (profile.wallet != currentWallet) {
            alert("You have changed your account in Metamask. Please sign in again.")
            handleLogout();
        }
    }, [currentWallet])

    useEffect(() => {
        if (isAuthToken == null) return;
        getProfile();
    }, [isAuthToken]) // if authToken from localStorage changes its value, refetch profile 

    useEffect(() => {
        if (profile == null || currentNetworkName == null) return;
        getOwnerTokenIds(profile.wallet);
    }, [profile, currentNetworkName]) // if profile or currentNetworkName response changes it value, refetch owner token ids

    useEffect(() => {
        if (isRefreshing) {
            deleteDataFromSessionStorage();
        }
    }, [isRefreshing])

    const handleOnClickLogin = (e) => {
        deleteDataFromSessionStorage();

        if (isMetamaskInstalled) {
            connectMetamask();
            
            if (!clicked) {
                setClicked(true);

            }
        } else {
            window.open(metamaskAppLink, "_blank");
        }
    }

    function handleSwitchToSepoliaNetwork() {
        switchToSepoliaTestnet();
    }

    function handleLogout() {
        localStorage.removeItem("metamask")
        localStorage.removeItem("auth_token")
        localStorage.removeItem("isFocused")
        localStorage.removeItem('isNetworkChanged')
        localStorage.removeItem('networkName')
        
        deleteDataFromSessionStorage();

        window.location.href = '/home';
    }

    const handleSwitchChange = (e) => {
        setAllSeries(e.target.checked);
    }


    return (
        <Grid container sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "1rem" : "4rem", pt: 0, pb: 0}}>

            <Grid item xs={4} sm={6} sx={{ padding: 2, paddingLeft: 0, paddingRight: 0, textAlign: 'start', alignSelf: 'center' }} >
                <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ width: 'auto', height: isSmScreen ? 24 : 55, cursor: 'pointer' }} onClick={() => navigate("/home")} 
                    src={CybergemLogoReverse}
                    // src={isDarkmode ? CybergemLogoReverse : CybergemLogo}
                />
            </Grid>

            <Grid item xs={8} sm={6} sx={{ textAlign: 'end', padding: 2, paddingLeft: 0, paddingRight: 0, justifyContent: 'flex-end', alignSelf: 'center' }} >
                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto", justifyContent: 'flex-end', alignContent: 'center', alignItems: 'center', alignSelf: 'center', }}>
                    {(breakpoints == "md" || breakpoints == "lg" || breakpoints == "xl") &&
                        <Button
                            className="blink"
                            sx={{
                                borderRadius: '12px',
                                padding: '0px 1px',
                                position: 'relative',
                                height: '48px',
                                minWidth: '48px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: 'transparent',
                                color: '#0e0e0e',
                                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                fontFamily: 'GucciSans',
                            }}
                            onClick={(e) => navigate("/series/alpha")}
                        >
                            <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 16, textTransform: "none", fontFamily: "GucciSans",
                                color: "#fff", fontWeight: 800
                            }}>
                                ALPHA is LIVE!
                            </Typography>
                        </Button>  
                    } &nbsp; &nbsp;

                    <Button
                        sx={{
                            borderRadius: '12px',
                            padding: '0px 1px',
                            position: 'relative',
                            height: '48px',
                            minWidth: '48px',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'transparent',
                            color: '#0e0e0e',
                            transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                            fontFamily: 'GucciSans'
                        }}
                        onClick={(e) => navigate("/about")}                   
                    >
                        <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 16, fontWeight: 540, textTransform: "none", fontFamily: "GucciSans",
                            color: "#fff", 
                            // color: (isDarkmode) ? "#fff" : "#0e0e0e",
                        }}>
                            About
                        </Typography>
                    </Button>

                    &nbsp;

                    {/* {url === "/home" &&
                        <>
                            <Typography sx={{fontSize: (isSmScreen) ? 12 : 16, color: (isDarkmode) ? "#fff" : "#0e0e0e", fontWeight: 540, textTransform: "none", fontFamily: "GucciSans"}}>
                                All Series
                            </Typography>

                            <AntSwitch checked={isAllSeries} onClick={handleSwitchChange} />
                        </>
                    } */}

                    &nbsp;

                   {isAuthToken ?
                        null
                    :
                        <GuestButton
                            onClickLogin={(e) => handleOnClickLogin(e)}
                            isDarkmode={isDarkmode}
                            isSmScreen={isSmScreen}
                            currentNetworkName={currentNetworkName}
                            clicked={clicked} 
                            breakpoints={breakpoints}
                        />
                    }

                    {/* {isAuthToken ?
                        <AuthenticatedButton
                            onClickSwitchNetwork={handleSwitchToSepoliaNetwork}
                            isDarkmode={isDarkmode}
                            isSmScreen={isSmScreen}
                            currentNetworkName={currentNetworkName}
                            ownedTokens={ownedTokens} />
                        :
                        <GuestButton
                            onClickLogin={(e) => handleOnClickLogin(e)}
                            isDarkmode={isDarkmode}
                            isSmScreen={isSmScreen}
                            currentNetworkName={currentNetworkName}
                            clicked={clicked} />
                    } */}

                    &nbsp;

                    <Box sx={{ justifyContent: 'flex-end', display: 'flex', zIndex: 190 }}>
                        <Button
                            sx={{
                                borderRadius: '12px',
                                padding: '0px 1px',
                                position: 'relative',
                                height: '30px',
                                minWidth: '30px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: 'transparent',
                                color: '#0e0e0e',
                                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                                fontFamily: 'GucciSans'

                            }}
                            onClick={handleOpenMenu}
                        >
                            <AccountCircleOutlinedIcon sx={{ 
                                color: "#fff",
                                // color: isDarkmode ? '#fff' : '#0e0e0e', 
                                width: isSmScreen ? 20 : 28, height: isSmScreen ? 20 : 28 }} 
                            />

                        </Button>
                    </Box>

                </Stack>
            </Grid>

            <AccountPopper 
                anchorEl={anchorEl} 
                handleCloseMenu={handleCloseMenu} 
                // toggleDarkMode={toggleDarkMode} 
                // darkMode={darkMode} 
                ownedTokens={ownedTokens}
                breakpoints={breakpoints}
            />

            {/* {currentNetworkName !== null && currentNetworkName !== "Sepolia" &&
                <Alert variant="filled" severity="warning"
                    style={{
                        position: 'absolute',
                        top: isSmScreen ? 55 : 65,
                        left: 0,
                        width: '100%',
                        zIndex: 9999,
                        color: isDarkmode ? "#fff" : "#000",
                        backgroundColor: "rgb(242, 153, 74)",
                        fontSize: isSmScreen ? 7.2 : 12,
                        overflow: 'hidden',
                        paddingTop: 2,
                        paddingBottom: 2,
                        alignItems: 'center',
                        lineHeight: isSmScreen ? '10px' : '18px',
                        height: isSmScreen ? 30 : 36,
                        textAlign: isSmScreen ? 'start' : 'center',
                        justifyContent: 'center',
                        fontFamily: 'GucciSans'
                    }}
                >

                    You're viewing data from the test network, but your wallet is connected to {currentNetworkName} network. To use Cybergems, please switch to the Sepolia network.
                </Alert>
            } */}

        </Grid>
    )
}

const AuthenticatedButton = ({ isDarkmode, isSmScreen, currentNetworkName, ownedTokens, onClickSwitchNetwork }) => {
    return (
        <Box sx={{ justifyContent: 'flex-end', display: "flex", zIndex: 190 }}>
            <Button disableRipple 
                startIcon={
                    <WalletOutlinedIcon sx={{ 
                        color: "#fff",
                        // color: isDarkmode ? '#fff' : "#0e0e0e", 
                        width: isSmScreen ? 20 : 28, height: isSmScreen ? 20 : 28 }} 
                    />
                }
                sx={{
                    borderRadius: '12px',
                    padding: '0px 1px',
                    position: 'relative',
                    height: '48px',
                    minWidth: '48px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    color: '#0e0e0e',
                    transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                    fontFamily: 'GucciSans',
                    cursor: (currentNetworkName !== null && currentNetworkName !== "Sepolia") ? "pointer" : "default"
                }}
                onClick={(e) => onClickSwitchNetwork(e)}
            >
                <Typography sx={{ fontSize: isSmScreen ? 12 : 16, 
                    color: "#fff",
                    // color: isDarkmode ? '#fff' : "#0e0e0e", 
                    fontWeight: 540, textTransform: 'none', fontFamily: 'GucciSans' }}>
                    {currentNetworkName !== null && currentNetworkName !== "Sepolia" ?
                        'Switch Network'
                        :
                        `${ownedTokens?.length} CG`
                    }
                </Typography>
            </Button>
        </Box>
    )
}

const GuestButton = ({isDarkmode, onClickLogin, clicked, breakpoints}) => {
    return (
        <Box sx={{ justifyContent: 'flex-end', display: "flex", zIndex: 190 }}>
            <Button disableRipple disableFocusRipple disabled={clicked} 
                // startIcon={
                //     <WalletOutlinedIcon sx={{ 
                //         color: "#fff",
                //         // color: isDarkmode ? '#fff' : "#0e0e0e", 
                //         width: 28, height: 28 }} 
                //     />
                // }
                sx={{
                    borderRadius: '12px',
                    padding: '0px 1px',
                    position: 'relative',
                    height: '48px',
                    minWidth: '48px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    color: '#0e0e0e',
                    transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                    fontFamily: 'GucciSans'

                }}
                onClick={() => onClickLogin()}
            >
                <Typography sx={{
                    fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 16,
                    color: "#fff",
                    // color: isDarkmode ? '#fff' : "#0e0e0e", 
                    fontWeight: 540, cursor: 'pointer', fontFamily: 'GucciSans', textTransform: "none"
                }}>
                    Login
                </Typography>
            </Button>
        </Box>
    )
}

export default AppbarComponent