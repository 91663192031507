import React, {useEffect, useState} from "react";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useWidth from "../../hooks/useWidth";

const SeriesCarouselComponent = (props) => {

    const breakpoints = useWidth();

    const { handleMouseDown, handleMouseMove, handleMouseUp, imageTitleHovered, imageSources, isImageLoaded,
        handleRightClick, handleImageLoad, setHoveredImage, isDragging, currentX, isDarkmode, handleTouchStart, handleTouchMove, handleTouchEnd
    } = props;

    const [isAndroidPhone, setAndroidPhone] = useState(false);
    const [isIOSPhone, setIOSPhone] = useState(false);

    useEffect(() => {
        const handleDeviceType = () => {
            const isAndroid = /Android/i.test(navigator.userAgent);
            const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

            setAndroidPhone(isAndroid);
            setIOSPhone(isIOS);
        }

        handleDeviceType();

        const resizeHandler = () => {
            handleDeviceType();
        }

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    }, []);

    const navigate = useNavigate();

    const handleNavigateSeries = (src) => {
        if(src?.title === "Alpha") {
            navigate(`/series/${src?.title.toLowerCase()}`);
        } else {
           navigate('/minting')
        }
    }

    
    return (
        <Box
            className={
                (breakpoints == "md" || breakpoints == "lg" || breakpoints == "xl") ? "container" 
            : 
                ((breakpoints == "xs" || breakpoints == "sm") && isAndroidPhone) ? "containerAndroidMobileView" 
            : 
                ((breakpoints == "xs" || breakpoints == "sm") && isIOSPhone) ? "containerIOSMobileView" 
            : 
                "container"
            }
            style={{ position: 'relative', overflow: 'hidden'}}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
            draggable='false'
        >

            <div
                id="carousel-scope"
                className={(imageTitleHovered == null) ? "scope" : "focusedScope"}
                style={{
                    transform: `perspective(1000px) rotateY(${(currentX / 13)}deg)`,
                    '--start-angle': `${currentX / 13}deg`,
                }}
            >
                {imageSources?.map((src, index) => (
                    <Tooltip key={index} title={isDragging ? null : "Hold and swipe"} placement="top" sx={{fontFamily: 'GucciSans'}}>
                        <span key={index} id={`image-${src?.id}`}
                            className={index === imageTitleHovered ? "focusedImage" : ""}
                            style={{
                                '--i': index + 1,
                            }}
                        >
                            {!isImageLoaded[index] &&
                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    width="290px"
                                    height="120px"
                                    className='scope'
                                />
                            }

                            <img
                                key={index}
                                src={src?.image}
                                alt="CyberGems - A collection of 8,888 NFT gems"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '../images/sample-cover-art.png'; // Replace with your placeholder image URL
                                }}
                                onContextMenu={handleRightClick}
                                onLoad={() => handleImageLoad(index)}
                                onMouseEnter={() => setHoveredImage(src?.id)}
                                onMouseLeave={() => setHoveredImage(null)}
                                style={{ display: isImageLoaded[index] ? 'block' : 'none' }}
                                draggable='false'
                                onClick={!isDragging ? () => handleNavigateSeries(src) : null}
                                // onClick={!isDragging ? () => navigate(`/series/${src?.title.toLowerCase()}`) : null}
                            />

                            <Typography
                                style={{
                                    marginTop: 18,
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    width: '100%',
                                    objectFit: 'cover',
                                    transition: '2s',
                                    textAlign: 'center',
                                    textTransform: (index == 0) ? "uppercase" : "none",
                                    color: '#fff',
                                    // color: isDarkmode ? '#fff' : '#000',
                                    padding: '1px 8px',
                                    fontSize: 9,
                                    lineHeight: 1,
                                    fontWeight: 'regular',
                                    fontFamily: 'GucciSans',
                                }}
                            >
                                {src?.title}
                            </Typography>

                        </span>
                    </Tooltip>
                ))}
            </div>
        </Box>
    )
}

export default SeriesCarouselComponent