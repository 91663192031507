import React, { useEffect, useState } from "react";
import {Tooltip, IconButton, Paper, Grid, Box, Typography, List, ListItemText, Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachMemoryDialogComponent from "../attach-memory/dialog/attach-memory-dialog.component";
import { useNavigate } from "react-router";
import ViewMemoryIcon from "../../icons/album.png";
import ViewGemDetailsSkeleton from "./skeleton/view-gem-details.skeleton";
import OpenseaIcon from "../../icons/blue-opensea.png";
import WhiteOpenseaIcon from "../../icons/white-opensea.png";

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import AttachMemory from "../../icons/attachment-float.png";
import SuccessCOAIcon from "../../icons/success-coa.png";
import FailedCOAIcon from "../../icons/failed.png";
import PendingCOAIcon from "../../icons/pending.png";
import SuccessCOAReverseIcon from "../../icons/success-coa-reverse.png";
import FailedCOAReverseIcon from "../../icons/failed-reverse-coa.png";
import PendingCOAReverseIcon from "../../icons/pending-reverse-coa.png";
import RetryConfirmationModal from "../view-memory/dialog/retry-confirmation-modal";
import useCybergemNft from "../../hooks/useCybergemNft";
import useWidth from "../../hooks/useWidth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import {log} from "../../console-config.log"

const ViewGemDetailsComponent = (props) => {

    const {details, attachMemory, setGemClicked, isLoadingAttachMemory, successAttachment, 
        isLoadingCybergemDetailView, setMemoryAttach, getViewCybergem, gemTokenId
    } = props;

    log("gem details xxxx", details);

    const breakpoints = useWidth();

    const {retryCOA, cybergemRetryCOA, isLoadingRetryCOA} = useCybergemNft();

    const [open, setOpen] = useState(false);
    const [openThumbnailCropper, setOpenThumbnailCropper] = useState(false);
    const navigate = useNavigate();

    const isDarkMode = localStorage.getItem("darkMode");
    const isUserLoggedIn = localStorage.getItem("auth_token");
    const metamask = JSON.parse(localStorage.getItem("metamask"));
    // const liveCoaURL = "https://salmon-desert-0600ffb1e.4.azurestaticapps.net";
    const liveCoaURL = "https://cybergems.com";
    // const localCoaURL = "http://localhost:3000";

    const [hasAttachedMemory, setHasAttachedMemory] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    useEffect(() => {
        if (hasAttachedMemory) {
            getViewCybergem(gemTokenId);
        }
    }, [hasAttachedMemory])
    
    const handleOpenModal = () => {
        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    const handleUpload = () => {
        setOpen(false);
    }

    const handleViewCOA = () => {
        let liveTabURL =  liveCoaURL + "/view-coa?tokenId=" + details?.catalog?.tokenID;
        
        window.location.href= liveTabURL;
    }


    const handleRedirectOpensea = () => {
        let openseaURL = details?.marketplace_view_url;

        window.open(openseaURL, "_blank");
    }

    const handleOpenConfirmationModal = () => {
        setOpenConfirmationModal(true)
    }

    const [isImageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    }


    const handleRightClick = (event) => {
        event.preventDefault();
    }

    const handleCopyOwnerAddress = (e, ownerAddress) => {
        copy(ownerAddress);
    }

    const handleLinkToOpenseaProfile = (e, ownerAddress) => {
        let openseaUrl = "https://opensea.io/" + ownerAddress;

        window.open(openseaUrl, "_blank");
    }
    

    if (isLoadingCybergemDetailView) return <ViewGemDetailsSkeleton breakpoints={breakpoints} />;


    return (
        <Paper elevation={3}
            sx={{
                top: "80px",
                bottom: "20px",
                left: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : {},
                right: "16px",
                padding: "16px",
                position: "fixed",       
                width: (breakpoints == "lg" || breakpoints == "xl") ? "450px" : {},
                overflowY: "auto",  
                borderRadius: "8px",
                boxShadow: "rgb(0 0 0 / 29%) 0px 4px 20px",
                zIndex: 1000,
                paddingTop: "0px !important",
            }}
        >
            <Box sx={{textAlign: "end", mt: 2}}>
                <CloseIcon sx={{color: "#808080", cursor: "pointer", fontSize: 20}} 
                    onClick={(e) => setGemClicked(false)} 
                />
            </Box>
        
            <Grid container sx={{justifyContent: "center", mt: "20px"}}>
                <Grid item xs={12} sm={(details?.links?.memory_thumbnail || hasAttachedMemory) ? 5 : 12} md={6} lg={6} xl={6}>
                    <img alt="CyberGems - A collection of 8,888 NFT gems" src={details?.links?.img_thumbnail} onContextMenu={handleRightClick} 
                        onClick={(e) => navigate(`/${details?.catalog?.tokenID}`)}  
                        style={{cursor: "pointer", height: 280, boxShadow: "rgb(0 0 0 / 22%) 0px 4px 20px", borderRadius: "8px"}} 
                    />
                </Grid>

                {(details?.links?.memory_thumbnail || hasAttachedMemory) && 
                    <Grid item xs={12} sm={5} md={6} lg={6} xl={6}>
                        <>
                            {/* {!isImageLoaded && (
                                <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    width="150px"
                                    height="280px"
                                    style={{borderRadius: "8px"}}
                                />
                            )} */}

                            <img alt="CyberGems - A collection of 8,888 NFT gems" 
                                src={details?.links?.memory_uri} 
                                style={{
                                    height: 280,
                                    objectFit: "cover", 
                                    width: "187px", 
                                    boxShadow: "rgb(0 0 0 / 22%) 0px 4px 20px", 
                                    borderRadius: "8px",
                                    cursor: "pointer"
                                }} 
                                onLoad={() => {
                                    setImageLoaded(true);
                                    handleImageLoad();
                                }}
                                onContextMenu={handleRightClick}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = details?.links?.memory_thumbnail;
                                }}
                                onClick={()=> navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                            />
                        </>
                    </Grid>
                }
            </Grid>

            <Grid container direction="column" sx={{mt: "21px", textAlign: "start"}}>
                <Grid container direction="row">
                    <Grid item xs={10} sx={{}}>
                        <Typography sx={{fontSize: 20, fontWeight: "bold", mb: "-3px", fontFamily: 'GucciSans'}}>
                            #{details?.catalog?.tokenID} {details?.catalog?.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={1} sx={{textAlign: "end"}}>
                        <Tooltip title="View in Opensea" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                            <IconButton sx={{mt: "-11px", mr: "-10px", ml: (breakpoints == "xs" || breakpoints == "sm") ? {} : 2}} onClick={(e) => handleRedirectOpensea()}>
                                <img alt="CyberGems - A collection of 8,888 NFT gems" 
                                    src={WhiteOpenseaIcon} 
                                    // src={(isDarkMode === "false") ? OpenseaIcon : WhiteOpenseaIcon} 
                                    style={{height: (isDarkMode === "false") ? 21 : 19, 
                                    width: 19,
                                    // width: (isDarkMode === "false") ? 20 : 19
                                }} 
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    {(details?.links?.memory_thumbnail || hasAttachedMemory) && 
                        <Grid item xs={1} sx={{textAlign: "end"}}>
                            {details?.catalog?.coa_status === "S" ?
                                <Tooltip title="View Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                    <IconButton sx={{mt: "-11px", mr: "-10px"}} onClick={(e) => handleViewCOA()}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" 
                                            src={SuccessCOAReverseIcon} 
                                            // src={(isDarkMode === "false") ? SuccessCOAIcon : SuccessCOAReverseIcon} 
                                            style={{height: 19}} 
                                        />
                                    </IconButton>
                                </Tooltip>
                                :
                                    details?.catalog?.coa_status === "P"  ?
                                        <Tooltip title="Pending Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                            <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 19, cursor: "default", mt: "-11px", mr: "-10px"}} 
                                                src={PendingCOAReverseIcon} 
                                                // src={(isDarkMode === "false") ? PendingCOAIcon : PendingCOAReverseIcon} 
                                            />
                                        </Tooltip>
                                :   
                                    <Tooltip title="Regenerate Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 19, cursor: "pointer", mt: "-11px", mr: "-10px"}}
                                            src={FailedCOAReverseIcon } 
                                            // src={(isDarkMode === "false") ? FailedCOAIcon : FailedCOAReverseIcon }  
                                            onClick={handleOpenConfirmationModal}
                                        />
                                    </Tooltip>
                            }
                        </Grid>
                    }
                </Grid>

                <Grid item xs={12} sx={{mb: "10px", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 18}}>
                    <Typography sx={{fontFamily: 'GucciSans'}}>
                        {details?.catalog?.description}
                    </Typography>
                </Grid>

                <Grid container sx={{mt: 0}}>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        ABOUT
                    </Typography>

                    <Grid container sx={{textAlign: "start", mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            VARIATION
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.variation}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            SERIES
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.series}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            TOKEN ID
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.tokenID}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>
                    </Grid>

                    <Grid container sx={{textAlign: "start", mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            MEMORY TITLE
                                        </Typography>
                                    }
                                    secondary={(details?.catalog?.memory_title == null) ? "-----" : details?.catalog?.memory_title}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            OWNER
                                        </Typography>
                                    }
                                    secondary={
                                        (breakpoints === "xs" || breakpoints == "sm" || breakpoints == "md") ? 
                                            (
                                                <>
                                                    <Box sx={{display: "flex"}}>
                                                        <Tooltip arrow title={(details?.owner_address) ? details?.owner_address : null} placement="top" sx={{fontFamily: 'GucciSans'}}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    whiteSpace: "nowrap",
                                                                    width: "80px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    textAlign: "start",
                                                                    color: "rgba(255, 255, 255, 0.7)",
                                                                    // color: (isDarkMode === "false") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.7)", 
                                                                    fontFamily: 'GucciSans',
                                                                    textDecoration: (details?.owner_address) ? "underline" : "none",
                                                                    textDecorationColor: (details?.owner_address) ? "#017af4" : "none",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(e) => (details?.owner_address) ? handleLinkToOpenseaProfile(e, details?.owner_address) : null}
                                                            >
                                                                {details?.owner_address ?
                                                                    details?.owner_address?.toLowerCase()
                                                                    :
                                                                    "---"
                                                                }
                                                            </Typography>
                                                        </Tooltip>

                                                        {details?.owner_address && 
                                                            <Tooltip arrow title={"Copy owner address"} placement="top" sx={{fontFamily: "GucciSans"}}>
                                                                <IconButton onClick={(e) => handleCopyOwnerAddress(e, details?.owner_address)}>
                                                                    <ContentCopyIcon fontSize="small" style={{width: 12, height: 12, marginTop: "-6px", color: "#017af4"}} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Box>
                                                </>
                                            )
                                        :
                                            (breakpoints === "lg" || "xl") ? 
                                                (
                                                    <>
                                                        <Box sx={{display: "flex"}}>
                                                            <Tooltip arrow title={(details?.owner_address) ? details?.owner_address : null} placement="top" sx={{fontFamily: 'GucciSans'}}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        whiteSpace: "nowrap",
                                                                        width: "280px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        textAlign: "start",
                                                                        color: "rgba(255, 255, 255, 0.7)", 
                                                                        // color: (isDarkMode === "false") ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.7)", 
                                                                        fontFamily: "GucciSans",
                                                                        textDecoration: (details?.owner_address) ? "underline" : "none",
                                                                        textDecorationColor: (details?.owner_address) ? "#017af4" : "none",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(e) => (details?.owner_address) ? handleLinkToOpenseaProfile(e, details?.owner_address) : null}
                                                                >
                                                                    {details?.owner_address ?
                                                                        details?.owner_address?.toLowerCase()
                                                                        :
                                                                        "---"
                                                                    }
                                                                </Typography>   
                                                            </Tooltip>

                                                            {details?.owner_address && 
                                                                <Tooltip arrow title={"Copy owner address"} placement="top" sx={{fontFamily: "GucciSans"}}>
                                                                    <IconButton onClick={(e) => handleCopyOwnerAddress(e, details?.owner_address)}>
                                                                        <ContentCopyIcon fontSize="small" style={{width: 12, height: 12, marginTop: "-6px", color: "#017af4"}} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </Box>
                                                    </>
                                                )            
                                        :
                                            (details?.owner_address) ? 
                                                details?.owner_address?.toLowerCase()
                                            :
                                                "-----"
                                    }
                                    secondaryTypographyProps={{fontSize: 11, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{}}>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        STATS
                    </Typography>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            LEVEL
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.level}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}} 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            CLARITY
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.clarity}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            MULTIPLIER
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.multiplier}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>
                    </Grid>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            CARAT
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.carat}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}} 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            RATING
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.rating}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{mt: 1}}>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        PROPERTIES
                    </Typography>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            BACKGROUND
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.background}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}} 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            GLOW
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.glow}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            GLITTER
                                        </Typography>
                                    }
                                    secondary={details?.catalog?.glitter}
                                    secondaryTypographyProps={{fontSize: 12, fontFamily: 'GucciSans'}}
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {(isUserLoggedIn && details?.owner_address?.toLowerCase() === metamask?.wallet_address) ?
                (details?.links?.memory_thumbnail || hasAttachedMemory) ?
                    <Grid sx={{display: "flex"}}>
                        <button className="btn-donate" onClick={(e) => navigate(`/${details?.catalog?.tokenID}`)} 
                            style={{width: "80%", height: "38px", marginTop: 24, display: "flex", justifyContent: "center"}}
                        >
                            {/* <VisibilityIcon sx={{mt: "-2px"}} />  */}
            
                            <Typography sx={{color: "#fff", mt: "-2px", fontFamily: 'GucciSans'}}>
                                See More
                            </Typography>

                            <ExpandCircleDownOutlinedIcon sx={{ml: 1, mt: "-2px", rotate: '270deg'}} /> 

                        </button>
    
                        &nbsp; &nbsp;

                        <Button variant="outlined" 
                            sx={{fontSize: "15px", width: "80%", color: "#097FF4", mt: 3, border: "0.1px solid #0178f2", backgroundColor: "#fff", 
                                borderRadius: "6px", textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                "&:hover": {border: "0.1px solid #0178f2", backgroundColor: "#fff"}, fontFamily: 'GucciSans'
                            }}
                            startIcon={<img alt="CyberGems - A collection of 8,888 NFT gems" src={ViewMemoryIcon} style={{height: 24}} />}
                            onClick={()=> navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                        >
                            View Memory
                        </Button>
                    </Grid>

                :
                    <Grid sx={{display: "flex"}}>
                        <button className="btn-donate" onClick={(e) => navigate(`/${details?.catalog?.tokenID}`)} 
                            style={{width: "100%", height: "38px", marginTop: 24, display: "flex", justifyContent: "center"}}
                        >
                            {/* <VisibilityIcon sx={{mt: "-2px"}} />  */}
            
                            <Typography sx={{color: "#fff",  mt: "-2px", fontFamily: 'GucciSans'}}>
                                See More
                            </Typography>

                            <ExpandCircleDownOutlinedIcon sx={{ml: 1, mt: "-2px", rotate: '270deg'}} /> 

                        </button>

                        {/* &nbsp; &nbsp;

                        <Button variant="outlined" 
                            sx={{width: "80%", color: "#45A1FF", mt: 3, border: "0.1px solid #0178f2", fontSize: 14, backgroundColor: "#fff", 
                                borderRadius: "6px", textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                "&:hover": {border: "0.1px solid #0178f2", backgroundColor: "#fff"}, fontFamily: 'GucciSans'
                            }} 
                            onClick={handleOpenModal}
                            // startIcon={<AttachFileIcon sx={{color: "#45A1FF"}} />}
                            startIcon={<img src={AttachMemory} style={{ height: 18}} />}
                        >
                            Attach Memory
                        </Button>  */}
                    </Grid>
            :
                (details?.links?.memory_thumbnail || hasAttachedMemory) ?
                    <Grid sx={{display: "flex"}}>
                        <button className="btn-donate" onClick={(e) => navigate(`/${details?.catalog?.tokenID}`)} 
                            style={{width: "80%", height: "38px", marginTop: 24, display: "flex", justifyContent: "center"}}
                        >
                            {/* <VisibilityIcon sx={{mt: "-2px"}} />  */}
            
                            <Typography sx={{color: "#fff",  mt: "-2px", fontFamily: 'GucciSans'}}>
                                See More
                            </Typography>

                            <ExpandCircleDownOutlinedIcon sx={{ml: 1, mt: "-2px", rotate: '270deg'}} /> 

                        </button>

                        &nbsp; &nbsp;

                        <Button variant="outlined" 
                            sx={{fontSize: "15px", width: "80%", color: "#097FF4", mt: 3, border: "0.1px solid #0178f2", backgroundColor: "#fff", 
                                borderRadius: "6px", textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                "&:hover": {border: "0.1px solid #0178f2", backgroundColor: "#fff"}, fontFamily: 'GucciSans'
                            }}
                            startIcon={<img alt="CyberGems - A collection of 8,888 NFT gems" src={ViewMemoryIcon} style={{height: 24}} />}
                            onClick={()=> navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                        >
                            View Memory
                        </Button>
                    </Grid>
                :
                    <Grid sx={{display: "flex"}}>
                        <button className="btn-donate" onClick={(e) => navigate(`/${details?.catalog?.tokenID}`)} 
                            style={{width: "100%", height: "38px", marginTop: 24, display: "flex", justifyContent: "center"}}
                        >
                            <Typography sx={{color: "#fff", mt: "-2px", fontFamily: 'GucciSans'}}>
                                See More
                            </Typography>

                            <ExpandCircleDownOutlinedIcon sx={{ml: 1, mt: "-2px", rotate: '270deg'}} /> 
                        </button>
                    </Grid>   
            }

            
            <AttachMemoryDialogComponent 
                open={open} 
                close={handleCloseModal} 
                handleUpload={handleUpload} 
                setOpen={setOpen}
                openThumbnailCropper={openThumbnailCropper} 
                details={details} 
                isLoadingAttachMemory={isLoadingAttachMemory}
                setOpenThumbnailCropper={setOpenThumbnailCropper} 
                attachMemory={attachMemory} 
                successAttachment={successAttachment}
                setMemoryAttach={setMemoryAttach} 
                setHasAttachedMemory={setHasAttachedMemory}
            />

            <RetryConfirmationModal 
                openConfirmationModal={openConfirmationModal} 
                setOpenConfirmationModal={setOpenConfirmationModal}
                retryCOA={retryCOA} 
                isLoadingRetryCOA={isLoadingRetryCOA}
                tokenID={gemTokenId} 
                cybergemRetryCOA={cybergemRetryCOA}
            />
        </Paper>
    )
}

export default ViewGemDetailsComponent
