
import React from 'react';
import {Navigate} from "react-router-dom";

function PrivateRoute({children}) {

    let storedToken = localStorage.getItem("auth_token");

    return storedToken ? children : <Navigate to="/" />;
}

export {PrivateRoute}