import React, {useEffect} from "react";
import {Box, Grid, IconButton} from "@mui/material";
import useCybergemNft from "../hooks/useCybergemNft";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import GemDetailsPageViewComponent from "../component/detail-view/gem-details-page-view.component";
import PageNotFoundComponent from "../component/page-not-found.component";
import useWidth from "../hooks/useWidth";
import GemComingSoonComponent from "./GemComingSoonScreen";

const GemDetailsPageViewScreen = (props) => {

    const {setGemClicked} = props;

    const navigate = useNavigate();
    const {tokenID} = useParams();
    const breakpoints = useWidth();

    const {cybergemDetailViewData, successAttachment, isLoadingCybergemDetailView, getViewCybergem, attachMemory, errorCybergemDetailView, isLoadingAttachMemory} = useCybergemNft();
    
    const errorMessage = "Request failed with status code 403";

    useEffect(() => {
        if (tokenID) {
            getViewCybergem(tokenID);
        }

    }, [tokenID])

    
    if (errorMessage === errorCybergemDetailView) return <GemComingSoonComponent />


    return (
        <>
            <Box sx={{width: breakpoints === 'xs' || breakpoints === 'sm' ? "95%" : breakpoints === 'md' ? "90%" : "85%", margin: "auto", mb: "30px", overflowX: "hidden"}}>
                <Box sx={{width: "90%", margin: "auto", mb: 2}}> 
                    <Grid sx={{textAlign: "start"}}>
                        <IconButton sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? "-10px" : "10px"}} onClick={(e) => navigate(-1)}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>

                    <GemDetailsPageViewComponent 
                        details={cybergemDetailViewData} 
                        attachMemory={attachMemory} 
                        setGemClicked={setGemClicked} 
                        successAttachment={successAttachment}
                        isLoadingCybergemDetailView={isLoadingCybergemDetailView}
                        isLoadingAttachMemory={isLoadingAttachMemory}
                    />
                </Box>
            </Box>
        </>
    )
}   

export default GemDetailsPageViewScreen