import { Box, Skeleton } from "@mui/material";


export default function ViewCoaSkeletonLoader () {

    return(
        <Box sx={{width: '80%', margin:'auto'}}>
            <Skeleton variant="rectangular" style={{width: "40%", height: '770px', margin:'auto'}}/>
        </Box>
    )
}