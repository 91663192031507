import { useState } from 'react';
import useCybergemNft from '../hooks/useCybergemNft';

function AttachMemoryScreen(){

    const { data, isLoading, error, attachMemory, cancelApiRequest } = useCybergemNft();
    const [tokenId, setTokenId] = useState(null);
    const [memoryTitle, setMemoryTitle] = useState(null);
    const [memory, setMemory] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);

    const handleTokenIdTextChange = (event) => {
        setTokenId(event.target.value);
    };
    
    const handleTokenMemoryTitleChange = (event) => {
        setMemoryTitle(event.target.value);
    };

    const handleMemoryFileChange = async (event) => {
        const file = event.target.files[0];
        setMemory(file);
    };

    const handleThumbnailFileChange = (event) => {
        const file = event.target.files[0];
        setThumbnail(file)
    };
  
    const handleFileUpload = (tokenId, memory, thumbnail) => {
        attachMemory(tokenId, memory, thumbnail);
    };

    return(
        <div style={{fontFamily: 'GucciSans'}} >

            { error &&
                <div>
                    <h4>{ error && JSON.stringify(error)}</h4>
                    <hr/>
                </div>
            }

            { isLoading && <h3>Uploading . . .</h3> }

            token id: <input type="number" onChange={handleTokenIdTextChange} />
            <br/>
            memory (max of of 10mb): <input type="file" onChange={handleMemoryFileChange} />
            <br/>
            thumbnail (max of of 5kb): <input type="file" onChange={handleThumbnailFileChange} />
            <br/>
            memory title: <input type="number" onChange={handleTokenMemoryTitleChange} />

            <br/><br/>

            <button onClick={()=>handleFileUpload(tokenId, memory, thumbnail, memoryTitle)}>Upload File</button>

        </div>
    );

}

export default AttachMemoryScreen;