const seriesTypes = {
    alpha: "alpha",
    phantom: "phantom",
    lumina: "lumina",
    myst: "myst",
    nova: "nova",
    catalyst: "catalyst",
    quantum: "quantum",
    serenity: "serenity",
    infinity: "infinity",
    zenith: "zenith"
};

const originalSeriesCoverArtDimensions = {
    width: 1890,
    height: 840
}

export {seriesTypes, originalSeriesCoverArtDimensions}