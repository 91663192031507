import { useState, useEffect, useRef } from 'react';
import { jwtDecode } from "jwt-decode";
import CgNftService from '../services/CgNftService';
import ApiService from '../services/apiService';
import useAPIRequest from "../hooks/useAPIRequest";

function useAuth(){

    const [jwt, setJWT] = useState(null);
    const [jwt_token, setJWTToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isFetchingOwnedTokens, setFetchingOwnedTokens] = useState(true);
    const abortControllerRef = useRef(new AbortController()); // for cancelling api requests to be triggered on view

    const [userOwnedCatalogList, setUserOwnedCatalogList] = useState([]);

    // useEffect(()=>{
    //     return () => {
    //         abortControllerRef.current.abort(); // Abort the ongoing request when the component unmounts or the effect re-runs
    //     };
    // }, [])

    const {isLoading: isFetchingProfile, data: profile, error: errorProfile, makeAPIRequest: fetchProfile} = useAPIRequest();

    useEffect(() => {
        // Check if user is logged in (JWT is stored in local storage)
        const storedJWT = localStorage.getItem("auth_token");
        if (storedJWT) {
            const decodedToken = jwtDecode(storedJWT);
            // Check if token has expired
            if (decodedToken.exp * 1000 < Date.now()) {
                // Token expired, clear from local storage
                localStorage.removeItem("auth_token");
                setJWT(null); // Reset JWT state
            } else {
                // Token still valid, set it in state
                setJWT(storedJWT);
            }
        }
    }, []);

    const signIn = async (wallet, signature) => {
        setIsLoading(true);
        setError(null);
        if(wallet == null && signature == null){
            setError("wallet and signature parameters are required.");
            return;
        }
        try {
            // abortControllerRef.current = ApiService.cancelTokenSource; // Reset the abort controller for each request
            const response = await CgNftService.signIn(wallet, signature);
            setJWT(response.data);
            localStorage.setItem("auth_token", response.data.jwt);
            setJWTToken(response.data.jwt);

        } catch (error) {
            setError(error.message)
        } finally {
            setIsLoading(false);
        }
    };

    const getProfile = async () => {
        fetchProfile(CgNftService.getProfile());
    }

    const getUserCatalogListByTokenList = async (tokenList) => {
        setFetchingOwnedTokens(true);
        setError(null);

        try {
            // abortControllerRef.current = ApiService.cancelTokenSource; // Reset the abort controller for each request
            const response = await CgNftService.getUserCatalogListByTokenList(tokenList);
            
            setUserOwnedCatalogList(response.data);

        } catch (error) {
            setError(error.message);

        } finally {
            setFetchingOwnedTokens(false);
        }
    };


    const cancelApiRequest = () => {
        ApiService.cancelRequest();
    };

    return {jwt, jwt_token, profile, isLoading, error, signIn, getProfile, cancelApiRequest, 
        userOwnedCatalogList, getUserCatalogListByTokenList, isFetchingProfile, isFetchingOwnedTokens, errorProfile
    };

}

export default useAuth;