import AlphaImage from "../images/series/alpha.png";
import LuminaImage from "../images/series/lumina.png";
import QuantumFirstImage from "../images/series/quantum_a.png";
import QuantumSecondImage from "../images/series/quantum_b.png";
import MystImage from "../images/series/myst.png";
import SerenityImage from "../images/series/serenity.png";
import NovaImage from "../images/series/nova.png";
import CatalystImage from "../images/series/catalyst.png";
import PhantomFirstImage from "../images/series/phantom_a.png";
import PhantomSecondImage from "../images/series/phantom_b.png";
import ZenithImage from "../images/series/zenith.png";
import InfinityImage from "../images/series/infinity.png";

const seriesImageSources = [
    {id: 0, image: AlphaImage, series: "alpha"},
    {id: 1, image: LuminaImage, series: "lumina"},
    {id: 2, image: QuantumFirstImage, series: "quantum"},
    {id: 3, image: NovaImage, series: "nova"},
    {id: 4, image: CatalystImage, series: "catalyst"},
    {id: 5, image: PhantomFirstImage, series: "phantom"},
    {id: 6, image: QuantumSecondImage, series: "quantum"},
    {id: 7, image: MystImage, series: "myst"},
    {id: 8, image: SerenityImage, series: "serenity"},
    {id: 9, image: PhantomSecondImage, series: "phantom"},
    {id: 10, image: ZenithImage, series: "zenith"},
    {id: 11, image: InfinityImage, series: "infinity"},
];

export {seriesImageSources}