import React from "react";
import {Skeleton, Paper, Grid, Box, Typography, List, ListItemText} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ViewGemDetailsSkeleton = ({breakpoints}) => {

    return (
        <Paper elevation={3} 
            sx={{
                top: "80px",
                bottom: "20px",
                left: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : {},
                right: "16px",
                padding: "16px",
                position: "fixed",       
                width: (breakpoints == "lg" || breakpoints == "xl") ? "450px" : {},
                overflowY: "auto",  
                borderRadius: "8px",
                boxShadow: "rgb(0 0 0 / 29%) 0px 4px 20px",
                zIndex: 1000,
                paddingTop: "0px !important",
            }}
        >
            <Box sx={{textAlign: "end", mt: 2}}>
                <CloseIcon sx={{color: "#808080", cursor: "pointer", fontSize: 20}} />
            </Box>
        
            <Grid container sx={{justifyContent: "center", mt: "20px"}}>
                <Skeleton animation="wave" variant="rect" sx={{height: 280, width: 190, borderRadius: "8px"}} />
            </Grid>

            <Grid container direction="column" sx={{mt: "21px", textAlign: "start"}}>
                <Grid container direction="row">
                    <Grid item xs={10} sx={{}}>
                        <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                    </Grid>
                </Grid>
                
                <Grid item xs={12} sx={{mb: "10px", fontSize: 18}}>
                    <Skeleton animation="wave" variant="text"  />
                </Grid>

                <Grid container sx={{mt: 0}}>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        ABOUT
                    </Typography>

                    <Grid container sx={{mt: "-3px", textAlign: "start"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            VARIATION
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            SERIES
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            TOKEN ID
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>
                    </Grid>

                    <Grid container sx={{textAlign: "start", mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            MEMORY TITLE
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            OWNER
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        STATS
                    </Typography>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            LEVEL
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            CLARITY
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            MULTIPLIER
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>
                    </Grid>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            CARAT
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            RATING
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{mt: 1}}>
                    <Typography sx={{fontWeight: "bold", fontFamily: 'GucciSans'}}>
                        PROPERTIES
                    </Typography>

                    <Grid container sx={{mt: "-3px"}}>
                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            BACKGROUND
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            GLOW
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>

                        <Grid item xs={4}>
                            <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                <ListItemText 
                                    primary={
                                        <Typography sx={{fontWeight: "bold", fontSize: 12, fontFamily: 'GucciSans'}}>
                                            GLITTER
                                        </Typography>
                                    }
                                    secondary={
                                        <Skeleton animation="wave" variant="text" sx={{width: "90%"}} />
                                    } 
                                />
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{display: "flex", mt: 1.5}}>
                <Skeleton animation="wave" variant="text" sx={{width: "100%", height: 60, borderRadius: "8px"}} />       
            </Grid>   
        </Paper>
    )
}

export default ViewGemDetailsSkeleton
