import {createStore, selector} from "./helper";

const module_name = "apiKey"

export const allSeriesStore = createStore((set) => ({
    
    // state
    isAllSeries: false,

    // actions
    setAllSeries: (isAllSeries) => set({isAllSeries: isAllSeries}, null, module_name + "/data"),

}), module_name)

export const useAllSeriesStore = () => allSeriesStore(selector)