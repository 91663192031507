import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Tooltip, IconButton, Paper, Grid, Typography, List, ListItemText, useMediaQuery, useTheme, Skeleton } from "@mui/material";
import AttachMemoryDialogComponent from "../attach-memory/dialog/attach-memory-dialog.component";
import { useNavigate } from "react-router";
import WhiteViewMemoryIcon from "../../icons/album-white.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GemDetailsPageViewSkeleton from "./skeleton/gem-details-page-view.skeleton";
import OpenseaIcon from "../../icons/blue-opensea.png";
import WhiteOpenseaIcon from "../../icons/white-opensea.png";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useWidth from "../../hooks/useWidth";

import AttachMemory from "../../icons/attachment.png";
import SuccessCOAIcon from "../../icons/success-coa.png";
import FailedCOAIcon from "../../icons/failed.png";
import PendingCOAIcon from "../../icons/pending.png";
import SuccessCOAReverseIcon from "../../icons/success-coa-reverse.png";
import FailedCOAReverseIcon from "../../icons/failed-reverse-coa.png";
import PendingCOAReverseIcon from "../../icons/pending-reverse-coa.png";
import ReactPlayer from "react-player";
import ViewMemoryIcon from "../../icons/album.png";
import useCybergemNft from "../../hooks/useCybergemNft";
import RetryConfirmationModal from "../view-memory/dialog/retry-confirmation-modal";
import {log} from "../../console-config.log";

const GemDetailsPageViewComponent = (props) => {

    const { details, attachMemory, isLoadingAttachMemory, successAttachment, isLoadingCybergemDetailView } = props;

    const { retryCOA, cybergemRetryCOA, isLoadingRetryCOA } = useCybergemNft();

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const breakpoints = useWidth();

    const [open, setOpen] = useState(false);
    const [openThumbnailCropper, setOpenThumbnailCropper] = useState(false);

    const [isImageLoaded, setImageLoaded] = useState(false);
    const [contentType, setContentType] = useState(null);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const isDarkMode = localStorage.getItem("darkMode");
    const isUserLoggedIn = localStorage.getItem("auth_token");
    const metamask = JSON.parse(localStorage.getItem("metamask"));
    // const liveCoaURL = "https://salmon-desert-0600ffb1e.4.azurestaticapps.net";
    const liveCoaURL = "https://cybergems.com";
    // const localCoaURL = "http://localhost:3000";

    const memoryURI = details?.links?.memory_uri;

    useEffect(() => {
        fetch(memoryURI, { method: 'HEAD' }).then(response => {
            const type = response.headers.get("content-type");

            setContentType(type);
        })
        .catch(error => 
            log.error("Error fetching content type:", error)
        );
    }, [memoryURI]);


    const handleImageLoad = () => {
        setImageLoaded(true);
    }

    const handleOpenModal = () => {
        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    const handleUpload = () => {
        setOpen(false);
    }

    const handleRightClick = (event) => {
        event.preventDefault();
    }


    function MoreMenu({ isDarkMode, details }) {
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = Boolean(anchorEl);

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        const handleViewCOA = () => {
            let liveTabURL = liveCoaURL + "/view-coa?tokenId=" + details?.catalog?.tokenID;

            window.location.href = liveTabURL;
        }

        const handleRedirectOpensea = () => {
            let openseaURL = details?.marketplace_view_url;

            window.open(openseaURL, "_blank");
        }

        const handleOpenConfirmationModal = () => {
            setOpenConfirmationModal(true)
        }



        return (
            <>
                <Tooltip title="More" arrow placement="top" sx={{ fontFamily: 'GucciSans' }}>
                    <IconButton sx={{ mt: "-11px", ml: "auto" }}
                        id="demo-positioned-button" aria-haspopup="true"
                        aria-controls={open ? "demo-positioned-menu" : undefined}
                        onClick={(e) => handleClick(e)} aria-expanded={open ? "true" : undefined}
                    >
                        <MoreHorizIcon sx={{
                            // color: (isDarkMode === "false") ? "#000" : {} 
                        }} />
                    </IconButton>
                </Tooltip>

                <Menu sx={{ borderRadius: "10px" }}
                    open={openMenu} onClose={handleClose}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                    {(details?.links?.memory_thumbnail) &&
                        <>
                            {details?.catalog?.coa_status === "S" ?
                                <MenuItem sx={{ fontSize: 14, fontFamily: 'GucciSans' }} onClick={(e) => handleViewCOA()}>
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px" }}
                                        src={SuccessCOAReverseIcon}
                                    // src={(isDarkMode === "false") ? SuccessCOAIcon : SuccessCOAReverseIcon}
                                    />
                                    View Certificate
                                </MenuItem>
                                :
                                details?.catalog?.coa_status === "P" ?
                                    <MenuItem sx={{ fontSize: 14, cursor: "default", fontFamily: 'GucciSans' }}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px" }}
                                            src={PendingCOAReverseIcon}
                                        // src={(isDarkMode === "false") ? PendingCOAIcon : PendingCOAReverseIcon} 
                                        />
                                        Pending Certificate
                                    </MenuItem>
                                    :
                                    <MenuItem sx={{ fontSize: 14, fontFamily: 'GucciSans' }}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 20, width: 20, marginRight: "8px" }}
                                            onClick={handleOpenConfirmationModal}
                                            src={FailedCOAReverseIcon}
                                        // src={(isDarkMode === "false") ? FailedCOAIcon : FailedCOAReverseIcon} 
                                        />
                                        Regenerate Certificate
                                    </MenuItem>
                            }
                        </>
                    }

                    <MenuItem sx={{ fontSize: 14, fontFamily: 'GucciSans' }} onClick={(e) => handleRedirectOpensea()}>
                        <img alt="CyberGems - A collection of 8,888 NFT gems"
                            src={WhiteOpenseaIcon}
                            // src={(isDarkMode === "false") ? OpenseaIcon : WhiteOpenseaIcon}
                            style={{ height: (isDarkMode === "false") ? 20 : 18, marginTop: "-2px", marginRight: "8px" }}
                        />

                        View in Opensea
                    </MenuItem>
                </Menu>
            </>
        )
    }


    if (isLoadingCybergemDetailView) return <GemDetailsPageViewSkeleton isDarkMode={isDarkMode} breakpoints={breakpoints} />


    return (
        <>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={5.5}>
                    {isSmScreen ?
                        <Tooltip  disableFocusListener  title="Scroll for more" placement="top" arrow sx={{fontFamily: 'GucciSans !important'}} >
                            <div onContextMenu={handleRightClick}>
                                <TransformWrapper maxScale={1.7} >
                                    <TransformComponent wrapperStyle={{ borderRadius: "20px", boxShadow: "rgb(0 0 0 / 22%) 0px 4px 20px", width: 'auto', margin: 'auto', filter: 'contrast(120%)' }}>
                                        <img
                                            alt="CyberGems - A collection of 8,888 NFT gems"
                                            src={details?.links?.img}
                                            style={{
                                                height: 581,
                                                width: '100%',
                                                borderRadius: "20px",
                                                position: 'relative'
                                            }}
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </Tooltip>
                    :
                        <div onContextMenu={handleRightClick}>
                            <TransformWrapper maxScale={1.7} >
                                <TransformComponent wrapperStyle={{ borderRadius: "20px", boxShadow: "rgb(0 0 0 / 22%) 0px 4px 20px", width: '387px', margin: 'auto', filter: 'contrast(120%)' }}>
                                    <img
                                        alt="CyberGems - A collection of 8,888 NFT gems"
                                        src={details?.links?.img}
                                        style={{
                                            height: 581,
                                            width: '100%',
                                            borderRadius: "20px",
                                            position: 'relative'
                                        }}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    }

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6.5}>
                    <Grid sx={{ display: "flex" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 20, fontFamily: 'GucciSans' }}>
                            #{details?.catalog?.tokenID} {details?.catalog?.name}
                        </Typography>

                        <MoreMenu isDarkMode={isDarkMode} details={details} />
                    </Grid>

                    <Grid sx={{ textAlign: "start" }}>
                        <Typography sx={{
                            fontSize: 14, fontFamily: 'GucciSans'
                        }}>
                            {details?.catalog?.description}
                        </Typography>
                    </Grid>

                    <Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={details?.links?.memory_thumbnail ? 9.5 : 12}
                                lg={
                                    (details?.links?.memory_thumbnail && contentType?.startsWith('video/')) ? 9
                                        :
                                        (details?.links?.memory_thumbnail && contentType?.startsWith('image/')) ? 9.5
                                            :
                                            12
                                }
                            >
                                <Paper elevation={0} sx={{
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    borderRadius: 4,
                                    border: "1px solid #8080802e",
                                    mt: 2,
                                }}>
                                    <Grid container sx={{ mt: "5px", padding: "10px 16px 10px 24px" }}>
                                        <Typography sx={{ fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans' }}>
                                            ABOUT
                                        </Typography>

                                        <Grid container sx={{ mt: "10px", textAlign: "start" }}>
                                            <Grid item xs={4}>
                                                <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                                    <ListItemText sx={{ display: "inline-grid" }}
                                                        primary={
                                                            <Typography sx={{
                                                                fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                            }}>
                                                                VARIATION
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography sx={{ fontSize: 14, fontFamily: 'GucciSans', fontWeight: "bold", textAlign: 'start' }}>
                                                                {details?.catalog?.variation}
                                                            </Typography>
                                                        }
                                                    />
                                                </List>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                                    <ListItemText sx={{ display: "inline-grid" }}
                                                        primary={
                                                            <Typography sx={{
                                                                fontFamily: 'GucciSans', fontSize: 12, textAlign: 'start',
                                                            }}>
                                                                SERIES
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography sx={{ fontSize: 14, fontFamily: 'GucciSans', fontWeight: "bold", textAlign: "start", cursor: "pointer", color: "#0178f2" }}
                                                                onClick={(e) => navigate(`/series/${details?.catalog?.series.toLowerCase()}`)}
                                                            >
                                                                {details?.catalog?.series}
                                                            </Typography>
                                                        }
                                                    />
                                                </List>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                                    <ListItemText sx={{ display: "inline-grid" }}
                                                        primary={
                                                            <Typography sx={{
                                                                fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                            }}>
                                                                TOKEN ID
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography sx={{ fontSize: 14, fontFamily: 'GucciSans', fontWeight: "bold", textAlign: 'start' }}>
                                                                {details?.catalog?.tokenID}
                                                            </Typography>
                                                        }
                                                    />
                                                </List>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ textAlign: "start" }}>
                                            <Grid item xs={4}>
                                                <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                                    <ListItemText sx={{ display: "inline-grid" }}
                                                        primary={
                                                            <Typography sx={{
                                                                fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                            }}>
                                                                MEMORY TITLE
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography sx={{ fontSize: 14, fontFamily: 'GucciSans', fontWeight: "bold", textAlign: 'start' }}>
                                                                {(details?.catalog?.memory_title == null) ? "---" : details?.catalog?.memory_title}
                                                            </Typography>
                                                        }
                                                    />
                                                </List>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                                    <ListItemText sx={{ display: "inline-grid" }}
                                                        primary={
                                                            <Typography sx={{
                                                                fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                            }}>
                                                                OWNER
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Tooltip arrow title={(details?.owner_address) ? details?.owner_address : null} placement="top" sx={{ fontFamily: 'GucciSans' }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        fontWeight: "bold",
                                                                        whiteSpace: 'nowrap',
                                                                        width: ((breakpoints === "xl" || breakpoints === "lg" || breakpoints === "md" || breakpoints === "sm") && details?.owner_address?.length > 18) ?
                                                                            "230px"
                                                                            :
                                                                            "80px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        textAlign: "start",
                                                                        color: "#fff",
                                                                        // color: (isDarkMode === "false") ? "#000" : "#fff",
                                                                        fontFamily: 'GucciSans'
                                                                    }}
                                                                >
                                                                    {details?.owner_address ?
                                                                        details?.owner_address?.toLowerCase()
                                                                        :
                                                                        "---"
                                                                    }
                                                                </Typography>
                                                            </Tooltip>
                                                        }
                                                    />
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>

                            </Grid>

                            {contentType && (
                                <>
                                    {contentType.startsWith('image/') ? (
                                        <Grid item xs={12} sm={12} md={2.5} sx={{ alignSelf: 'center', mt: 2 }}>
                                            <>
                                                {!isImageLoaded && (
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="rect"
                                                        width="150px"
                                                        height="160px"
                                                        style={{ borderRadius: "20px" }}
                                                    />
                                                )}

                                                <img
                                                    alt="CyberGems - A collection of 8,888 NFT gems"
                                                    src={details?.links?.memory_uri}
                                                    onLoad={() => {
                                                        setImageLoaded(true);
                                                        handleImageLoad();
                                                    }}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = details?.links?.memory_thumbnail;
                                                    }}
                                                    style={{
                                                        display: isImageLoaded ? 'block' : 'none',
                                                        height: isSmScreen ? 'auto' : 160,
                                                        width: "100%",
                                                        objectFit: 'cover',
                                                        borderRadius: "20px",
                                                        zIndex: 1,
                                                        filter: 'contrast(120%)',
                                                        border: "1px solid #8080802e",
                                                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={(e) => navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                                                    onContextMenu={handleRightClick}
                                                />
                                            </>
                                        </Grid>

                                    ) : contentType.startsWith('video/') ? (
                                        <Grid item xs={12} sm={12} md={2.5} lg={3} sx={{ margin: "14px auto" }}>
                                            <ReactPlayer
                                                url={memoryURI}
                                                playing={true}
                                                controls={true}
                                                width="100%"
                                                height="inherit"
                                                muted={true}
                                                style={{ objectFit: "contain", textAlign: "start", marginTop: "10px", height: "100%" }}
                                            />

                                            <Grid sx={{ textAlign: "end" }}>
                                                <Tooltip title="View Memory" arrow placement="top" sx={{ fontFamily: 'GucciSans' }}>
                                                    <IconButton onClick={(e) => navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}>
                                                        <img alt="CyberGems - A collection of 8,888 NFT gems"
                                                            src={WhiteViewMemoryIcon}
                                                            // src={(isDarkMode === "false") ? ViewMemoryIcon : WhiteViewMemoryIcon} 
                                                            style={{ height: 16, marginTop: "-2px" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        null
                                    )}
                                </>
                            )}
                        </Grid>

                        <Paper elevation={0} sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: 4,
                            border: "1px solid #8080802e",
                            mt: 2,
                        }}>
                            <Grid container sx={{ mt: "5px", padding: "10px 16px 10px 24px" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans' }}>
                                    STATS
                                </Typography>

                                <Grid container sx={{ mt: "10px", textAlign: "start" }}>
                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {}, 
                                                    }}>
                                                        LEVEL
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontWeight: "bold", fontSize: 14, textAlign: 'start', fontFamily: 'GucciSans' }}>
                                                        {details?.catalog?.level}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {}
                                                    }}>
                                                        CLARITY
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontWeight: "bold", fontSize: 14, textAlign: 'start', fontFamily: 'GucciSans' }}>
                                                        {details?.catalog?.clarity}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {}
                                                    }}>
                                                        MULTIPLIER
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontWeight: "bold", fontFamily: 'GucciSans', fontSize: 14, textAlign: 'start' }}>
                                                        {details?.catalog?.multiplier}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ textAlign: "start" }}>
                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {},
                                                    }}>
                                                        CARAT
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontWeight: "bold", fontFamily: 'GucciSans', fontSize: 14, textAlign: 'start' }}>
                                                        {details?.catalog?.carat}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontFamily: 'GucciSans', fontSize: 12, textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {},
                                                    }}>
                                                        RATING
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontFamily: 'GucciSans', fontWeight: "bold", fontSize: 14, textAlign: 'start' }}>
                                                        {details?.catalog?.rating}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: 4,
                            border: "1px solid #8080802e",
                            mt: 2,
                        }}>
                            <Grid container sx={{ mt: "5px", padding: "10px 16px 10px 24px" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans' }}>
                                    PROPERTIES
                                </Typography>

                                <Grid container sx={{ mt: "10px", textAlign: "start" }}>
                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontSize: 12, fontFamily: 'GucciSans', textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {},
                                                    }}>
                                                        BACKGROUND
                                                    </Typography>
                                                }
                                                secondary={(breakpoints === "lg" || breakpoints === "md") ? (
                                                    <Tooltip title={details?.catalog?.background} arrow placement="top-start" sx={{ fontFamily: 'GucciSans' }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                whiteSpace: "nowrap",
                                                                width: (breakpoints === "md" && details?.catalog?.background.length > 13) ?
                                                                    "110px"
                                                                    :
                                                                    "250px",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                textAlign: "start",
                                                                color: "#fff",
                                                                // color: (isDarkMode === "false") ? "#000" : "#fff",
                                                                fontFamily: 'GucciSans'
                                                            }}
                                                        >
                                                            {details?.catalog?.background}
                                                        </Typography>
                                                    </Tooltip>
                                                )
                                                    :
                                                    (
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                textAlign: "start",
                                                                fontFamily: 'GucciSans'
                                                            }}
                                                        >
                                                            {details?.catalog?.background}
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontFamily: 'GucciSans', fontSize: 12, textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {},
                                                    }}>
                                                        GLOW
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontFamily: 'GucciSans', fontWeight: "bold", fontSize: 14, textAlign: 'start' }}>
                                                        {details?.catalog?.glow}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{ width: "100%", pt: "0px !important", pb: "0px !important" }}>
                                            <ListItemText sx={{ display: "inline-grid" }}
                                                primary={
                                                    <Typography sx={{
                                                        fontFamily: 'GucciSans', fontSize: 12, textAlign: 'start',
                                                        // color: (isDarkMode === "false") ? "hsla(0 0% 20% / 100)" : {},
                                                    }}>
                                                        GLITTER
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontFamily: 'GucciSans', fontWeight: "bold", fontSize: 14, textAlign: 'start' }}>
                                                        {details?.catalog?.glitter}
                                                    </Typography>
                                                }
                                            />
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid sx={{ mt: 2 }}>
                        {(isUserLoggedIn && details?.owner_address?.toLowerCase() === metamask?.wallet_address) ?
                            (details?.links?.memory_thumbnail) ?
                                <button className="btn-donate" onClick={() => navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                                    style={{ width: "100%", height: "38px", display: "flex", justifyContent: "center" }}
                                >
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" src={WhiteViewMemoryIcon} style={{ height: 24, marginTop: "-2px" }} />

                                    <Typography sx={{ color: "#fff", ml: 1, mt: "-2px", fontFamily: 'GucciSans' }}>
                                        View Memory
                                    </Typography>
                                </button>
                                :
                                null
                            :
                            (details?.links?.memory_thumbnail) ?
                                <button className="btn-donate" onClick={() => navigate("/view-memory?tokenId=" + details?.catalog?.tokenID)}
                                    style={{ width: "100%", height: "38px", display: "flex", justifyContent: "center" }}
                                >
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" src={WhiteViewMemoryIcon} style={{ height: 24, marginTop: "-2px" }} />

                                    <Typography sx={{ color: "#fff", ml: 1, mt: "-2px", fontFamily: 'GucciSans' }}>
                                        View Memory
                                    </Typography>
                                </button>
                                :
                                null
                        }
                    </Grid>
                </Grid>

                <AttachMemoryDialogComponent
                    open={open}
                    close={handleCloseModal}
                    details={details}
                    handleUpload={handleUpload}
                    openThumbnailCropper={openThumbnailCropper}
                    isLoadingAttachMemory={isLoadingAttachMemory}
                    setOpenThumbnailCropper={setOpenThumbnailCropper}
                    attachMemory={attachMemory}
                    setOpen={setOpen}
                    successAttachment={successAttachment}
                />

                <RetryConfirmationModal
                    openConfirmationModal={openConfirmationModal}
                    setOpenConfirmationModal={setOpenConfirmationModal}
                    retryCOA={retryCOA}
                    isLoadingRetryCOA={isLoadingRetryCOA}
                    tokenID={details?.catalog?.tokenID}
                    cybergemRetryCOA={cybergemRetryCOA}
                />
            </Grid>
        </>
    )
}

export default GemDetailsPageViewComponent