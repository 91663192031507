import React from "react";
import HomeScreenV2 from "./HomeScreenV2";
import AllSeriesScreen from "./AllSeriesScreen";
import { useAllSeriesStore } from "../zustand/useAllSeriesStore";

const MainHomeScreen = () => {

    const { isAllSeries } = useAllSeriesStore();
  
    return (
       <>
            {isAllSeries ?
                <AllSeriesScreen />
            :
                <HomeScreenV2 />
            }
       </>
    )
}

export default MainHomeScreen