import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import useCybergemABIContract from "../../hooks/useCybergemABIContract";
import useCybergemNft from '../../hooks/useCybergemNft';
import { seriesTypes, originalSeriesCoverArtDimensions } from "../../common/staticVariables";
import { FormControlLabel, Paper, Switch, Typography, Skeleton, TextField, InputAdornment, IconButton, Box } from '@mui/material';

import { styled } from '@mui/material/styles';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseIcon from '@mui/icons-material/Close';
import ErrorTokenDialog from '../dialog/error-message/error-token-dialog';
import useWidth from '../../hooks/useWidth';
import {log} from "../../console-config.log";
import ClipLoader from "react-spinners/ClipLoader";
import BeatLoader from "react-spinners/BeatLoader";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#177ddc',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SeriesCoverArtComponent = (props) => {

  const { series, wallet_address, setGemClicked, setGemTokenId, handleImageLoad, isImageLoaded } = props;
  const breakpoints = useWidth();
  const { ownedTokens, getOwnerTokenIds } = useCybergemABIContract();
  const { getCybergemInfo, cybergemInfoData } = useCybergemNft();

  const [memoryTokens, setMemoryTokens] = useState([])
  const [seriesCoverArt, setSeriesCoverArt] = useState(null)
  const [cgNfts, setCgNfts] = useState([]);
  const [currentHoveredItem, setCurrentHoveredItem] = useState(null)
  const [customCoverArtMarginLeft, setCustomCoverArtMarginLeft] = useState(0)

  const divRef = useRef(null);

  const [percentageDimensionsDifference, setPercentageDimensionsDifference] = useState({ width: null, height: null });
  const [dimensionsCoverArt, setDimensionsCoverArt] = useState({ width: null, height: null });
  const [searchTokenId, setSearchTokenId] = useState(""); // State variable to store the token ID entered in the TextField
  const [searchInput, setSearchInput] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  useEffect(() => {
    getCybergemInfo()
  }, [])


  useEffect(() => {
    if (wallet_address) getOwnerTokenIds(wallet_address)
  }, [wallet_address])

  useEffect(() => {
    if (cybergemInfoData) {

      log("cybergemInfoData xxxx", cybergemInfoData);

      const series_cover_art_json = cybergemInfoData.cover_art_plots;
      const series_cover_art_img = cybergemInfoData.cover_art_links;
      const memory_tokens = cybergemInfoData.memory_links

      const { series_cover_art_img_url, series_cover_art_json_url, memory_tokens_url } = getCybergemInfoLinks(series, series_cover_art_json, series_cover_art_img, memory_tokens)

      const fetchSeriesCoverArtJSON = async (series_cover_art_json_url) => {
        try {
          const response = await axios.get(series_cover_art_json_url);
          setCgNfts(response.data)
        } catch (error) {
          log.error('Error fetching JSON data:', error);
        }
      };

      const fetchMemoryTokensJSON = async (memory_tokens_url) => {
        try {
          const response = await axios.get(memory_tokens_url);
          setMemoryTokens(response.data.token_memories)
        } catch (error) {
          log.error('Error fetching JSON data:', error);
        }
      };

      // fetch alpha json
      fetchSeriesCoverArtJSON(series_cover_art_json_url);
      // fetch memory tokens
      fetchMemoryTokensJSON(memory_tokens_url);
      // cover art bg image
      // Check if the component is mounting (first render)
      if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {
        // Page is reloaded, add timestamp as a query parameter
        const timestamp = Date.now(); // Get current timestamp in milliseconds
        const urlWithTimestamp = `${series_cover_art_img_url}?time=${Math.floor(timestamp / 1000)}`;
        setSeriesCoverArt(urlWithTimestamp); // Update image URL with timestamp
      } else {
        // Regular render (not a reload), use original URL without timestamp
        setSeriesCoverArt(series_cover_art_img_url);
      }
    }
  }, [series, JSON.stringify(cybergemInfoData)])

  useEffect(() => {

    const updateDimensions = () => {

      if (divRef.current) {

        log("divRef.current width", divRef.current.clientWidth);
        log("divRef.current height", divRef.current.clientHeight);

        // 1. get difference of original image vs window size
        let accurateWidthDiff = calculatePercentage(divRef.current.clientWidth, originalSeriesCoverArtDimensions.width)
        let accurateHeightDiff = calculatePercentage(divRef.current.clientHeight, originalSeriesCoverArtDimensions.height)

        // 2. fix for overlapping squares cause by values with decimals
        // 2.1 for <area> tags inside map tag
        let roundDownWidth = Math.floor(accurateWidthDiff / 10) * 10;
        let roundDownHeight = Math.floor(accurateHeightDiff / 10) * 10;

        setPercentageDimensionsDifference({ width: roundDownWidth, height: roundDownHeight });

        // 2.2 for cover art
        var roundDownCoverArtWidth = calculateSizeWithPercentage(originalSeriesCoverArtDimensions.width, roundDownWidth);
        var roundDownCoverArtHeight = calculateSizeWithPercentage(originalSeriesCoverArtDimensions.height, roundDownWidth); //TODO refactor code
        setDimensionsCoverArt({ width: roundDownCoverArtWidth, height: roundDownCoverArtHeight });

        let custom_cover_art_margin_left = (divRef.current.clientWidth - roundDownCoverArtWidth) / 2 // Cut it half so that it will center
        setCustomCoverArtMarginLeft(custom_cover_art_margin_left)

      }

    };

    const calculateSizeWithPercentage = (originalNumber, percentage) => {
      const result = originalNumber + (originalNumber * percentage / 100);
      return result;
    }

    const calculatePercentage = (targetSize, originalSize) => {
      if (originalSize !== 0) {
        const percentage = (targetSize / originalSize) * 100;
        log(`${targetSize} is ${percentage}% of ${originalSize}`);
        return percentage - 100;
      } else {
        log('Cannot calculate percentage when originalSize is zero.')
        return 0;
      }
    };

    const resizeObserver = new ResizeObserver(updateDimensions);

    // Start observing the target element (divRef)
    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    // Cleanup: Disconnect the ResizeObserver when the component unmounts
    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
      resizeObserver.disconnect();
    };

  }, []); // Empty dependency array means this effect runs once on mount

  const getCybergemInfoLinks = (series, series_cover_art_json, series_cover_art_img, memory_tokens) => {
    let series_cover_art_json_url, series_cover_art_img_url, memory_tokens_url;

    switch (series) {
      case seriesTypes.alpha:
        series_cover_art_json_url = series_cover_art_json["alpha_cover_art_plots"]
        memory_tokens_url = memory_tokens["alpha_memory_link"]
        series_cover_art_img_url = series_cover_art_img["alpha_cover_art_link"]
        break;
      case seriesTypes.phantom:
        series_cover_art_json_url = series_cover_art_json["phantom_cover_art_plots"]
        memory_tokens_url = memory_tokens["phantom_memory_link"]
        series_cover_art_img_url = series_cover_art_img["phantom_cover_art_link"]
        break;
      case seriesTypes.lumina:
        series_cover_art_json_url = series_cover_art_json["lumina_cover_art_plots"]
        memory_tokens_url = memory_tokens["lumina_memory_link"]
        series_cover_art_img_url = series_cover_art_img["lumina_cover_art_link"]
        break;
      case seriesTypes.myst:
        series_cover_art_json_url = series_cover_art_json["myst_cover_art_plots"]
        memory_tokens_url = memory_tokens["myst_memory_link"]
        series_cover_art_img_url = series_cover_art_img["myst_cover_art_link"]
        break;
      case seriesTypes.nova:
        series_cover_art_json_url = series_cover_art_json["nova_cover_art_plots"]
        memory_tokens_url = memory_tokens["nova_memory_link"]
        series_cover_art_img_url = series_cover_art_img["nova_cover_art_link"]
        break;
      case seriesTypes.catalyst:
        series_cover_art_json_url = series_cover_art_json["catalyst_cover_art_plots"]
        memory_tokens_url = memory_tokens["catalyst_memory_link"]
        series_cover_art_img_url = series_cover_art_img["catalyst_cover_art_link"]
        break;
      case seriesTypes.quantum:
        series_cover_art_json_url = series_cover_art_json["quantum_cover_art_plots"]
        memory_tokens_url = memory_tokens["quantum_memory_link"]
        series_cover_art_img_url = series_cover_art_img["quantum_cover_art_link"]
        break;
      case seriesTypes.serenity:
        series_cover_art_json_url = series_cover_art_json["serenity_cover_art_plots"]
        memory_tokens_url = memory_tokens["serenity_memory_link"]
        series_cover_art_img_url = series_cover_art_img["serenity_cover_art_link"]
        break;
      case seriesTypes.infinity:
        series_cover_art_json_url = series_cover_art_json["infinity_cover_art_plots"]
        memory_tokens_url = memory_tokens["infinity_memory_link"]
        series_cover_art_img_url = series_cover_art_img["infinity_cover_art_link"]
        break;
      case seriesTypes.zenith:
        series_cover_art_json_url = series_cover_art_json["zenith_cover_art_plots"]
        memory_tokens_url = memory_tokens["zenith_memory_link"]
        series_cover_art_img_url = series_cover_art_img["zenith_cover_art_link"]
        break;
    }

    return { series_cover_art_json_url, series_cover_art_img_url, memory_tokens_url }
  }

  const onMouseoverNFT = (id) => {
    setCurrentHoveredItem(id)
  }

  const onMouseoutNFT = () => {
    setCurrentHoveredItem(null)
  }

  const url = window.location.pathname;

  const [isFocused, setIsFocused] = useState(
    localStorage.getItem('isFocused') === 'true'
  );

  const [isSearchFocused, setSearchIsFocused] = useState(
    localStorage.getItem('isSearchFocused') === 'true'
  )

  const handleSearchTokenIdChange = (event) => {
    const newSearchTokenId = event.target.value;

    if (/^\d*$/.test(newSearchTokenId)) {
      setSearchInput(newSearchTokenId);
    }

    if (!newSearchTokenId) {
      setSearchInput(null);
    }
  }


  const keyPressModule = (e) => {
    if (e.keyCode === 13) {
      if (searchInput) {
        const isInputFound = cgNfts.some(item => item.token_id === parseInt(searchInput));

        setSearchTokenId(searchInput);
        setSearchIsFocused(true);

        if (!isInputFound) {
          setOpenErrorDialog(true)
        }

      } else if (!searchInput && e.keyCode === 13) {
        setSearchIsFocused(false);
        setSearchTokenId("");
      }
    }
  };

  useEffect(() => {
    if (!searchInput) {
      setOpenErrorDialog(false);
      setIsFocused(false);
      setSearchIsFocused(false);
      setSearchTokenId("");
      setSearchInput("")
    }
  }, [searchInput])

  const handleRemoveInput = () => {
    setIsFocused(false);
    setSearchIsFocused(false);
    setSearchTokenId("");
    setSearchInput("")
  }

  const handleClose = () => {
    setOpenErrorDialog(false);
    setIsFocused(false);
    setSearchIsFocused(false);
    setSearchTokenId("");
    setSearchInput("")
  }

  useEffect(() => {
    localStorage.setItem('isFocused', isFocused);
  }, [isFocused]);

  useEffect(() => {
    localStorage.setItem('isSearchFocused', isSearchFocused);
  }, [isSearchFocused,]);

  useEffect(() => {

    if (url !== '/series') {
      localStorage.removeItem('isFocused');
    }

  }, [url])

  const handleFocusTokens = () => {
    setIsFocused((prevIsFocused) => !prevIsFocused);
  };


  const handleRightClick = (event) => {
    event.preventDefault();
  }

  const isAuthToken = localStorage.getItem("auth_token");
  const isSmOrXs = breakpoints === 'sm' || breakpoints === 'xs';
  const maxScale = isSmOrXs ? 5.0 : 1.3;


  return (
    <>
      {/* <button onClick={handleFocusTokens}>{isFocused ? "Hokus" : "Pokus"}</button> */}
      <div style={{ display: 'flex', alignItems: 'center', textAlign: 'start', marginLeft: customCoverArtMarginLeft, marginRight: customCoverArtMarginLeft, marginBottom: 10 }}>
        {(isAuthToken || (ownedTokens?.length > 0)) &&
          <div style={{ justifyContent: 'flex-start', textAlign: 'start' }}>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} onClick={handleFocusTokens} checked={isFocused} />}
              label={<Typography sx={{ fontFamily: 'GucciSans' }}>Focus</Typography>}
            />

          </div>
        }
        <Box sx={{ flexGrow: 1 }} />
        {isImageLoaded &&
          <TextField
            placeholder="Token ID"
            value={searchInput}
            onChange={handleSearchTokenIdChange}
            onKeyDown={keyPressModule}
            sx={{
              "& .MuiInputBase-root": {
                width: (isSmOrXs) ? 130 : "auto",
                paddingRight: "0px !important",
                paddingLeft: (isSmOrXs) ? "8px" : {},
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: 12, 
                fontStyle: 'italic', 
                fontFamily: 'GucciSans'
              }
            }}
            inputProps={{
              style: {
                padding: (isSmOrXs) ? "3px" : "5px", 
                fontSize: (isSmOrXs) ? 9 : 12, 
                fontStyle: 'italic', 
                fontFamily: 'GucciSans'
              } 
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p style={{fontWeight: 'bold', fontSize: (isSmOrXs) ? 9 : 12, fontFamily: 'GucciSans'}}>
                    Search: 
                  </p>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <p style={{fontWeight: 'bold', fontSize: (isSmOrXs) ? 9 : 12, fontFamily: 'GucciSans'}}>
                    {searchInput && 
                      <IconButton onClick={() => handleRemoveInput()}>
                        <CloseIcon sx={{fontSize: (isSmOrXs) ? "15px" : "1.2rem"}} />
                      </IconButton>
                    }
                  </p>
                </InputAdornment>
              ),
            }}
          />
        }
      </div>

      <div ref={divRef}
        onMouseOut={onMouseoutNFT}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          marginLeft: customCoverArtMarginLeft
        }}
      >
         
        {!isImageLoaded && (
          <Box position="relative">
            <Skeleton
              animation="wave"
              variant="rect"
              width={`${dimensionsCoverArt.width}px`}
              height={`${dimensionsCoverArt.height}px`}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  color: "#fff",
                  visibility: "visible"
                }}
              >
                <Typography sx={{fontFamily: "GucciSans", fontSize: (breakpoints === "xs" || breakpoints === "sm") ? 16 : 28 }}>
                  Loading
                </Typography>
                <BeatLoader color="#fff" size={(breakpoints === "xs" || breakpoints === "sm") ? 10 : 20} speedMultiplier={0.8} />
              </Box>
            </Skeleton>
          </Box>  
        )}

        <div onContextMenu={handleRightClick}>
          <TransformWrapper maxScale={maxScale}>
            <TransformComponent
              wrapperStyle={{
                boxShadow: 'rgb(0 0 0 / 54%) 0px 4px 20px',
                filter: "contrast(120%)",
                visibility: (isImageLoaded) ? "visible" : "hidden", overflow: 'visible'
              }}
            >

              <img
                style={{
                  width: 'inherit',
                  height: 'inherit',
                  maxHeight: dimensionsCoverArt.height,
                  maxWidth: dimensionsCoverArt.width,
                  display: 'block',
                  boxShadow: 'rgb(0 0 0 / 54%) 0px 4px 20px',
                  visibility: isImageLoaded ? 'visible' : 'hidden', // hide image until loaded
                  imageRendering: "-webkit-optimize-contrast",
                }}
                useMap="#imageMap"
                src={seriesCoverArt}
                alt="CyberGems - A collection of 8,888 NFT gems"
                onLoad={handleImageLoad}
              />

              {percentageDimensionsDifference.height != null && percentageDimensionsDifference.height != -100 &&
                cgNfts.map((item, i) => {
                  var isTokenOwned = false;

                  if (ownedTokens) {
                    isTokenOwned = Array.isArray(ownedTokens) && item.token_id == ownedTokens?.find((id) => id == item.token_id);

                    if (searchTokenId) {
                      isTokenOwned = item.token_id == searchTokenId;
                    }
                  }
                  return (
                    <AreaComponent
                      key={i}
                      item={item}
                      percentageHeightDifference={percentageDimensionsDifference.height}
                      percentageWidthDifference={percentageDimensionsDifference.width}
                      onMouseoverNFT={onMouseoverNFT}
                      currentHoveredItem={currentHoveredItem}
                      isTokenOwned={isTokenOwned}
                      setGemClicked={setGemClicked}
                      setGemTokenId={setGemTokenId}
                      isFocused={isFocused} isSearchFocused={isSearchFocused}
                      breakpoints={breakpoints}
                    />
                  )
                })
              }
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
      <ErrorTokenDialog openErrorDialog={openErrorDialog} handleClose={handleClose} series={series} />
    </>
  )
}

function AreaComponent({ item, percentageWidthDifference, percentageHeightDifference, currentHoveredItem, onMouseoverNFT,
  isTokenOwned, setGemClicked, setGemTokenId, isFocused, isSearchFocused, breakpoints }) {

  const [coordsCalculated, setCoordsCalculated] = useState({ x1: null, y1: null, x2: null, y2: null });

  useEffect(() => {

    var _x1 = calculateSizeWithPercentage(item.x1, percentageWidthDifference);
    var _y1 = calculateSizeWithPercentage(item.y1, percentageHeightDifference);
    var _x2 = calculateSizeWithPercentage(item.x2, percentageWidthDifference);
    var _y2 = calculateSizeWithPercentage(item.y2, percentageHeightDifference);

    setCoordsCalculated({ x1: _x1, y1: _y1, x2: _x2, y2: _y2 });

  }, [percentageWidthDifference, percentageHeightDifference])

  function calculateSizeWithPercentage(originalNumber, percentage) {
    const result = originalNumber + (originalNumber * percentage / 100);
    return result;
  }

  var calculatedWidth = (coordsCalculated.x2 - coordsCalculated.x1);
  var calculatedHeight = (coordsCalculated.y2 - coordsCalculated.y1);

  const handleGemClicked = (tokenId) => {
    setGemClicked(true);
    setGemTokenId(tokenId);
  }

  return (
    <>
      <div
        onClick={(e) => (currentHoveredItem === item.token_id) || (breakpoints === 'sm' || breakpoints === 'xs') ? handleGemClicked(item.token_id) : null}
        onMouseOver={() => onMouseoverNFT(item.token_id)}
        className={!isFocused && !isSearchFocused ? "" : (isTokenOwned || currentHoveredItem === item.token_id) ? "" : 'dim-overlay'}
        style={{
          cursor: 'pointer', width: calculatedWidth, height: calculatedHeight,
          position: 'absolute',
          boxSizing: "border-box",
          top: coordsCalculated.y1, left: coordsCalculated.x1,
          right: coordsCalculated.x2, bottom: coordsCalculated.y2
        }}
      />
      {
        currentHoveredItem === item.token_id &&
        <Paper onClick={(e) => breakpoints === 'sm' || breakpoints === 'xs' ? handleGemClicked(item.token_id) : undefined} sx={{ position: 'absolute', top: coordsCalculated.y2, left: coordsCalculated.x1, zIndex: 1 }}>
          <Typography sx={{ m: 1, fontFamily: 'GucciSans' }}>
            Token ID: {item.token_id}
          </Typography>
        </Paper>
      }
    </>
  )

}

export default SeriesCoverArtComponent