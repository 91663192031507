import TestModal from "../screens/TestModal.jsx";
import HomeScreen from "../screens/HomeScreen.jsx";
import FAQScreen from "../screens/FAQScreen.jsx";
import SeriesCoverArtScreen from "../screens/SeriesCoverArtScreen.jsx";
import ProfileScreen from "../screens/ProfileScreen.jsx";
import GemDetailsPageViewScreen from "../screens/GemDetailsPageViewScreen.jsx";
import HomeScreenV2 from "../screens/HomeScreenV2.jsx";
import ViewCOAScreen from "../screens/ViewCOAScreen.jsx";
import TestScreen from "../screens/TestScreen.jsx";
import ViewMemoryScreen from "../screens/ViewMemoryScreen.jsx";
import CybergemsSpectrumScreen from "../screens/CybergemsSpectrumScreen.jsx";
import ComingSoonScreen from "../screens/ComingSoonScreen.jsx";
import UserAgreementComponent from "../component/disclaimer/user-agreement.jsx";
import DisclaimerComponent from "../component/disclaimer/disclaimer.component.jsx";
import AboutScreen from "../screens/AboutScreen.jsx";
import AllSeriesScreen from "../screens/AllSeriesScreen.jsx";
import MainHomeScreen from "../screens/MainHomeScreen.jsx";
import GemComingSoonComponent from "../screens/GemComingSoonScreen.jsx";

export const routes = [

    {
        path: "/home",
        name: "MainHomeScreen",
        element: <MainHomeScreen />,
        isPrivate: false

    },

    {
        path: "/",
        name: "ComingSoonScreen",
        element: <ComingSoonScreen />,
        isPrivate: false
    },


    {
        path: "/series/:series",
        name: "SeriesCoverArtScreen",
        element: <SeriesCoverArtScreen />,
        isPrivate: false
    },

    {
        path: "/profile",
        name: "ProfileScreen",
        element: <ProfileScreen />,
        isPrivate: true
    },

    {
        path: "/:tokenID",
        name: "GemDetailsPageViewScreen",
        element: <GemDetailsPageViewScreen />,
        isPrivate: false
    },

    {
        path: "/view-coa",
        name: "ViewCOAScreen",
        element: <ViewCOAScreen />,
        isPrivate: false
    },

    {
        path: "/view-memory",
        name: "ViewMemoryScreen",
        element: <ViewMemoryScreen />,
        isPrivate: false
    },

    {
        path: "/minting",
        name: "GemComingSoonComponent",
        element: <GemComingSoonComponent />,
        isPrivate: false
    },


    {
        path: "/user-agreement",
        name: "UserAgreementComponent",
        element: <UserAgreementComponent />,
        isPrivate: false
    },

    {
        path: "/disclaimer",
        name: "DisclaimerComponent",
        element: <DisclaimerComponent />,
        isPrivate: false
    },

    {
        path: "/about",
        name: "AboutScreen",
        element: <AboutScreen />,
        isPrivate: false
    },

    {
        path: "*",
        name: "MainHomeScreen",
        element: <MainHomeScreen />
    },



]