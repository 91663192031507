import React, { useState } from "react";
import { Box, DialogContent, DialogActions, Dialog, Divider, Button, Grid, Slider, Avatar, CardActionArea, Typography } from '@mui/material';
import AvatarEditor from 'react-avatar-editor'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { BootstrapDialogTitle } from "../../../utils/bootstrap-dialog-util";
import { dataURItoFile } from "../../../utils/file/convert";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';


function UserImage({width, height, image}){
    return(
        <Avatar  variant="square" style={{ cursor:'pointer', width: width, height: height }}>
            <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ background: 'white', objectFit:'cover' }} src={image} />
        </Avatar>
    )
}


function ImageSlider({onChangeSlider, defaultValue}){

    const [scale, setScale] = useState(defaultValue); // default

    function handleOnChangeSlider(event){
        setScale(event.target.value)
        onChangeSlider(event.target.value);
    }

    return(
        <Grid container spacing={2}>
            <Grid item>
                <RemoveIcon />
            </Grid>
            <Grid item xs>
                <Slider 
                    min={1} 
                    max={2.0} 
                    step={0.01} 
                    defaultValue={scale} 
                    value={scale}
                    onChange={handleOnChangeSlider} 
                    aria-labelledby="continuous-slider" 
                />
            </Grid>
            <Grid item>
                <AddIcon />
            </Grid>
        </Grid>
    )
}

function CroppedImagePreview({image, onClickUndo, details, gemHeight, gemWidth}){
    
    return(
        <Box>
            <Box style={{justifyContent: 'center', display: 'grid'}}>
                <UserImage width={gemWidth} height={gemHeight} image={image} />
            </Box>
            <br/>
            <Box display="flex" justifyContent="center">
                <Box p={1}>
                    <button className="btn-donate"  onClick={onClickUndo} style={{marginLeft: "-133px", fontFamily: 'GucciSans'}}>
                        Undo
                    </button>
                </Box>
            </Box>
        </Box>
    )
}

function ImageEditorSection({file, onCropImage, onChangeImageEditorRef, details, validateThumbnail, fileUploaded, setHasCroppedImage, gemHeight, gemWidth}){

    const [editor_ref, setEditorRef] = useState(null);
    const [scale, setScale] = useState(1.2);

    function handleImageEditorRef(editor){
        onChangeImageEditorRef(editor)
        setEditorRef(editor)
    }

    function cropPhoto(){
        const canvas = editor_ref.getImageScaledToCanvas();
        const image_cropped = canvas.toDataURL('image/', 0.9); // Adjust quality between 0 and 1
        onCropImage(image_cropped);
        setHasCroppedImage(true);
    }

    function handleOnChangeSlider(scale){
        setScale(scale)
    }

    return(
        <>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', zoom: '150%'}}>
                <AvatarEditor
                    ref={handleImageEditorRef}
                    image={file}
                    width={gemWidth}
                    height={gemHeight}
                    border={50}
                    color={[12, 9, 10, 0.6]} // RGBA
                    scale={scale}
                    rotate={0}
                />
            </Box>
            <br/>
            <ImageSlider onChangeSlider={handleOnChangeSlider} defaultValue={scale} />
            <Box display="flex" justifyContent="center" sx={{mb: 2}}>
                <Box p={1}>
                    <Button variant="outlined" onClick={(e)=>cropPhoto()} style={{fontFamily: 'GucciSans', textTransform:'none'}} >
                        Crop Photo
                    </Button>
                </Box>
                {fileUploaded.type.startsWith('video/') &&
                    <Box p={1}>
                        <button className="btn-donate">
                            <input
                                type="file"
                                accept="image/*"
                                id="upload-thumbnail"
                                onChange={validateThumbnail}
                                hidden
                            />
                            <label style={{ cursor: "pointer" ,fontFamily: 'GucciSans'}} htmlFor="upload-thumbnail" >
                                    Change thumbnail
                            </label>
                            
                        </button>
                    </Box>
                }
            </Box>
        </>
    )
}

function ThumbnailCropperDialogComponent({openThumbnailCropper, file, setOpenThumbnailCropper, onSave, details, validateThumbnail, fileUploaded}){

    const [image_cropped, setImageCropped] = useState(null);
    const [editor_ref, setEditorRef] = useState(null);

    const [hasCroppedImage, setHasCroppedImage] = useState(false);

    const gemHeight = details?.cover_art_details?.height * 2;
    const gemWidth = details?.cover_art_details?.width * 2;

    function handleClose(){
        setImageCropped(null)
        setEditorRef(null)
        // onClose();
        setOpenThumbnailCropper(false)
    }

    function save(image_cropped, filename){
        if(!image_cropped){
            image_cropped = editor_ref.getImageScaledToCanvas().toDataURL();
        }
        
        const hasTransparentBackground = checkTransparentBackground(image_cropped);
        
        if (hasTransparentBackground) {
            // Create a canvas element with white background
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = function() {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.fillStyle = 'white'; // Set white background
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0); // Draw the image
                const modifiedImage = canvas.toDataURL();
                const file = dataURItoFile(modifiedImage, filename);
                onSave(file);
                handleClose();
            };
            img.src = image_cropped;
        } else {
            const file = dataURItoFile(image_cropped, filename);
            onSave(file);
            handleClose();
        }
    }
    
    function checkTransparentBackground(imageData) {
        const img = new Image();
        img.src = imageData;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        for (let i = 3; i < pixelData.length; i += 4) {
            if (pixelData[i] < 255) {
                return true; // Transparent pixel found
            }
        }
        return false; // No transparent pixels found
    }

    function handleImageEditorRef(editor){
        setEditorRef(editor)
    }

    return(
        <Dialog 
            onClose={(event)=>handleClose()} 
            aria-labelledby="customized-dialog-title" 
            open={openThumbnailCropper} 
            fullWidth 
            PaperProps={{ style: { borderRadius: 4 } 
        }}>
            <BootstrapDialogTitle id="customized-dialog-title" 
            onClose={(event)=>handleClose()} style={{textAlign: 'center', fontSize: 18, fontFamily: 'GucciSans'}}>
                Crop Picture
            </BootstrapDialogTitle>
            <Divider/>
            <DialogContent divider style={{paddingBottom: 1, textAlign:'center'}}>
                {image_cropped && 
                    <CroppedImagePreview 
                        image={image_cropped} 
                        onClickUndo={()=>setImageCropped(null)}
                        hasCroppedImage={hasCroppedImage} details={details} gemHeight={gemHeight}
                        gemWidth={gemWidth}
                    /> 
                }

                {!image_cropped && 
                    <ImageEditorSection 
                        fileUploaded={fileUploaded} 
                        file={file} 
                        validateThumbnail={validateThumbnail} 
                        onCropImage={(image)=>setImageCropped(image)} 
                        onChangeImageEditorRef={handleImageEditorRef} 
                        details={details} gemHeight={gemHeight}
                        gemWidth={gemWidth} setHasCroppedImage={setHasCroppedImage}
                    /> 
                }
            </DialogContent>
            
           {hasCroppedImage &&
                <DialogActions sx={{justifyContent: "center"}}>
                    {image_cropped && 
                        <button className="btn-donate" onClick={() => save(image_cropped, file?.name)}
                            style={{marginTop: "-70px", marginLeft: "130px", fontFamily: 'GucciSans'}}
                        >
                            Save
                        </button>
                    }
                </DialogActions>
            }
        </Dialog>
    )
}

export default ThumbnailCropperDialogComponent;