import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import {log} from "../console-config.log";


class ApiService {

  static ERROR_CODE_TOKEN_EXPIRED = "TOKEN_EXPIRED";
  static ERROR_CODE_TOKEN_INVALID = "TOKEN_INVALID";

  static source = axios.CancelToken.source();

  static async get(url, params = {}, customHeaders = {}, bypassToken = false) {
    log("bypassToken aaa", bypassToken);

    try {
      if (!bypassToken) {
        const isExpired = await this.isTokenExpired();
        log("isExpired aaa", isExpired);

        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      
      const response = await axios.get(url, {
        params,
        headers: this.getHeaders(false, customHeaders),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
  }

  static async customGet(url, params = {}, customHeaders = {}, bypassToken = false) {
    log("bypassToken aaa", bypassToken);

    try { 
      if (!bypassToken) {
        const isExpired = await this.isProfileTokenExpired();
        log("isExpired aaa", isExpired);

        if (isExpired) this.handleProfileTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Your session has expired.")

      }
      log("ni agiii");
      const response = await axios.get(url, {
        params,
        headers: this.getHeaders(false, customHeaders),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);

        localStorage.removeItem("metamask");
        localStorage.removeItem("auth_token");
       

        throw error;
      }
    }
  }

  static async get1(url, params = {}, customHeaders = {}, bypassToken = false) {
    log("bypassToken aaa", bypassToken);

    try {
      if (!bypassToken) {
        const isExpired = await this.isTokenExpired();
        log("isExpired aaa", isExpired);

        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      log("ni agiii");
      const response = await axios.get(url, {
        params,
        headers: this.getHeaders(false, customHeaders),
        cancelToken: this.source.token,
        responseType: 'blob', 
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
  }

  static async post(url, body, isFormData = false, customHeaders = {}, bypassToken = true) {
    try {
      if (!bypassToken) {
        const isExpired = this.isTokenExpired();
        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      const response = await axios.post(url, body, {
        headers: this.getHeaders(isFormData, customHeaders),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error posting data:', error);
        // alert(error.response.data.error)
        throw error;
      }
    }
  }

  static async post1(url, body, isFormData = false, customHeaders = {}, bypassToken = true) {
    try {
      if (!bypassToken) {
        const isExpired = this.isTokenExpired();
        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      const response = await axios.post(url, body, {
        headers: this.getHeaders(isFormData, customHeaders),
        cancelToken: this.source.token,
        responseType: 'blob', 
      });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error posting data:', error);
        // alert(error.response.error)
        throw error;
      }
    }
  }

  static async put(url, body, isFormData = false, customHeaders = {}, bypassToken = false) {
    try {
      if (!bypassToken) {
        const isExpired = this.isTokenExpired();
        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      const response = await axios.put(url, body, {
        headers: this.getHeaders(isFormData, customHeaders),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error updating data:', error);
        throw error;
      }
    }
  }

  static async delete(url, params = {}, customHeaders = {}, bypassToken = false) {
    try {
      if (!bypassToken) {
        const isExpired = this.isTokenExpired();
        if (isExpired) this.handleTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Token is expired.")
      }
      const response = await axios.delete(url, {
        params,
        headers: this.getHeaders(false, customHeaders),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error deleting data:', error);
        throw error;
      }
    }
  }

  static cancelRequest(message = 'Request canceled') {
    this.source.cancel(message);
    this.source = axios.CancelToken.source(); // Create a new source for future requests
  }

  static getHeaders(isFormData, customHeaders) {

    // Let the browser set the headers for FormData if true
    let headers = isFormData ? {} : { 'Content-Type': 'application/json' };
    
    // Get Bearer Token
    const token = localStorage.getItem("auth_token"); // temporary. feel free to edit data source.
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Append custom headers
    headers = { ...headers, ...customHeaders };

    return headers;

  }

  static async getServerTime() {
    const BASE_URL = process.env.REACT_APP_CG_NFT_HOST;
    const CG_NFT_SERVICE_SUBSCRIPTION_KEY = process.env.REACT_APP_CG_NFT_SERVICE_SUBSCRIPTION_KEY;

    const cgNFTServerTimeEndpoint = BASE_URL + "/api/v1/cybergems/server-time"
    var headers = {
      "Ocp-Apim-Subscription-Key": CG_NFT_SERVICE_SUBSCRIPTION_KEY,
    };

    try {
      const response = await axios.get(cgNFTServerTimeEndpoint, {
        params: {},
        headers: this.getHeaders(false, headers),
        cancelToken: this.source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);
        throw error;
      }
    }
  }

  static async isProfileTokenExpired() {
    const res = await this.getServerTime()
    const serverTime = res.data.server_time
    const auth_token = localStorage.getItem("auth_token")
    log("auth_token aa", auth_token);

    if (auth_token == null || auth_token == undefined) {
      this.handleProfileTokenError(this.ERROR_CODE_TOKEN_EXPIRED, "Your session has expired.")
    }

    const isTokenValid = this.isTokenValidFormat(auth_token);
    log("isTokenValid aa", isTokenValid);

    if (!isTokenValid) {
      this.handleTokenError(this.ERROR_CODE_TOKEN_INVALID, "Token is invalid.")
    }

    const decodedToken = jwtDecode(auth_token);
    log("decodedToken aa", decodedToken);

    const timestamp = decodedToken.exp;
    log("timestamp aa", timestamp);

    // Create a Date object using the timestamp
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const formattedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
    log("formattedDate aa", formattedDate);

    const isExpired = formattedDate < serverTime;
    return isExpired
  }

  static async isTokenExpired() {
    const res = await this.getServerTime()
    const serverTime = res.data.server_time
    const auth_token = localStorage.getItem("auth_token")
    log("auth_token aa", auth_token);

    if (auth_token == null || auth_token == undefined) {
      this.handleTokenError(this.ERROR_CODE_TOKEN_INVALID, "Token not found.")
    }

    const isTokenValid = this.isTokenValidFormat(auth_token);
    log("isTokenValid aa", isTokenValid);

    if (!isTokenValid) {
      this.handleTokenError(this.ERROR_CODE_TOKEN_INVALID, "Token is invalid.")
    }

    const decodedToken = jwtDecode(auth_token);
    log("decodedToken aa", decodedToken);

    const timestamp = decodedToken.exp;
    log("timestamp aa", timestamp);

    // Create a Date object using the timestamp
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const formattedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
    log("formattedDate aa", formattedDate);

    const isExpired = formattedDate < serverTime;
    return isExpired
  }

  static handleTokenError(code, message){
    localStorage.clear()
    const error = new Error(message);
    error.code = code;
    throw error;
  }

  static handleProfileTokenError(code, message){
    localStorage.removeItem("metamask");
    localStorage.removeItem("auth_token");
   
    const error = new Error(message);
    error.code = code;


    throw error;
  }

  static isTokenValidFormat = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      log("decodedToken bbbb", decodedToken);
  
      // Check if the decoded token has the expected structure
      return (
        decodedToken &&
        typeof decodedToken === 'object' &&
        typeof decodedToken.sub == 'string' &&
        this.isUnixTimestamp(decodedToken.exp) &&
        this.isUnixTimestamp(decodedToken.iat)
      );
    } catch (error) {
      // Token decoding failed
      log("error aa", );
      return false;
    }
  };

  static isUnixTimestamp(value) {
    // Check if the value is a number
    if (typeof value === 'number') {
      // Check if the value is a positive integer
      return Number.isInteger(value) && value > 0;
    }
  
    return false;
  }

}

export default ApiService;