import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import SeriesCoverArtComponent from "../component/series-cover-art/series-covert-art.component";
import ViewGemDetailsScreen from "./ViewGemDetailsScreen";
import { Box, Grid, IconButton, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import useWidth from "../hooks/useWidth";
import SeriesButtonGroupComponent from "../component/homepage/series-button-group.component";
import { imageSources } from '../common/imageSources';
import MobileSeriesButtonGroupComponent from "../component/homepage/mobile-button-group.component";


const SeriesCoverArtScreen = () => {

    const breakpoints = useWidth();
    const { series } = useParams();
    const metamask = JSON.parse(localStorage.getItem("metamask"))
    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"))

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [gemTokenId, setGemTokenId] = useState(null);
    const [isGemClicked, setGemClicked] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isMemoryAttach, setMemoryAttach] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    // Function to detect zoom level
    function detectZoom() {
        var ratio = 0,
            screen = window.screen,
            ua = navigator.userAgent.toLowerCase();

        if (window.devicePixelRatio !== undefined) {
            ratio = window.devicePixelRatio;
        }
        else if (~ua.indexOf('msie')) {
            if (screen.deviceXDPI && screen.logicalXDPI) {
                ratio = screen.deviceXDPI / screen.logicalXDPI;
            }
        }
        else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
            ratio = window.outerWidth / window.innerWidth;
        }

        if (ratio) {
            return Math.round(ratio * 100);
        }

        return 100; // Default to 100% zoom if unable to detect
    }

    // Function to apply styles based on zoom level
    function applyStylesForZoomLevel() {
        var zoomLevel = detectZoom();

        if (zoomLevel === 125) {
            // Apply styles for 125% zoom
            document.body.classList.add('series-zoom-125');

        } else if (zoomLevel === 150) {
            document.body.classList.add('series-zoom-150');

        } else {
            // Remove styles if zoom level is not 125%
            document.body.classList.remove('series-zoom-125');
            document.body.classList.remove('series-zoom-150');
        }
    }

    // Apply styles initially and listen for resize events to reapply if needed
    applyStylesForZoomLevel();
    window.addEventListener('resize', applyStylesForZoomLevel);

    const series_name = series


    return (
        <>

            {!isImageLoaded && isSmScreen && (
                <Skeleton
                    animation="wave"
                    variant="rect"
                    height={42}
                    sx={{ mt: 10, mb: 4}}
                />
            )}

            {isImageLoaded && isSmScreen &&
                <Typography
                    sx={{
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 28,
                        fontFamily: 'GucciSans',
                        mt: 10,
                        mb: 4
                    }}
                    className="series-name"
                >
                    {series_name}
                </Typography>}
            <Box
                sx={{
                    width: isSmScreen ? '100%' : '90%',
                    margin: 'auto',
                }}
            >
                {/* Series Cover Art Component */}
                <SeriesCoverArtComponent
                    series={series}
                    wallet_address={metamask?.wallet_address}
                    setGemClicked={setGemClicked}
                    setGemTokenId={setGemTokenId}
                    customStyles={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                    handleImageLoad={handleImageLoad}
                    isImageLoaded={isImageLoaded}
                    isMemoryAttach={isMemoryAttach}
                    metamask={metamask}
                />

            </Box>


            {!isImageLoaded && isSmScreen && (
                <Skeleton
                    animation="wave"
                    variant="rect"
                    height={82}
                    sx={{ mt: 5}}
                />
            )}
            {isImageLoaded && isSmScreen &&
                <Box
                    sx={{
                        mt: 5,
                    }}
                >
                    <MobileSeriesButtonGroupComponent imageSources={imageSources} />
                </Box>
            }

            {isImageLoaded && !isSmScreen &&
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 24,
                        fontFamily: 'GucciSans',
                        mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : 1.5,
                    }}
                    className="series-name"
                >
                    {series_name}
                </Typography>
            }

            {isGemClicked &&
                <ViewGemDetailsScreen gemTokenId={gemTokenId} setGemClicked={setGemClicked} setMemoryAttach={setMemoryAttach} />
            }
        </>

    )
}

export default SeriesCoverArtScreen;