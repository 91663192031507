import {useState} from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from "web3";
import ABICybergemContract from "../smart-contract/ABI-Cybergem-Contract";
import useSessionStorage from "./useSessionStorage";
import {log} from "../console-config.log";

const useCybergemABIContract = () => {

    const [ownedTokens, setOwnedTokens] = useState([]);

    const {retrieveDataFromSessionStorage, storeDataInSessionStorage} = useSessionStorage();

    const getABIContract = async () => {
        var promise = new Promise( async (resolve, reject) => {
            try {
                const provider = await detectEthereumProvider();
                if (provider) {
                    const web3Instance = new Web3(provider);
                    const cybergemContractInstance = new web3Instance.eth.Contract(ABICybergemContract.ABI.contractABI, ABICybergemContract.Address.contractAddress);
                    resolve(cybergemContractInstance)
                }
            } catch (error) {
                log.error("Error detecting Ethereum provider:", error);
                reject(error)
            }
        })
        
        return promise
    }

    const balanceOf = (address) => {
        return new Promise(async (resolve, reject) => {
            const ABIContractInstance = await getABIContract()
            ABIContractInstance.methods.balanceOf(address).call().then((balance)=>{
                resolve(Number(balance))
            })
            .catch((e) => reject(e))
        })
    }

    const ownerOf = (tokenId) => {
        return new Promise(async (resolve, reject) => {
            const ABIContractInstance = await getABIContract()
            ABIContractInstance.methods.ownerOf(tokenId).call().then((address)=>{
                resolve(address)
            })
            .catch((e) => reject(e))
        })
    }

    const getOwnerTokenIds = async (address) => {

        const storedOwnerTokensData = await retrieveDataFromSessionStorage();

        if (storedOwnerTokensData) {
            setOwnedTokens(storedOwnerTokensData);

        } else {

            const ABIContractInstance = await getABIContract();

            // Get the tokens that the account received
            const eventsReceivedTokens = await ABIContractInstance.getPastEvents("Transfer", {
                filter: {
                to: address,
                },
                fromBlock: 5509405,
            });

            // Count the number of times the account received the token
            let receivedTokensCount = {};

            for (let key in eventsReceivedTokens) {
                let tokenId = eventsReceivedTokens[key]["returnValues"]["tokenId"];
                receivedTokensCount[tokenId] = (receivedTokensCount[tokenId] || 0) + 1;
            }

            let receivedTokenIds = Object.keys(receivedTokensCount);

            // Get the tokens that the account sent
            const eventsSentTokens = await ABIContractInstance.getPastEvents("Transfer", {
                filter: {
                from: address,
                tokenId: receivedTokenIds,
                },
                fromBlock: 5509405,
            });

            let sentTokensCount = {};

            for (let key in eventsSentTokens) {
                let tokenId = eventsSentTokens[key]["returnValues"]["tokenId"];
                sentTokensCount[tokenId] = (sentTokensCount[tokenId] || 0) + 1;
            }

            // Substract the tokens received by the sent to get the tokens owned by account
            // Store them on ownedTokenIds
            let ownedTokenIds = [];

            for (let tokenId in receivedTokensCount) {
                if (
                (sentTokensCount[tokenId] ? sentTokensCount[tokenId] : 0) <
                receivedTokensCount[tokenId]
                ) {
                ownedTokenIds.push(tokenId);
                }
            }
            
            log(`Token IDs owned by ${address}: ${ownedTokenIds}`);
            setOwnedTokens(ownedTokenIds);

            storeDataInSessionStorage(ownedTokenIds);
        }
    }

    
    return {ownedTokens, balanceOf, ownerOf, getOwnerTokenIds}
}

export default useCybergemABIContract;
