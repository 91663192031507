import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from "react-router-dom";

export default function ErrorViewCOADialog (props) {
    const {openErrorDialog, setOpenErrorDialog, errorCybergemDownloadCOA} = props;

    const navigate = useNavigate();

    return(
        <Dialog open={openErrorDialog}  maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {borderRadius: 5, margin: "auto"}}}>
            <DialogContent className="btn-donate" sx={{p: 2, textAlign:'center',borderRadius: 0,}}>
                <ErrorIcon sx={{color:'white', height: 50, width: 50}}/>
            </DialogContent>
            <DialogContent sx={{textAlign:'center', mt: 2, mb: 2}}>
                <Typography sx={{color:'gray', fontSize: 13, fontFamily: 'GucciSans'}}>Error: The Certificate of Authenticity not found.</Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center', mb: 2}}>
                <button className="btn-donate" onClick={()=> navigate("/")} style={{fontFamily: 'GucciSans'}} >Go to homepage</button>
                {/* <Button variant="outlined" color="error">Go to homepage</Button> */}
            </DialogActions>
        </Dialog>
    )
}