import { Document, pdfjs, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useCybergemNft from '../hooks/useCybergemNft';
import { useEffect, useState } from 'react';
import { Divider, Button, Popper, Paper, ClickAwayListener, MenuList, IconButton, Box, Grid, ListItemIcon, MenuItem, Typography, TextField } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from 'react-router';
import ViewCoaSkeletonLoader from '../skeleton-loader/view-coa-skeleton-loader';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ViewMemoryIcon from "../icons/album.png";
import ViewMemoryReverseIcon from "../icons/album-white.png";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ErrorViewCOADialog from '../component/dialog/error-message/error-view-coa-dialog.component';
import { useSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {log} from "../console-config.log";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ActionPopper = ({ handleDownload, cybergemDetailViewData, tokenID }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const isDarkMode = localStorage.getItem("darkMode");

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const [open_downloadDialog, setOpen_downloadDialog] = useState(false);
  
    const handleClickOpen = () => {
        setOpen_downloadDialog(true);
    };
  
    const handleCloseDownloadDialog = (value) => {
        setOpen_downloadDialog(false);
    };

    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    const isMetamaskMobileAppBrowser = isMobileDevice && typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;

    return (
        <Box>
            <Button onClick={handleClick}>
                <MoreHorizIcon aria-describedby={id} sx={{cursor: "pointer",
                    color: "#fff",
                    // color: ( isDarkMode == null || isDarkMode === "false") ? "#000" : "#fff",
                }} />
            </Button>

            <Popper style={{zIndex: 3}} id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{ borderRadius: 2 }}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList sx={{ width: 170 }}>
                            {/* <MenuItem onClick={handleDownload} style={{ fontSize: 13, fonWeight: 500 }}> */}
                            <MenuItem onClick={()=>isMetamaskMobileAppBrowser ? handleClickOpen() : handleDownload()} style={{ fontSize: 13, fonWeight: 500 }}>
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <DownloadForOfflineIcon sx={{
                                        color: '#fff' 
                                        // color: (isDarkMode === "false") ? '#45A1FF' : '#fff' 
                                    }} />
                                </ListItemIcon>

                                <Typography variant="inherit" sx={{ fontFamily: 'GucciSans' }}>
                                    Download
                                </Typography>
                            </MenuItem>

                            <MenuItem onClick={() => navigate("/view-memory?tokenId=" + tokenID)} style={{ fontSize: 13, fonWeight: 500 }}>
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" style={{ height: 24 }} 
                                        src={ViewMemoryReverseIcon} 
                                        // src={(isDarkMode === "false") ? ViewMemoryIcon : ViewMemoryReverseIcon} 
                                    />
                                </ListItemIcon>

                                <Typography variant="inherit" sx={{ fontFamily: 'GucciSans' }}>
                                    View Memory
                                </Typography>
                            </MenuItem>

                            <MenuItem style={{ fontSize: 13, fonWeight: 500 }} onClick={(e) => navigate(`/${tokenID}`)}>
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <ExpandCircleDownOutlinedIcon sx={{marginRight: "8px", rotate: '270deg',
                                        color: "#fff",
                                        // color: (isDarkMode === "false") ? "#097ff4f2" : "#fff",
                                    }} />
                                </ListItemIcon>

                                <Typography variant="inherit" sx={{ fontFamily: 'GucciSans' }}>
                                    See More
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            <DownloadCOADialog
                open={open_downloadDialog}
                onClose={handleCloseDownloadDialog}
            />

        </Box>
    )
}

function DownloadCOADialog(props) {

    const { onClose, selectedValue, open } = props;

    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        onClose(selectedValue);
    };

    const { href } = window.location;

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          enqueueSnackbar("Copied successfully!", { 
            variant: 'success',
            autoHideDuration: 2000
        });
        } catch (err) {
          log.error('Failed to copy: ', err);
        }
    };

  return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Download</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}
        >
            <CloseIcon />
        </IconButton>
        <Divider/>
        <Box
            component="form"
            sx={{pl: 3, pr: 3, pb: 3}}
            noValidate
            autoComplete="off"
        >
            <p>Open the link below on a browser outside Metamask mobile app.</p>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <TextField sx={{width: '100%'}} id="outlined-basic" value={href} variant="outlined" />
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={()=>copyToClipboard(href)} sx={{width: '100%', height: '55px'}}>COPY</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                
            </Box>
        </Box>
    </Dialog>
  );
}

export default function ViewCOAScreen() {

    const tokenID = new URLSearchParams(window.location.search).get("tokenId");

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [openAgreement, setOpenAgreement] = useState(false);

    const { cybergemDownloadCOA, downloadCOA, isLoadingCybergemDownloadCOA, cybergemDetailViewData, getViewCybergem, errorCybergemDownloadCOA } = useCybergemNft();
    const navigate = useNavigate();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    function handleOnLoadError(error) {
        setError(error.message); // react-pdf validates by default
    }

    const handleOpenAgreement = () => {
        setOpenAgreement(true)
    }

    const handleClose = () => {
        setOpenAgreement(false)
    }

    useEffect(() => {
        if (tokenID) {
            getViewCybergem(tokenID);
        }
    }, [])

    useEffect(() => {
        downloadCOA(tokenID)
    }, [tokenID])

    const pdfContent = window.URL.createObjectURL(new Blob([cybergemDownloadCOA?.data], { type: "application/pdf" }));

    const handleDownload = (e) => {
        setAnchorEl(null);
        const a = document.createElement('a');
        a.href = pdfContent;
        const gemName = cybergemDetailViewData?.catalog?.name;
        a.download = `${gemName}-COA-Token${tokenID}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        if (errorCybergemDownloadCOA) {
            setOpenErrorDialog(true)
        }
    }, [errorCybergemDownloadCOA])

    return (
        <Box sx={{margin: "auto", mb: 3}}>
            <Grid item xs={12} sm={12} sx={{textAlign:'start', display:'flex', alignItems:'center'}}>

                <IconButton sx={{ml: "10px", fontSize: 14}} onClick={(e) => navigate(0)}>
                    <KeyboardBackspaceIcon /> 
                </IconButton>

                <Box sx={{flex: 1}}/>
            
                <ActionPopper
                    handleDownload={handleDownload} 
                    cybergemDetailViewData={cybergemDetailViewData} 
                    tokenID={tokenID} 
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />

            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center">
                {isLoadingCybergemDownloadCOA ?
                    <ViewCoaSkeletonLoader />
                    :
                    <div className="pdf-container">
                        <Document
                            file={pdfContent}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={handleOnLoadError}
                        // loading="Please wait"
                        // style={{
                        //     boxShadow: "rgb(0 0 0 / 22%) 0px 4px 20px", // Adjust values as needed
                        // }}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </div>
                }
            </Box>
            {/* <Button onClick={handleOpenAgreement} sx={{mt: 2, textTransform:'none'}}>Certificate of Agreement</Button>

            <CerttificateOfAgreementDialog openAgreement={openAgreement} handleClose={handleClose}/> */}
            <ErrorViewCOADialog openErrorDialog={openErrorDialog} setOpenErrorDialog={setOpenErrorDialog} errorCybergemDownloadCOA={errorCybergemDownloadCOA} />
           
        </Box>
    )
}