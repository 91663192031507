import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Container, CssBaseline, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme, Switch, Link, Dialog, DialogContent, Backdrop, Stack, FormGroup, FormControlLabel } from '@mui/material';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import AppbarComponent from '../layout/appbar.component';
import FooterComponent from '../layout/footer.component';



const imageSources = [
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/myst.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
    "https://cybergems.blob.core.windows.net/cgnftcatalog/genesis.webp",
];

// const tooltipTitles = [
//     "Alpha",
//     "Myst",
//     "Catalyst",
//     "Yeda",
//     "Beacon",
//     "Eclipse",
//     "Reverie",
//     "Eternity",
//     "Serenity",
//     "Memoir"
// ];

const tooltipTitles = [
    "Alpha",
    "Myst",
    "Phantom",
    "Lumina",
    "Nova",
    "Catalyst",
    "Quantum",
    "Serenity",
    "Infinity",
    "Zenith"
];


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[0.5],
        fontSize: 18,
        fontWeight: 'bold'
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&::before': {
            color: '#fff'
        }
    }
}));



const HomeScreen = () => {

    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [clickedImage, setClickedImage] = useState(null);
    const [clickedImageSet2, setClickedImageSet2] = useState(null);
    const [clickedImageSet3, setClickedImageSet3] = useState(null);
    const [clickedImageSet4, setClickedImageSet4] = useState(null);


    return (

        <Container component="main" maxWidth="auto">
            <CssBaseline />

            <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '82vh', position: 'relative', overflow: 'hidden' }} >
                <Typography sx={{ marginBottom: 2, fontWeight: 500, fontSize: isSmScreen ? 18 : 27, marginBottom: 3, letterSpacing: 2, lineHeight: 1.8 }}>
                    8,888 Unique NFT Gems, Forever <br /> Capturing Life’s Precious Memories
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                    {imageSources.slice(0, 5).map((src, index) => (
                        <div key={index} onClick={() => setClickedImage(index)}>
                            <LightTooltip title={tooltipTitles[index].toUpperCase()} arrow key={index} placement="bottom">
                                <img
                                    src={src}
                                    alt="CyberGems - A collection of 8,888 NFT gems"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', marginBottom: 0, marginRight: 0, transition: 'transform 0.3s ease', cursor: 'pointer' }}
                                />
                            </LightTooltip>
                            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={clickedImage === index} onClick={(e) => { e.stopPropagation(); setClickedImage(null); }}>
                                <Box>
                                    <LightTooltip title={tooltipTitles[index].toUpperCase()} arrow key={index} placement="top">
                                        <img
                                            src={src}
                                            alt="CyberGems - A collection of 8,888 NFT gems"
                                            style={{ width: '90%', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                            onClick={() => window.location.href = `/series/${tooltipTitles[index].toLowerCase()}`}
                                        />
                                    </LightTooltip>
                                </Box>
                            </Backdrop>
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                    {imageSources.slice(5, 10).map((src, index) => (
                        <div key={index} onClick={() => setClickedImageSet2(index)}>
                            <LightTooltip title={tooltipTitles[index + 5].toUpperCase()} arrow key={index + 5} placement="top">
                                <img
                                    src={src}
                                    alt={`Image ${index + 1}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', marginBottom: 0, marginRight: 0, transition: 'transform 0.3s ease', cursor: 'pointer' }}
                                />
                            </LightTooltip>
                            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={clickedImageSet2 === index} onClick={(e) => { e.stopPropagation(); setClickedImageSet2(null); }}>
                                <Box>
                                    <LightTooltip title={tooltipTitles[index + 5].toUpperCase()} arrow key={index + 5} placement="top">
                                        <img
                                            src={src}
                                            alt={`Image ${index + 1}`}
                                            style={{ width: '90%', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                            onClick={() => window.location.href = `/series/${tooltipTitles[index + 5].toLowerCase()}`}
                                        />
                                    </LightTooltip>
                                </Box>
                            </Backdrop>
                        </div>
                    ))}
                </div>

                <Box sx={{ marginTop: 4 }} >
                    <ButtonGroup variant="text" disableFocusRipple disableRipple sx={{ '& .MuiButtonGroup-grouped:not(:last-child)': { borderColor: 'rgb(0 0 0 / 47%)', borderBottomWidth: '10%' }, }} >
                        {imageSources.slice(0, 6).map((src, index) => (
                            <div key={index} onClick={() => setClickedImageSet3(index)}>
                                <Button sx={{ textTransform: 'none', color: '#000', padding: '1px 8px', fontSize: 20, lineHeight: 1 }}>{tooltipTitles[index]}</Button>
                                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={clickedImageSet3 === index} onClick={(e) => { e.stopPropagation(); setClickedImageSet3(null); }}>
                                    <Box>
                                        <LightTooltip title={tooltipTitles[index].toUpperCase()} arrow key={index} placement="top">
                                            <img
                                                src={src}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '90%', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                onClick={() => window.location.href = `/series/${tooltipTitles[index].toLowerCase()}`}
                                            />
                                        </LightTooltip>
                                    </Box>
                                </Backdrop>
                            </div>
                        ))}
                    </ButtonGroup>
                    <br />
                    <ButtonGroup variant="text" disableFocusRipple disableRipple sx={{ mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': { borderColor: 'rgb(0 0 0 / 47%)', borderBottomWidth: '10%' }, }} >
                        {imageSources.slice(6, 10).map((src, index) => (
                            <div key={index} onClick={() => setClickedImageSet4(index)}>
                                <Button sx={{ textTransform: 'none', color: '#000', padding: '1px 8px', fontSize: 20, lineHeight: 1 }}>{tooltipTitles[index + 6]}</Button>
                                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={clickedImageSet4 === index} onClick={(e) => { e.stopPropagation(); setClickedImageSet4(null); }}>
                                    <Box>
                                        <LightTooltip title={tooltipTitles[index + 6].toUpperCase()} arrow key={index} placement="top">
                                            <img
                                                src={src}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '90%', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                onClick={() => window.location.href = `/series/${tooltipTitles[index + 6].toLowerCase()}`}
                                            />
                                        </LightTooltip>
                                    </Box>
                                </Backdrop>
                            </div>
                        ))}
                    </ButtonGroup>
                </Box>

            </Box>
        </Container >

    );
};

export default HomeScreen;
