import React from "react";
import useWidth from "../../hooks/useWidth";
import {Box, Typography} from "@mui/material";

const UserAgreementComponent = () => {

    const breakpoints = useWidth();

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    return (
        <Box sx={{padding : '4rem', pt: 0, pb: 0}}>
        {/* <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "85%" : "90%", margin: "auto"}}> */}
            <Typography sx={{textAlign: "center", fontSize: (breakpoints == "xs" || breakpoints == "sm") ?  22 : 30, fontFamily: "GucciSans", 
                color: "#fff",
                // color: (isDarkmode) ? "#fff" : "#000"
            }}>
                User Acknowledgment and Agreement 
            </Typography>

            <Box sx={{mt: 4.2, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Please Read Carefully Before Using the CyberGems Platform 
                </Typography>

                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    mt: 3, textAlign: 'justify'
                }}>
                    By accessing or using the CyberGems platform, you acknowledge that you have read, understood, and
                    agree to be bound by the CyberGems Platform Disclaimer and the Terms of Service. 
                    If you do not agree with any part of these terms, you must not use the CyberGems platform. 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    fontWeight: "bold"
                }}>
                    I Agree to the Following: 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Responsibility for Content: </span>
                    I understand that I am solely responsible for any content I attach to my digital gems, 
                    including any memory, image, text, or link. I agree that I will not upload content that 
                    violates the platform's content policies or any applicable laws. 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Prohibited Content: </span>
                    I acknowledge the types of content that are prohibited on the CyberGems platform 
                    and agree not to engage in or disseminate such content.
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Reporting and Enforcement: </span>
                    I understand that if I encounter any content that violates the CyberGems policies or terms, 
                    I will report it to the platform administrators for review. I also acknowledge that CyberGems 
                    reserves the right to take necessary actions against violations, which may include removing 
                    content, suspending my account, or other appropriate measures. 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000",
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Liability: </span>
                    I agree to release, indemnify, and hold harmless CyberGems and its affiliates, officers, 
                    agents, and employees from any claim, liability, loss, expense, or demand, including reasonable
                    attorneys' fees, related to my violation of these terms or my use of the services.  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Acceptance of Risk: </span>
                    I understand that by using the CyberGems platform, there is a risk that I may be exposed
                    to content that I find offensive, indecent, or objectionable. I take sole responsibility 
                    for my exposure to such content.   
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000",
                    textAlign: 'justify'
                }}>
                    <span style={{fontStyle: "italic"}}>Changes to Terms: </span>
                    I acknowledge that CyberGems reserves the right to change these terms
                    at any time. My continued use of the platform after any such changes 
                    constitutes my acceptance of the new terms.   
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    fontWeight: "bold"
                }}>
                    Consent: 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", mb: 7}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    By clicking “I Agree”, I represent that I have read and understood this 
                    entire acknowledgment, agree to its terms, and confirm that I am of legal age to
                    form a binding contract with CyberGems. If I am accepting these terms on behalf
                    of a legal entity, I represent that I have the authority to bind that entity to these terms.  
                </Typography>
            </Box>
        </Box>
    )
}

export default UserAgreementComponent