import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import MintedGemImage from "../../images/final_gem_logo.png";

const AboutCybergemsComponent = () => {

    const breakpoints = useWidth();
    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                width: breakpoints === "xs"? "85%" : breakpoints === "sm"  ? '99%' : "100%",
                margin: "auto",
            }}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}> 
                    <img
                        src={MintedGemImage}
                        alt="CyberGems - A collection of 8,888 NFT gems"
                        style={{
                            maxWidth: (breakpoints === "xs" || breakpoints === "sm") ? "85%" : ( breakpoints === "md") ? "30%" : "58%", 
                            height:  (breakpoints === "xs" || breakpoints === "sm") ?  {} : "100%", display: "block", objectFit: "contain", margin: breakpoints === "md" ? '-5%' : "auto",
                            position : ( breakpoints === "md")  ? "fixed" : "static", marginBottom : (breakpoints === "xs" || breakpoints === "sm") ?  "5%" : {},
                            marginTop : (breakpoints === "xs" || breakpoints === "sm") ?  "-10%" : {}
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} 
                    sx={{
                        maxWidth: (breakpoints == "xs" || breakpoints == "sm") ? "100% !important" : "53.5% !important", 
                        textAlign: 'justify', textJustify: 'inter-word', 
                        mt: ((breakpoints == "xs" || breakpoints == "sm") ? 2 : {}),
                        mb: 2,
                    }}
                >
                    <Typography 
                        sx={{textAlign: "start", fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ?  18 : 26, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2", mb: 2,
                            
                        }}
                    >
                        About CyberGems  
                    </Typography>

                    <Box sx={{mt: (breakpoints == "lg") ? 1 : (breakpoints == "xl") ? 1 : 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans",
                            color: "#fff", 
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            CYBERGEMS is a collection of 8,888 NFT gems designed as time capsules, 
                            forever capturing life's precious memories.  
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            Vision
                        </Typography>
                    </Box>

                    <Box sx={{mt: 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            CyberGems redefines digital collectibles by infusing exclusivity and profound 
                            personal meaning into each piece. With the creation of only 8,888 gems, CyberGems 
                            offers an exclusive opportunity to eternalize personal memories on the blockchain, 
                            creating an immutable digital legacy.
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            The Essence of CyberGems
                        </Typography>
                    </Box>

                    <Box sx={{mt: 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            Each CyberGem is not just a token; it's a vessel for a treasured memory. 
                            Beyond the transaction lies the transformation—elevating personal moments 
                            into timeless artifacts on the blockchain. Owning a CyberGem means preserving 
                            your unique story as part of eternity forever.
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff", 
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            The CyberGems Experience
                        </Typography>
                    </Box>

                    <Box sx={{mt: 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            Minting a finite series of 8,888 CyberGems, we offer a unique digital collectible 
                            experience that transcends art and technology, immortalizing personal memories 
                            as part of an everlasting blockchain legacy.
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            CyberGems Creator
                        </Typography>
                    </Box>

                    <Box sx={{mt: 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            CyberGems were made by a group of artists, creative thinkers, and tech experts from a company in Zurich, 
                            Switzerland since 2021.
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            Be a GemMaster
                        </Typography>
                    </Box>

                    <Box sx={{mt: 1, textAlign: "justify"}}>
                        <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>             
                            Owning a Cybergem makes you a GemMaster, synthesizing gems in the future. 
                            GemMasters infuse each gem with personal stories, shaping the Cybergems universe and 
                            their digital legacy, one gem at a time. Treasure Forever.
                        </Typography>
                    </Box>

                    <Box sx={{mt: (breakpoints == "xl") ? 3 : (breakpoints == "lg") ? 2 : 2, textAlign: "start"}}>
                        <Typography sx={{fontWeight: "bold", fontSize: 16, fontFamily: "GucciSans", 
                            color: "#fff",
                            textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        }}>
                            CyberGems is a community of people dedicated to capturing life's precious memories for eternity, treasuring them forever.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AboutCybergemsComponent

