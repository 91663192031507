import React, { useEffect } from "react";
import { Menu, MenuItem, Stack, Typography, Switch } from '@mui/material';
import { useNavigate } from "react-router-dom";

import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import useMetamaskProvider from "../hooks/useMetamaskProvider-v2";
import useAuth from "../hooks/useAuth";
import { styled } from '@mui/material/styles';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DisclaimerIcon from "../icons/disclaimer.png";
import DisclaimerIconWhite from "../icons/disclaimer-white.png";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import useSessionStorage from "../hooks/useSessionStorage";
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const AccountPopper = (props) => {

    const { anchorEl, handleCloseMenu, toggleDarkMode, darkMode, ownedTokens, breakpoints} = props;
    const {wallet, signature} = useMetamaskProvider();
    const { signIn, jwt } = useAuth();

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"))

    const navigate = useNavigate();
    const {deleteDataFromSessionStorage} = useSessionStorage();


    useEffect(() => {
        const metamask = {
            wallet_address: wallet,
            signature,
        }
        if (wallet && signature) {
            localStorage.setItem("metamask", JSON.stringify(metamask))

            // call Sign In API 
            signIn(wallet, signature)
        }
    }, [wallet, signature])


    const metamask = JSON.parse(localStorage.getItem("metamask"))
    const isUserLoggedIn = localStorage.getItem("auth_token");

    const handleLogout = (e) => {
        localStorage.removeItem("metamask")
        localStorage.removeItem("auth_token")
        localStorage.removeItem("isFocused")
        localStorage.removeItem('isNetworkChanged')
        localStorage.removeItem('networkName')

        deleteDataFromSessionStorage();

        window.location.href = '/home';
    }


    function handleContactUsClick() {
        window.location.href = 'mailto:inquiry@cybergems.com';
        handleCloseMenu();
    }

    return (
        <Menu sx={{ top: "60px", 
        zIndex: 99999
        // '.MuiMenu-list' : {  
        //     // backgroundColor: '#0e0e0e',backdropFilter: 'blur(20px)', opacity: 0.7,
        //  }
    }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
  
        >   
            {isUserLoggedIn &&
                <MenuItem disableRipple disableHover sx={{paddingRight: "25px", fontFamily: 'GucciSans', cursor: "default",
                    ":hover": {backgroundColor: "inherit", backdropFilter: "blur(20px)"}}} 
                >
                    <WalletOutlinedIcon sx={{
                        color: "#fff",
                        // color: isDarkmode ? '#fff' : "#0e0e0e"
                    }} />

                    <Typography sx={{ml: "10px", fontSize: 15, 
                        color: "#fff",
                        // color:  isDarkmode ? '#fff' : "#0e0e0e", 
                        fontFamily: 'GucciSans'
                    }}>
                        {ownedTokens?.length} CG
                    </Typography>
                </MenuItem>
            }

            {isUserLoggedIn && 
                <MenuItem sx={{ paddingRight: "25px", fontFamily: 'GucciSans',
                    ":hover": {backdropFilter: "blur(20px)"}
                    // ":hover": {backgroundColor: (!darkMode) ? {} : "#2f2c91", backdropFilter: "blur(20px)"}
                // ':hover': { backgroundColor: '#2f2c91', backdropFilter: 'blur(20px)' } 
                }} onClick={(e) => [navigate("/profile"), handleCloseMenu()] }  >
                    <AccountCircleOutlinedIcon sx={{ 
                        color: "#fff",
                        // color: isDarkmode ? '#fff' : "#0e0e0e" 
                    }} />

                    <Typography sx={{ ml: "10px", fontSize: 15, 
                        color: "#fff",
                        // color:  isDarkmode ? '#fff' : "#0e0e0e", 
                        fontFamily: 'GucciSans' 
                    }}>
                        Profile
                    </Typography>
                </MenuItem>
            }

            {/* <MenuItem sx={{paddingRight: "25px", ":hover": {backgroundColor: (!darkMode) ? {} : "#2f2c91", backdropFilter: "blur(20px)"}, 
                fontFamily: "GucciSans"}} onClick={() => [navigate("/disclaimer"), handleCloseMenu()]}
            >
                <img alt="" style={{height: 23, width: 23}} 
                    src={DisclaimerIconWhite} 
                    // src={(isDarkmode) ? DisclaimerIconWhite : DisclaimerIcon} 
                />

                <Typography sx={{ml: "10px", fontSize: 14, 
                    color: "#fff",
                    // color: (isDarkmode) ? "#fff" : "#0e0e0e", 
                    fontFamily: "GucciSans"
                }}>
                    Disclaimer
                </Typography>
            </MenuItem> */}

            {/* <MenuItem sx={{ paddingRight: "25px", 
            // ':hover': { backgroundColor: '#2f2c91', backdropFilter: 'blur(20px)' } 
            }} onClick={() => window.location.href = '/cybergems-spectrum'} >
                <DiamondOutlinedIcon sx={{ color:  isDarkmode ? '#fff' : "#0e0e0e" }} />

                <Typography sx={{ ml: "10px", fontSize: 14, color:  isDarkmode ? '#fff' : "#0e0e0e" }}>
                    Cybergems Spectrum
                </Typography>
            </MenuItem> */}

            <MenuItem sx={{ paddingRight: "25px", ":hover": {backdropFilter: "blur(20px)"},
                fontFamily: 'GucciSans'}} onClick={handleContactUsClick}
            >
                <SendOutlinedIcon sx={{ 
                    color: "#fff",
                    // color:  isDarkmode ? '#fff' : "#0e0e0e" 
                }} />

                <Typography sx={{ ml: "10px", fontSize: 14, 
                    color: "#fff",
                    // color: isDarkmode ? '#fff' : "#0e0e0e", 
                    fontFamily: 'GucciSans' 
                }}>
                    Contact Us
                </Typography>
            </MenuItem>

            {/* <MenuItem sx={{paddingRight: "25px", ":hover": {backgroundColor: (!darkMode) ? {} : "#2f2c91", backdropFilter: "blur(20px)"}}} 
                onClick={toggleDarkMode}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {(darkMode) ?
                        <LightModeOutlinedIcon sx={{color: "#fff"}} />
                    :
                        <Brightness2OutlinedIcon sx={{color: "#0e0e0e"}} />
                    }
                    
                    <Typography sx={{ml: "10px !important", fontSize: 15, color: (darkMode) ? "#fff" : "#0e0e0e", fontFamily: "GucciSans"}}>
                        {(darkMode) ? "Light Mode" : "Dark Mode"}
                    </Typography>
                </Stack>
            </MenuItem> */}

            {/* old version */}
            {/* <MenuItem sx={{ paddingRight: "25px", ':hover': { backgroundColor: '#2f2c91', backdropFilter: 'blur(20px)' } }} >
                <Brightness2OutlinedIcon sx={{ color: "#0e0e0e" }} />

                <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: "10px" }}>
                    <Typography sx={{ ml: "10px", fontSize: 15, color: "#0e0e0e" }}>Night Mode</Typography>
                    <AntSwitch checked={darkMode} onClick={toggleDarkMode} />
                    
                </Stack>
            </MenuItem> */}

            {isUserLoggedIn &&
                <MenuItem sx={{ paddingRight: "25px", 
                    ":hover": { backdropFilter: "blur(20px)"}
                    // ':hover': { backgroundColor: '#2f2c91', backdropFilter: 'blur(20px)'}
                    }} 
                    onClick={(e) => handleLogout(e)}
                >
                    <ExitToAppOutlinedIcon sx={{ 
                        color: "#fff",
                        // color:  isDarkmode ? '#fff' : "#0e0e0e", 
                        transform: 'scaleX(-1)' 
                    }} />

                    <Typography sx={{ ml: "10px", fontSize: 14, 
                        color: "#fff",
                        // color:  isDarkmode ? '#fff' : "#0e0e0e", 
                        fontFamily: 'GucciSans' 
                    }}>
                        Logout
                    </Typography>
                </MenuItem>

            }
        </Menu>
    )
}

export default AccountPopper