import { Grid, Typography, Card, ListItemText, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player';
import useWidth from "../../hooks/useWidth";

export default function ViewMemoryComponent (props) {
    const {cybergemDetailViewData, contentType, memoryURI, isDarkMode} = props;

    const navigate = useNavigate();
    const breakpoints = useWidth();

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload'
            }
        }
    };

    let gem_name = "#" + cybergemDetailViewData?.catalog?.tokenID + " " + cybergemDetailViewData?.catalog?.name;

    return(
        <>
            <Grid item xs={12} sx={{mb: 3}}>
                <Typography variant="h5" sx={{fontWeight: "bold", fontFamily: 'GucciSans', fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "14px" : "1.5rem"}}>
                    {cybergemDetailViewData?.catalog?.memory_title}
                </Typography>
            </Grid>
                
            <Grid item xs={12} sx={{textAlign:'center'}}>
                <Card sx={{margin:'auto', height:'50vh', bgcolor:'#80808017', zIndex: 0}}>
                    {contentType && (
                        <>
                            {contentType.startsWith('image/') ? (
                                <img src={memoryURI} alt="CyberGems - A collection of 8,888 NFT gems" style={{height:'inherit', objectFit:'contain', textAlign:'start',  width:'100%' }} />
                            ) : contentType.startsWith('video/') ? (
                                <ReactPlayer
                                    url={memoryURI}
                                    playing={true} // Set to true for auto-play
                                    controls={true}
                                    config={playerConfig}
                                    width="100%"
                                    height="inherit"
                                    muted={true}
                                    style={{ objectFit: 'contain', textAlign: 'start' }}
                                    light={<img src={cybergemDetailViewData?.links?.memory_thumbnail} alt='Thumbnail' style={{height: '80px'}} />}
                                />
                            ) : (
                                <Skeleton variant="rectangular" height={500} width="100%" animation="wave" />
                            )}
                        </>
                    )}
                </Card>

            </Grid>
            <Grid item xs={12} sm={12} sx={{m: (breakpoints == "xs" || breakpoints == "sm") ? "5px 0px" : 2}}>
                <Typography onClick={() => window.open(cybergemDetailViewData?.links?.memory_uri, "_blank")} 
                    sx={{
                        textOverflow: 'ellipsis', overflow: 'hidden', textAlign: (breakpoints == "xs" || breakpoints == "sm")  ? "start" : "center", 
                        fontSize: 14, color: "#fff", 
                        textDecoration: 'underline', cursor:'pointer', fontFamily: 'GucciSans'
                    }}
                >
                    {cybergemDetailViewData?.links?.memory_uri}
                </Typography>
            </Grid>
            
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid item xs={12} sm={12} sx={{mt: 2}}>
                        <ListItemText sx={{textAlign:'start'}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Gem Name
                                </Typography>
                            } 
                            secondary={gem_name} 
                            secondaryProps={{fontFamily: 'GucciSans'}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{mt: 2}}>
                        <ListItemText sx={{textAlign:'start'}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Variation
                                </Typography>
                            } 
                            secondary={cybergemDetailViewData?.catalog?.variation} 
                            secondaryProps={{fontFamily: 'GucciSans'}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{mt: 2}}>
                        <ListItemText sx={{textAlign:'start', display: "table-caption"}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Series
                                </Typography>
                            } 
                            secondary={
                                <Typography sx={{fontSize: 14, cursor: "pointer", color: "#fff", fontFamily: 'GucciSans'}}
                                    onClick={(e) => navigate(`/series/${cybergemDetailViewData?.catalog?.series.toLowerCase()}`)}
                                >
                                    {cybergemDetailViewData?.catalog?.series} 
                                </Typography>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sx={{mt: 2}}>
                        <ListItemText sx={{textAlign:'start'}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Description
                                </Typography>
                            } 
                            secondary={cybergemDetailViewData?.catalog?.description} 
                            secondaryProps={{fontFamily: 'GucciSans'}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{mt: 2}}>
                        <ListItemText sx={{textAlign:'start'}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Owner
                                </Typography>
                            } 
                            secondary={cybergemDetailViewData?.owner_address} 
                            secondaryProps={{fontFamily: 'GucciSans'}}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6} sx={{textAlign: "end", alignSelf: "center"}}>
                    <img alt="CyberGems - A collection of 8,888 NFT gems" src={cybergemDetailViewData?.links?.img} onClick={(e) => navigate("/" + cybergemDetailViewData?.catalog?.tokenID)}
                        style={{marginTop: "10px", height: "240px", objectFit: "contain", borderRadius: "8px", cursor: "pointer"}} 
                    />
                </Grid>
            </Grid>
        </>
    )
}