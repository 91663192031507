import React from "react";
import useWidth from "../../hooks/useWidth";
import {Box, Typography} from "@mui/material";

const DisclaimerComponent = () => {

    const breakpoints = useWidth();

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    return (
        <Box sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "1rem" : "4rem", pt: 0, pb: 0}}>
            <Typography sx={{textAlign: "center", fontSize: (breakpoints == "xs" || breakpoints == "sm") ?  22 : 30, fontFamily: "GucciSans",
                color: "#fff",
                textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                // color: (isDarkmode) ? "#fff" : "#000"
            }}>
                CyberGems Platform Disclaimer  
            </Typography>

            <Box sx={{mt: 4.2, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000",
                    textAlign: 'justify'
                }}>
                    Welcome to CyberGems, the premier platform for immortalizing your memories through unique 
                    digital gems (NFTs). At CyberGems, we are committed to providing a platform where users can
                    express themselves by attaching personal memories to their owned gems. However, as a responsible
                    entity in the digital and NFT space, we must outline certain conditions and disclaimers 
                    regarding the use of our platform.  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", fontStyle: "italic"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Content Responsibility:  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    CyberGems does not monitor, review, or edit the memories or content attached to 
                    the digital gems by the owners. As such, the responsibility for any content uploaded,
                    shared, or linked to the gems rests solely with the individual user. CyberGems will 
                    not be held liable for any inappropriate, offensive, or illegal content attached 
                    to the gems by their owners. 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", fontStyle: "italic"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Prohibited Content:  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    While we do not actively monitor content, we strictly prohibit the attachment of any content that is: 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <ul>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}>
                        Illegal under any applicable laws, 
                    </li>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}>
                        Infringing on the intellectual property rights of others, 
                    </li>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}>
                        Pornographic, obscene, or of a sexual nature,
                    </li>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}>
                        Violent, threatening, or promoting violence,
                    </li>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}>
                        Racist, hateful, or promoting hate towards any individual or group, 
                    </li>
                    <li style={{fontSize: 16, fontFamily: "GucciSans", 
                        color: "#fff",
                        textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                        // color: (isDarkmode) ? "#fff" : "#000"
                    }}> 
                        Misleading, defamatory, or known to be false. 
                    </li>
                </ul>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", fontStyle: "italic"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Reporting and Enforcement:   
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000",
                    textAlign: 'justify'
                }}>
                    If any content is found or reported to be in violation of our policies or the spirit 
                    of our community, CyberGems reserves the right to take appropriate action. This action
                    may include, but is not limited to, removing the content, banning the user from the platform,
                    and reporting the content to law enforcement or other relevant authorities.  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", fontStyle: "italic"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Liability:  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify',
                }}>
                    By using the CyberGems platform, users agree that CyberGems will not be held liable 
                    for any content that is uploaded or attached to the digital gems. Users agree to hold 
                    harmless and indemnify CyberGems, its affiliates, officers, agents, and employees from any
                    claim, suit, or action arising from or related to the use of the platform or violation of 
                    these policies, including any liability or expense arising from claims, losses, damages, 
                    suits, judgments, litigation costs, and attorneys’ fees. 
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", fontStyle: "italic"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Acceptance of Terms:   
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    By creating, buying, selling, or interacting with digital gems on the CyberGems 
                    platform, users agree to abide by this disclaimer and the terms of service of the
                    platform. It is the responsibility of each user to be aware of and understand these
                    terms before participating in the CyberGems community.  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start"}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000", 
                    textAlign: 'justify'
                }}>
                    We appreciate your understanding and cooperation in maintaining a respectful and 
                    lawful environment on the CyberGems platform. Our goal is to celebrate personal 
                    memories and expressions through the unique medium of digital gems, while ensuring 
                    the safety and integrity of our community.  
                </Typography>
            </Box>

            <Box sx={{mt: 3, textAlign: "start", mb: 7}}>
                <Typography sx={{fontSize: 16, fontFamily: "GucciSans", 
                    color: "#fff",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    // color: (isDarkmode) ? "#fff" : "#000"
                }}>
                    Thank you for being a part of CyberGems.  
                </Typography>
            </Box>
        </Box>
    )
}

export default DisclaimerComponent