import { Box, Button, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MobileSeriesButtonGroupComponent = (props) => {

    const { imageSources } = props;

    const navigate = useNavigate();

    const handleNavigateSeries = (e, src) => {
        if (src?.title === "Alpha") {
            navigate(`/series/${src?.title.toLowerCase()}`);
        } else {
            navigate('/minting')
        }
    }


    return (
        <>
            <Box>
                <ButtonGroup variant="text"
                    disableFocusRipple disableRipple
                    sx={{
                        '& .MuiButtonGroup-grouped:not(:last-child)': {
                            borderBottomWidth: '10%',
                            borderColor: '#fff',
                            // borderColor: isDarkmode ? '#fff' : 'rgb(0 0 0 / 47%)',
                        }
                    }}
                >
                    {imageSources?.slice(0, 3).map((src, index) => (
                        <Button
                            key={src?.id}
                            id={`button-${src?.id}`}
                            sx={{
                                textTransform: index === 0 ? "uppercase" : "none",
                                fontWeight:  500,
                                color:  '#fff',
                                padding: '1px 8px',
                                fontSize: 16,
                                lineHeight: 1,
                                fontFamily: 'GucciSans',
                                textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                            }}
                            onClick={(e) => handleNavigateSeries(e, src)}
                        >
                            {src?.title}
                        </Button>
                    ))}
                </ButtonGroup>

                <br />

                <ButtonGroup variant="text"
                    disableFocusRipple disableRipple
                    sx={{
                        mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': {
                            borderBottomWidth: '10%',
                            borderColor: '#fff',
                        }
                    }}
                >
                    {imageSources?.slice(3, 7).map((src, index) => (
                        <Button key={index} id={`button-${src?.id}`}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 500,
                                color:  "#fff",
                                padding: '1px 8px', fontSize: 16, lineHeight: 1, fontFamily: 'GucciSans',
                                textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                            }}
                            onClick={(e) => handleNavigateSeries(e, src)}
                        >
                            {src?.title}
                        </Button>
                    ))}
                </ButtonGroup>

                <br />

                <ButtonGroup variant="text"
                    disableFocusRipple disableRipple
                    sx={{
                        mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': {
                            borderBottomWidth: '10%',
                            borderColor: '#fff',
                        }
                    }}
                >
                    {imageSources?.slice(7, 10).map((src, index) => (
                        <Button key={index} id={`button-${src?.id}`}
                            sx={{
                                textTransform: 'none',
                                fontWeight: 500,
                                color:  '#fff',
                                padding: '1px 8px', fontSize: 16, lineHeight: 1, fontFamily: 'GucciSans',
                                textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                            }}
                            onClick={(e) => handleNavigateSeries(e, src)}
                        >
                            {src?.title}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>


        </>
    )
}

export default MobileSeriesButtonGroupComponent