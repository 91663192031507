import React, {useEffect} from "react";
import ViewGemDetailsComponent from "../component/detail-view/view-gem-details.component";
import useCybergemNft from "../hooks/useCybergemNft";
import GemComingSoonComponent from "../component/detail-view/gem-coming-soon.component";

const ViewGemDetailsScreen = (props) => {

    const {gemTokenId, setGemClicked, setMemoryAttach} = props;

    const {cybergemDetailViewData, isLoadingCybergemDetailView, getViewCybergem, attachMemory, isLoadingAttachMemory, successAttachment, errorCybergemDetailView} = useCybergemNft();

    useEffect(() => {
        if (gemTokenId) {
            getViewCybergem(gemTokenId);
        }
    }, [gemTokenId])

    const errorMessage = "Request failed with status code 403";

    if (errorMessage === errorCybergemDetailView) return <GemComingSoonComponent setGemClicked={setGemClicked} />

    return (
        <>
            <div>
                <ViewGemDetailsComponent 
                    details={cybergemDetailViewData} 
                    attachMemory={attachMemory} isLoadingAttachMemory={isLoadingAttachMemory}
                    setGemClicked={setGemClicked} successAttachment={successAttachment}
                    isLoadingCybergemDetailView={isLoadingCybergemDetailView}
                    setMemoryAttach={setMemoryAttach}
                    getViewCybergem={getViewCybergem}
                    gemTokenId={gemTokenId}
                />
            </div>
        </>
    )
}   

export default ViewGemDetailsScreen