import { Box, Button, IconButton, Menu, MenuItem, Popper } from "@mui/material";
import { useEffect, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


export default function EditActionPopperComponent (props) {

    const {handleEditImage} = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    useEffect(() => {
        
    },[])

    return(
        <div>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <MoreHorizIcon aria-describedby={id} />
            </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    onClose={handleClose} 
                >
                    <MenuItem onClick={handleEditImage} sx={{fontSize: 12, fontFamily: 'GucciSans'}}>Edit</MenuItem>
                    
                </Menu>
        </div>
    )
}