import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {useNavigate} from "react-router";

export default function ConfirmationDialog (props) {
    const {open, setOpenConfirmation, attachMemory, setOpenConfirmationModal, tokenId, memory, thumbnail, 
        memoryTitle, isLoadingAttachMemory, titleRequired, setTitleRequired, successAttachment, setOpen, setMemoryAttach, 
        setHasAttachedMemory
    } = props;

    const {enqueueSnackbar} = useSnackbar();

    const navigate = useNavigate();

    const url = window.location.pathname;

    const handleClose = () => {
        setOpenConfirmationModal(false)
        setOpen(true)
    }

    const handleUpload = () => {
        attachMemory(tokenId, memory, thumbnail, memoryTitle)
    }

    useEffect(()=>{
        if(successAttachment?.success){
            setOpenConfirmationModal(false)
            enqueueSnackbar("Memory attachment success!", { 
                variant: 'success',
                autoHideDuration: 3000
            });

            // if (url == "/series/alpha") {
                navigate("/view-memory?tokenId=" + tokenId);
                
            // } else {
            //     window.location.reload();
            // }
            
            
            if (url.match(/\/\d+$/)) {
                // do nothing if url == /{tokenId}

            } else {
                if (typeof setMemoryAttach === 'function') {
                    setMemoryAttach(true);  // Use setMemoryAttach directly from props if it exists
                }

                if (typeof setHasAttachedMemory === 'function') {
                    setHasAttachedMemory(true);  // Use setHasAttachedMemory directly from props if it exists
                }
            }
        }
    },[successAttachment])


    return(
        <Dialog open={open} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto"}}}>
            <DialogTitle className="dialog-bg" sx={{fontSize: 14, fontWeight: 600, bgcolor:'#0178f2', color:'white', p: 1.5, pt: 1, pb: 1, fontFamily: 'GucciSans'}}>CONFIRMATION</DialogTitle>
            <Divider/>
            <DialogContent sx={{textAlign:'center'}}>
                <Typography sx={{fontSize: 13, p: 2, pb: 0, color: 'gray', fontFamily: 'GucciSans'}}>
                    Please confirm your decision to attach memory. Kindly be aware that this action is irreversible and will result in a permanent modification.
                </Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent:'center', mb: 3}}>
                {isLoadingAttachMemory ?
                    <CircularProgress color="secondary" />
                :
                    <>
                        <button className="btn-cancel" onClick={handleClose} style={{fontFamily: 'GucciSans'}} >
                            Cancel
                        </button>
                        <button className="btn-donate" onClick={handleUpload} style={{fontFamily: 'GucciSans'}} >
                            Confirm
                        </button>
                    </>
                }
                
            </DialogActions>
        </Dialog>
    )
}