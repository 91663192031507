import { useState } from 'react';

const useApiRequestForCOA = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const makeAPIRequest = async (asyncFunc) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await asyncFunc;
            setData(response)
        } catch (error) {
            setError(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    return {isLoading, data, error, makeAPIRequest}
}

export default useApiRequestForCOA;
