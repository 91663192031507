import React, { useEffect, useState } from "react";
import {Tooltip, IconButton, Paper, Grid, Box, Typography, List, ListItemText, Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useWidth from "../../hooks/useWidth";
// import NotFoundIcon from "../../images/Minting-Soon.png";
import { useNavigate } from "react-router-dom";



const ViewGemDetailsComponent = (props) => {

    const { setGemClicked } = props;

    const breakpoints = useWidth();
    const navigate = useNavigate();

    const handleClick = () => {
        window.open('https://presale.magiceden.io/pay/661f2e4468628cb92ed3f0e6', '_blank');
    }

    return (
        <Paper elevation={3}
            sx={{
                top: "80px",
                bottom: "20px",
                left: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : {},
                right: "16px",
                padding: "16px",
                position: "fixed",       
                width: (breakpoints == "lg" || breakpoints == "xl") ? "450px" : {},
                overflowY: "auto",  
                borderRadius: "8px",
                boxShadow: "rgb(0 0 0 / 29%) 0px 4px 20px",
                zIndex: 1000,
                paddingTop: "0px !important",
            }}
        >
            <Box sx={{margin: 'auto', width: '100%'}}>

            <Box sx={{textAlign: "end", mt: 2}}>
                <CloseIcon sx={{color: "#808080", cursor: "pointer", fontSize: 20}} 
                    onClick={(e) => setGemClicked(false)} 
                />
            </Box>
        
            <Box sx={{width: "90%", margin: "auto",display: "inline-grid",  alignSelf:'center', alignItems: 'center'}}> 

                {/* <img alt="" src={NotFoundIcon} style={{ width : (breakpoints == "lg" || breakpoints == "xl") ? "600px" : {}}} 
                /> */}

                {/* <Typography sx={{fontWeight: "bold", letterSpacing: "0.4px", fontSize: 20, fontFamily: 'GucciSans',
                    mt: "5px", 
                }}>
                    Gem Minting Soon
                </Typography> */}

                <Typography sx={{fontWeight: "bold", letterSpacing: "0.4px", fontSize: 50, fontFamily: 'GucciSans',
                    marginBottom: (breakpoints == "xl") ? "10px" :  (breakpoints == "xs" || breakpoints == "sm") ? "5px" : "5px", mt: 30
                }}>
                   minting soon
                </Typography>

                <Typography onClick={handleClick} sx={{letterSpacing: "0.2px", cursor: 'pointer', color: "#fff", fontFamily: 'GucciSans', fontSize: 14, textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"}}>
                    CyberGems now available for Presales Minting.
                </Typography>

                <Box sx={{textAlign: "center", mt: 3, justifyContent: "center"}}>
                    <button className="btn-navigate" onClick={() => navigate("/home")} style={{height: "38px"}}>
                        <Typography sx={{color: "#fff", ml: 1, mt: "-2px", mr: "4px", fontFamily: 'GucciSans'}}>
                            Navigate back home
                        </Typography>
                    </button>
                </Box> 
        </Box>
           
            </Box>
        </Paper>
    )
}

export default ViewGemDetailsComponent
