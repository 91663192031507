import ApiService from './apiService';

const BASE_URL = process.env.REACT_APP_CG_NFT_HOST;
const CG_NFT_SERVICE_SUBSCRIPTION_KEY = process.env.REACT_APP_CG_NFT_SERVICE_SUBSCRIPTION_KEY;

const RETRIEVE_NONCE_MESSAGE_ENDPOINT = "/api/v1/wallet/nonce/message";
const WALLET_SIGN_IN_ENDPOINT = "/api/v1/wallet/sign-in";
const GET_PROFILE_ENDPOINT = "/api/v1/profile/retrieve";
const ATTACH_MEMORY_ENDPOINT = "/api/v1/nft-memory/upload";
const COA_AUTH_RETRY_ENDPOINT = "/api/v1/nft-coa/retry/authenticate";
const DOWNLOAD_COA_ENDPOINT = "/api/v1/nft-coa/download";
const GET_CATALOG_LIST = "/api/v1/catalog/list";
const GET_VIEW_CYBERGEM_ENDPOINT = "/api/v1/catalog/view";
const GET_CG_INFO = "/api/v1/cybergems/info";
const GET_USER_CATALOG_LIST_BY_TOKEN_LIST_ENDPOINT = "/api/v1/catalog/list/filter-tokens";
const RETRY_COA_ENDPOINT = "/api/v1/nft-coa/retry/authenticate";
const GET_MEMORY = "/api/v1/nft-memory/download";

class CgNftService {

  static async retrieveNonceMessage(wallet) {
    try {
      const url = `${BASE_URL}${RETRIEVE_NONCE_MESSAGE_ENDPOINT}`;
      const params = {wallet: wallet};
      const response = await ApiService.get(url, params, this.getHeaders(), true);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async signIn(wallet, signature) {
    try {
      const url = `${BASE_URL}${WALLET_SIGN_IN_ENDPOINT}`;
      const body = {wallet: wallet, signature: signature};
      const response = await ApiService.post(url, body, false, this.getHeaders(), true);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getProfile(location) {
    try {
      const url = `${BASE_URL}${GET_PROFILE_ENDPOINT}`;
      const params = {};
      const response = await ApiService.customGet(url, params, this.getHeaders(), false, location);
      
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async coaAuthRetry(tokenId) {
    try {
      const url = `${BASE_URL}${COA_AUTH_RETRY_ENDPOINT}`;
      const body = new FormData();
      body.append('tokenId', tokenId);
      const response = await ApiService.post(url, body, true, this.getHeaders());
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async downloadCOA(tokenId) {
    try {
      const url = `${BASE_URL}${DOWNLOAD_COA_ENDPOINT}`;
      const body = new FormData();
      body.append('tokenId', tokenId);
      const response = await ApiService.post1(url, body, true, this.getHeaders());
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async attachMemory(token_id, memory, thumbnail, memoryTitle) {
    try {
      const url = `${BASE_URL}${ATTACH_MEMORY_ENDPOINT}`;
      const formData = new FormData();
      formData.append('tokenId', token_id);
      formData.append('memory', memory);
      formData.append('thumbnail', thumbnail);
      formData.append('memoryTitle', memoryTitle);
      const response = await ApiService.post(url, formData, true, this.getHeaders());
      return response;
    } catch (error) {
      throw error;
    }
  }

  // static async getCybergemList(page, limit) {
  //   try {
  //     const url = `${BASE_URL}${GET_CATALOG_LIST}`;
  //     const params = {page, limit};
  //     const response = await ApiService.get(url, params, this.getHeaders());
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  static async getViewCybergem(token_id) {
    try {
      const url = `${BASE_URL}${GET_VIEW_CYBERGEM_ENDPOINT}`;
      const params = {token_id};
      const response = await ApiService.get(url, params, this.getHeaders(), true);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getCybergemInfo() {
    try {
      const url = `${BASE_URL}${GET_CG_INFO}`;
      const params = {};
      const response = await ApiService.get(url, params, this.getHeaders(), true);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getUserCatalogListByTokenList(tokenList) {
    try {
      const url = `${BASE_URL}${GET_USER_CATALOG_LIST_BY_TOKEN_LIST_ENDPOINT}`;
      const body = tokenList;

      const response = await ApiService.post(url, body, false, this.getHeaders());

      return response;

    } catch (error) {
      throw error;
    }
  }

  static async retryCOA(tokenId) {
    try {
      const url = `${BASE_URL}${RETRY_COA_ENDPOINT}`;
      const body = new FormData();
      body.append('tokenId', tokenId);
      const response = await ApiService.post(url, body, true, this.getHeaders());
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getMemoryImage(cid) {
    try {
      const url = `${BASE_URL}${GET_MEMORY}`;
      const params = {cid: cid};
      const response = await ApiService.get1(url, params, this.getHeaders());
      return response;
    } catch (error) {
      throw error;
    }
  }

  static getHeaders() {
    var headers = {
      "Ocp-Apim-Subscription-Key": CG_NFT_SERVICE_SUBSCRIPTION_KEY,
    };
    return headers;
  }

  

}

export default CgNftService;