import { Box, IconButton } from "@mui/material"
import youtubeIcon from '../icons/social-media/youtube.png';
import instagramIcon from '../icons/social-media/instagram.png';
import twitterIcon from '../icons/social-media/twitter.png';
import discordIcon from '../icons/social-media/discord-white.png';
import openseaIcon from '../icons/social-media/opensea.png';
import tiktokIcon from '../icons/social-media/tiktok.png';

import youtubeIconReverse from '../icons/social-media/youtube-reverse.png';
import instagramIconReverse from '../icons/social-media/instagram-reverse.png';
import twitterIconReverse from '../icons/social-media/twitter-reverse.png';
import openseaIconReverse from '../icons/social-media/opensea-reverse.png';
import tiktokIconReverse from '../icons/social-media/tiktok-reverse.png';
import useWidth from "../hooks/useWidth";
import magicEdenIcon from "../icons/social-media/magic-eden.png";
import mediumIcon from "../icons/social-media/medium-reverse.png";

const FooterComponent = () => {

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"))
    const breakpoints = useWidth();
    const openseaURL = process.env.REACT_APP_OPENSEA_COLLECTION_URL;

    return (
        <Box
            sx={{
                mb: 2,
                fontFamily: 'GucciSans',
                position: (breakpoints == "xs" || breakpoints == "sm") ? "absolute" : {}
            }}
            className="footer-class"
        >
            <Box>
                <IconButton color="primary" href="https://x.com/CyberGemsSwiss" target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img style={{ width: (breakpoints == "xs") ? 18 : 20, height: (breakpoints == "xs") ? 17 : 20 }} alt="Twitter" 
                        src={twitterIconReverse}
                        // src={isDarkmode ? twitterIconReverse : twitterIcon}
                    />
                </IconButton>

                <IconButton color="primary" href="https://www.instagram.com/cybergemsnft/?hl=en" target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img style={{ width: (breakpoints == "xs") ? 18 : 22, height: (breakpoints == "xs") ? 17 : 22 }} alt="Instagram" 
                        src={instagramIconReverse} 
                        // src={isDarkmode ? instagramIconReverse : instagramIcon} 
                    />
                </IconButton>

                <IconButton onClick={() => window.open("https://medium.com/@cybergemsnft_16664")}>
                    <img alt="" src={mediumIcon} style={{width: 30, height: "auto"}} />
                </IconButton>

                <IconButton onClick={() => window.open("https://discord.gg/9V9zYWjEPf")}>
                    <img alt="" src={discordIcon} style={{width: 30, height: "auto"}} />
                </IconButton>
                
                {/* <IconButton color="primary" href={openseaURL} target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img style={{ width: (breakpoints == "xs") ? 18 : 28, height: 'auto' }} alt="OpenSea" 
                        src={openseaIconReverse} 
                    />
                </IconButton>  */}

                {/* <IconButton color="primary" href="https://presale.magiceden.io/pay/661f2e4468628cb92ed3f0e6" target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img alt="Magic Eden"  style={{ 
                            width: (breakpoints == "xs") ? "65px" : "80px",
                            marginLeft: (breakpoints == "xs") ? "-24px" : "-28px",
                            // width: (breakpoints == "xs") ? 18 : 28, height: 'auto' 
                        }} 
                        src={magicEdenIcon}  
                    />
                </IconButton> */}
                
                {/* <IconButton color="primary" href="https://www.tiktok.com/@cybergemsnft " target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img src={isDarkmode ? tiktokIconReverse : tiktokIcon} style={{ width: (breakpoints == "xs") ? 15 : 22, height: 'auto' }} alt="Tiktok" />
                </IconButton> */}
                {/* <IconButton color="primary" href="https://www.youtube.com/channel/UC364qkePh_veZ-gBNTPjcwg" target="_blank" sx={{ paddingBottom: '6px' }}>
                    <img src={isDarkmode ? youtubeIconReverse : youtubeIcon} style={{ width: (breakpoints == "xs") ? 25 : 30, height: 'auto' }} alt="YouTube" />
                </IconButton> */}
            </Box>
            {/* <p style={{ margin: 0, fontSize: 11, color: isDarkmode ? '#fff' : '#000' }}>This is a Zurich Switzerland Company</p> */}
            <p style={{ margin: 0, fontSize: 11,
                color: '#fff', 
                // marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-22px" : "-22px",
                // marginLeft: (breakpoints == "xs" || breakpoints == "sm") ? {} : "-26px",
                // color: isDarkmode ? '#fff' : '#000' 
            }}>A Swiss Company</p>
        </Box>
    )
}

export default FooterComponent