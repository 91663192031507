import { useEffect, useState } from 'react';
import './App.css';
import {history} from "./router/history.js";
import {BrowserRouter} from "react-router-dom";
import Routes from "./router";
import AppbarComponent from './layout/appbar-v2.component.jsx';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import useWidth from "./hooks/useWidth.jsx";
import DesktopBgImage from "./images/webp-darkmode-bg-darker.webp";
import MobileBgImage from "./images/webp-darkmode-bg-darker-croppped.webp";
import { routes } from './router/routes.js';
import { match } from 'path-to-regexp';

function App() {

  const url = window.location.pathname;
  const breakpoints = useWidth();


  const allowedSeries = ['lumina', 'myst', 'quantum', 'serenity', 'nova', 'catalyst', 'phantom', 'zenith', 'infinity'];

  useEffect(() => {
    // Filter out wildcard and dynamic token routes
    const routePaths = routes.map(route => route.path).filter(path => !path.includes(':') && path !== "*");
    // Check exact matches
    const isExactMatch = routePaths.includes(url);
  
    // Specific check for /series/alpha
    const seriesAlphaRoute = routes.find(route => route.path === '/series/:series');
    const isSeriesAlphaMatch = match(seriesAlphaRoute.path.replace(':series', 'alpha'), { decode: decodeURIComponent })(url);
  
    // Check if it's a /series/:series path but not /series/alpha
    const seriesMatch = match('/series/:series', { decode: decodeURIComponent })(url);
    const isSeriesOtherMatch = seriesMatch && !isSeriesAlphaMatch;
  
    // Extract the series name from the URL if it matches /series/:series
    const series = seriesMatch ? seriesMatch.params.series : null;
  
    // Check if the series is one of the allowed series
    const isAllowedSeries = allowedSeries.includes(series);
  
    if (isSeriesOtherMatch && isAllowedSeries) {
      // Redirect to /minting
      window.location.href = "/minting";
    } else if (!isExactMatch && !isSeriesAlphaMatch) {
      // Redirect to /home
      window.location.href = "/home";
    }
  }, [url, routes]);

  const [darkMode, setDarkMode] = useState(false);
  const [isPageRefreshing, setPageRefreshing] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.removeItem("ownedTokensCached");
      setPageRefreshing(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, []); 


  useEffect(() => {
    // You can save the user's preference in local storage or any other way
    const isDarkModeEnabled = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkModeEnabled);
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', String(newDarkMode));
    
    // if ((url === '/faq' || url === '/about' || url === '/cybergems-spectrum') && (darkMode || !darkMode)){ 
    //   window.location.reload();
    // }
  };

  const theme = createTheme({
    palette: {
      mode: "dark"
    },
  });


  const DesktopBackgroundImage = `url(${DesktopBgImage})`;
  const MobileBackgroundImage = `url(${MobileBgImage})`;
  
  return (
    <div className="App" style={{backgroundSize: (breakpoints == "xs" || breakpoints == "sm") ? "cover" : "100% auto",
      backgroundImage: (breakpoints == "xs" || breakpoints == "sm") ? MobileBackgroundImage : DesktopBackgroundImage,
      backgroundPosition: (breakpoints == "xs" || breakpoints == "sm") ? "bottom" : {}
    }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BrowserRouter history={history}>
          {url !== "/" && 
            <AppbarComponent 
              toggleDarkMode={toggleDarkMode} 
              darkMode={darkMode} 
              isPageRefreshing={isPageRefreshing}
            /> 
          } 
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App