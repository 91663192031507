import React, {useState} from "react";
import {Box, Typography,} from '@mui/material';
import FooterComponent from "../layout/footer.component";
import AllSeriesComponent from "../component/homepage/all-series.component";
import {seriesImageSources} from "../common/seriesImageSources";

const AllSeriesScreen = () => {
    
    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    const [isImageLoading, setImageLoading] = useState(new Array(seriesImageSources.length).fill(false));

    const handleImageLoad = (index) => {
        setImageLoading(prevState => {
            const newState = [...prevState];

            newState[index] = true;

            return newState;
        });
    }


    return (
        <div className="containerHomeAllSeries">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'GucciSans',
                        // fontSize: isSmScreen ? 16 : 22,
                        // fontSize: 'clamp(1.875rem, 1.25rem + 1.5625vw, 3.125rem)',
                        // fontSize: 'clamp(2.5rem, 0.6250rem + 4.6875vw, 6.25rem)',
                        fontSize: 60,
                        margin: 0,
                        lineHeight: 1.6,
                        fontWeight: 600,
                        textAlign: 'center',
                        // color: '#1d1d1f',
                        // textAlign: 'justify',
                        // textJustify: 'inter-word',
                        // width: isSmScreen ? '96.5%' : '29%',
                        mt: -2,
                        color: "#fff" 
                        // color: (isDarkmode) ? "#fff" : "#000000b3"
                    }}
                >
                    {/* CYBERGEMS is a collection of 8,888 NFT unique gems designed as time capsules, forever capturing life's precious memories. */}
                    Treasure Forever
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography sx={{fontFamily: "GucciSans", mt: "-8px", fontSize: '1.4rem', fontWeight: 500, textAlign: 'center',
                    color: '#fff'
                    // color: (isDarkmode) ? '#fff' : '#0000008F' 
                }}>
                    8,888 &nbsp;NFT gem collection
                </Typography>
            </Box>

            <AllSeriesComponent isImageLoading={isImageLoading} handleImageLoad={handleImageLoad} seriesImageSources={seriesImageSources} />
        </div>
    )
}

export default AllSeriesScreen