import { useState, useEffect, useRef } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from "web3";
import CgNftService from '../services/CgNftService';
import ApiService from '../services/apiService';
import useAuth from './useAuth';
import {log} from "../console-config.log";

function useMetamaskProvider() {

  const [wallet, setWallet] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [currentNetworkName, setCurrentNetworkName] = useState(null);
  const [currentWallet, setCurrentWallet] = useState(null);
  const { signIn, error: error_useAuth, jwt_token } = useAuth();
  const abortControllerRef = useRef(new AbortController()); // for cancelling api requests to be triggered on view

  useEffect(()=>{
    if(jwt_token == null) return;
    setIsMetamaskConnected(true)
  }, [jwt_token])

  useEffect(() => {

    detectEthereumProvider().then((provider) => {
      
      if (!provider) {
        setIsMetamaskInstalled(false)
        setError("No Metamask Installed.");
        return;
      }
      log("Ethereum successfully detected in useMetamaskProvider useEffect xxxx");
      setIsMetamaskInstalled(true);

      // TEMPORARILY COMMENT THIS CODE COZ IT WILL LET THE METAMASK POPUP SHOW EVEN IF THE USER STILL NOT CLICKED THE LOGIN BUTTON
      // Request access to the user's MetaMask accounts
      // window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts)=>{
      // // Set the current account to the first account in the list (assuming user has connected only one account)
      //   setCurrentWallet(accounts[0])
      // });

      // // Get the current network ID
      // window.ethereum.request({ method: 'net_version' }).then((networkId)=>{
      //   var network_name = getNetworkName(networkId);
      //   setCurrentNetworkName(network_name);
      // });

      // // Listen accountsChanged event
      // window.ethereum.on('accountsChanged', (accounts) => {
      //   log("accounts length here: " + accounts.length) // if 0, means no user is logged in or user disconnected via metamask extension
      //   log(">>>", JSON.stringify(accounts)) // if 0, means no user is logged in or user disconnected via metamask extension
      //   setCurrentWallet(accounts[0])
      //   if (accounts.length === 0) {
      //     setIsMetamaskConnected(false);
      //     setWallet(null)
      //     setSignature(null)
      //     setError(null)
      //   }
      // });

      // // Listen networkChanged event
      // window.ethereum.on('networkChanged', (newNetworkId)=>{
      //   log("networkChanged here: " + newNetworkId)
      //   var network_name = getNetworkName(newNetworkId);
      //   log("network_namehere: " + network_name)
      //   setCurrentNetworkName(network_name);
      // });

    });

    return () => {
      abortControllerRef.current.abort(); // Abort the ongoing request when the component unmounts or the effect re-runs
    };

  }, [])

  const connectMetamask = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // check if metamask extension is installed
      const provider = await initializeDetectEthereumProvider();

      // REQUEST ACCESS TO THE USER'S METAMASK ACCOUNTS
      window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts)=>{
      // Set the current account to the first account in the list (assuming user has connected only one account)
        setCurrentWallet(accounts[0])
      });

      // GET THE CURRENT NETWORK ID
      window.ethereum.request({ method: 'net_version' }).then((networkId) => {
        var network_name = getNetworkName(networkId);

        setCurrentNetworkName(network_name);
      });


      // get metamask wallet
      const wallet = await requestFirstAccount();
      setWallet(wallet);
      // execute api call for nonce message
      const response = await retrieveNonceMessage(wallet);
      const nonce_message = response.data;
      // create personal sign message
      let web3 = new Web3(provider);
      let msg = web3.utils.toHex(nonce_message);
      let from = wallet;
      if (msg == null || from == null) {
        setError("msg and from parameters are required.");
        return;
      }
      // personal sign to get signature
      const signature = await requestPersonalSign(msg, from);
      setSignature(signature);
      signIn(wallet, signature);
    } catch (error) {
      if (error.code === 4001) {
        setWallet(null)
        setSignature(null)
      }
      setError(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  const retrieveNonceMessage = async (wallet) => {
    if (wallet == null) {
      throw Object.assign(new Error("wallet parameter is required."), { code: 402 });
    }
    abortControllerRef.current = ApiService.cancelTokenSource; // Reset the abort controller for each request
    try {
      const response = await CgNftService.retrieveNonceMessage(wallet);
      return response;
    } catch (error) {
      throw (error);
    }
  }

  const requestPersonalSign = async (msg, from) => {
    if (msg == null && from == null) {
      throw Object.assign(new Error("msg and from parameters are required."), { code: 402 });
    }
    try {
      const signature = await window.ethereum.request({ method: 'personal_sign', params: [msg, from] })
      return signature;
    } catch (error) {
      throw Object.assign(new Error(error.message), { code: error.code });
    }
  }

  const requestFirstAccount = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let wallet = accounts[0];
      return wallet;
    } catch (error) {
      throw (error)
    }
  }

  const initializeDetectEthereumProvider = async () => {
    try {
      const provider = await detectEthereumProvider();
      if (!provider) {
        setIsMetamaskInstalled(false);
        throw Object.assign(new Error("No Metamask Installed"), { code: 402 });
      }
      setIsMetamaskInstalled(true);
      return provider;
      
    } catch (error) {
      throw error;
    }
  };

  const getNetworkName = (networkId) => {

    // Check if networkId is an integer for metamask mobile app
    if (Number.isInteger(networkId)) {
      networkId = networkId.toString();
    }

    switch (networkId) {
      case "1":
        return "Ethereum Mainnet";

      case "3":
        return "Ropsten";

      case "4":
        return "Rinkeby";
        
      case "42":
        return "Kovan";

      case "59144":
        return "Linea";

      case "5":
          return "Goerli";
      
      case "11155111":
        return "Sepolia";
    
      case "59140":
        return "Linea Goerli"

      default:
        return "Unknown";
    }
  }

  // Function to switch network to Sepolia
  const switchToSepoliaTestnet = async () => {
    try {
      // Check if ethereum object is available
      if (window.ethereum) {
        // Request switch to Sepolia network 
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xaa36a7' }], // Make sure to use the same chain ID here
        });
        // After switching, get the current network ID
        const networkId = await window.ethereum.request({ method: 'net_version' });
        var network_name = getNetworkName(networkId);
        setCurrentNetworkName(network_name);
      } else {
        log.error('MetaMask not detected');
      }
    } catch (error) {
      log.error('Error switching network:', error);
    }
  };

  const cancelApiRequest = () => {
    ApiService.cancelRequest();
  };

  return { wallet, signature, jwt_token, isMetamaskConnected, isLoading, error, currentNetworkName, isMetamaskInstalled,  currentNetworkName, currentWallet,
    connectMetamask, cancelApiRequest, switchToSepoliaTestnet }

}

export default useMetamaskProvider;