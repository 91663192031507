import {CircularProgress, Container, Grid, IconButton, ListItemText, Tooltip, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useNavigate} from "react-router";
import useCybergemNft from "../hooks/useCybergemNft";
import { useEffect, useState } from "react";
import SuccessCOAIcon from "../icons/success-coa.png";
import FailedCOAIcon from "../icons/failed.png";
import PendingCOAIcon from "../icons/pending.png";
import SuccessCOAReverseIcon from "../icons/success-coa-reverse.png";
import FailedCOAReverseIcon from "../icons/failed-reverse-coa.png";
import PendingCOAReverseIcon from "../icons/pending-reverse-coa.png";

import ViewMemoryComponent from "../component/view-memory/view-memory-component";
import RetryConfirmationModal from "../component/view-memory/dialog/retry-confirmation-modal";
import ErrorMemoryDialog from "../component/dialog/error-message/error-memory-dialog";
import ErrorTokenDialog from "../component/dialog/error-message/error-token-dialog";
import ErrorTokenNotFoundDialog from "../component/dialog/error-message/error-token-not-found-dialog";
import useWidth from "../hooks/useWidth";
import {log} from "../console-config.log";

export default function ViewMemoryScreen () {

    const breakpoints = useWidth();

    const tokenID = new URLSearchParams(window.location.search).get("tokenId");

    const {cybergemDetailViewData, getViewCybergem, retryCOA, cybergemRetryCOA, isLoadingRetryCOA, errorRetryCOA, isLoadingCybergemDetailView, errorCybergemDetailView} = useCybergemNft();
    const navigate = useNavigate();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openErrorMemoryDialog, setOpenErrorMemoryDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const isDarkMode = localStorage.getItem("darkMode");

    const [contentType, setContentType] = useState(null);
    const memoryURI = cybergemDetailViewData?.links?.memory_uri;
   
    // const baseURL = "https://salmon-desert-0600ffb1e.4.azurestaticapps.net";
    // const baseURL = "http://localhost:3000";
    const baseURL = "https://cybergems.com";


    const handleOpenConfirmationModal = () => {
        setOpenConfirmationModal(true)
    }

    const handleCloseNoAttachment = () => {
        setOpenErrorMemoryDialog(false)
    }

    const handleCloseTokenNotFound = () => {
        setOpenErrorDialog(false)
    }

    const handleViewCOA = () => {
        let liveTabURL =  baseURL + "/view-coa?tokenId=" + cybergemDetailViewData?.catalog?.tokenID;
        
        window.location.href= liveTabURL;
    }


    useEffect(() => {
        if (tokenID) {
            getViewCybergem(tokenID);
        }
    }, [tokenID])

    useEffect(() => {
        // Fetch only the headers to determine the content type
        fetch(memoryURI, { method: 'HEAD' })
        .then(response => {
            const type = response.headers.get('content-type');
            setContentType(type);
        })
        .catch(error => console.error('Error fetching content type:', error));
    }, [memoryURI]);

    useEffect(() => {
        if(cybergemDetailViewData?.catalog?.memory_title === null){
            setOpenErrorMemoryDialog(true)
        }
    }, [cybergemDetailViewData]);

    useEffect(() => {
        if(errorCybergemDetailView){
            setOpenErrorDialog(true)
        }
    }, [errorCybergemDetailView]);

    return (
        <>
            <Container maxWidth="md">
                <Grid container sx={{textAlign:'center', mb: 3}}>
                    <Grid item xs={12} sm={12} sx={{textAlign:'start', mb: 2}}>
                        <IconButton onClick={(e) => navigate(-1)} sx={{ml: -2}}>
                            <KeyboardBackspaceIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11} sm={11} sx={{display: "inline-grid"}}>
                        <ListItemText sx={{textAlign:'start', display: "inline-grid"}} 
                            primary={
                                <Typography sx={{fontWeight:'bold', fontSize: 13, fontFamily: 'GucciSans'}}>
                                    Token ID
                                </Typography>
                            } 
                            secondary={
                                <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 16, 
                                    cursor: "pointer", fontFamily: 'GucciSans', fontWeight: "bold",
                                    color: "#fff",
                                    // color: (isDarkMode === "false") ? "rgba(0, 0, 0, 0.6)" : "#fff",
                                }}
                                    onClick={(e) => navigate("/" + cybergemDetailViewData?.catalog?.tokenID)}
                                >
                                    {cybergemDetailViewData?.catalog?.tokenID} 
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} sx={{textAlign:'end', alignSelf:'center'}}>
                        {isLoadingCybergemDetailView ? 
                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 24, cursor:'pointer'}} 
                            src={SuccessCOAReverseIcon} 
                            // src={(isDarkMode === "false") ? SuccessCOAIcon : SuccessCOAReverseIcon} 
                        />
                        :
                        <>
                            {cybergemDetailViewData?.catalog?.coa_status === "S" ?
                                <Tooltip title="View Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                    <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 24, cursor:'pointer'}} onClick={handleViewCOA} 
                                        src={SuccessCOAReverseIcon} 
                                        // src={(isDarkMode === "false") ? SuccessCOAIcon : SuccessCOAReverseIcon} 
                                    />
                                </Tooltip>
                                :
                                cybergemDetailViewData?.catalog?.coa_status === "P"  ?
                                    <Tooltip title="Pending Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" style={{height: 24, cursor: "default"}} 
                                            src={PendingCOAReverseIcon} 
                                            // src={(isDarkMode === "false") ? PendingCOAIcon : PendingCOAReverseIcon} 
                                        />
                                    </Tooltip>
                                :  
                                    isLoadingRetryCOA ?
                                    <CircularProgress size={20} color="secondary" />
                                :
                                    <Tooltip title="Regenerate Certificate" arrow placement="top" sx={{fontFamily: 'GucciSans'}}>
                                        <img alt="CyberGems - A collection of 8,888 NFT gems" onClick={handleOpenConfirmationModal} style={{height: 24, cursor:'pointer'}} 
                                            src={FailedCOAReverseIcon}
                                            // src={(isDarkMode === "false") ? FailedCOAIcon : FailedCOAReverseIcon}
                                        />
                                    </Tooltip>
                            }
                        </>
                    }
                            
                    </Grid>
                    
                    {/*Child component */}
                    <ViewMemoryComponent cybergemDetailViewData={cybergemDetailViewData} contentType={contentType} memoryURI={memoryURI} isDarkMode={isDarkMode} />
                </Grid>

                <RetryConfirmationModal retryCOA={retryCOA} openConfirmationModal={openConfirmationModal} setOpenConfirmationModal={setOpenConfirmationModal} isLoadingRetryCOA={isLoadingRetryCOA}
                tokenID={tokenID} cybergemRetryCOA={cybergemRetryCOA}/>

                {/* NO ATTACHMENT OF MEMORY FOUND ERROR DIALOG */}
                <ErrorMemoryDialog handleClose={handleCloseNoAttachment} openErrorMemoryDialog={openErrorMemoryDialog}/>

                {/* TOKEN NOT FOUND ERROR DIALOG */}
                <ErrorTokenNotFoundDialog openErrorDialog={openErrorDialog} handleClose={handleCloseTokenNotFound}/>

            </Container>
        </>
    )
}