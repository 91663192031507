import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import React from "react";

const faqSteps = [
  {
    label: 'What are Cybergems?',
    description: `Cybergems are digital artworks representing gems and crystals. These NFTs serve as assets where you can attach memories in the form of photos, audio, and video clips.`,
  },
  {
    label: 'How can I own a Gem?',
    description:
      <Grid item xs={12} sx={{ fontFamily: 'GucciSans', }}>
        You can own a gem through Opensea by using your Metamask wallet account. For detailed instructions, please refer to the <a href="https://support.opensea.io/hc/en-us/sections/10310791546899-FAQ" target="_blank" style={{ textDecoration: 'none' }}>Opensea FAQ</a> and <a href="https://metamask.io/faqs/" target="_blank" style={{ textDecoration: 'none' }}>Metamask FAQ</a>.
      </Grid>
  },
  {
    label: 'How do I attach a memory?',
    description: `After successfully logging into Opensea, select your owned gem and utilize the Cybergems platform to attach a memory.`,
  },
  {
    label: 'How do I register to become a verified publisher and earn a percentage for each gem resale?',
    description: `To register as a verified publisher and earn a percentage from each gem resale, please contact us via email. A Cybergems NFT administrator will then provide you with instructions on the registration process. `,
  },
  {
    label: 'Why are there imperfect gems?',
    description: `We celebrate the art of imperfection, finding joy in the unique surprises that each gem reveals. Our collection serves as a reminder that beauty lies not in perfection, but in being true to oneself - quirks and all. We believe that "Where Every Angle Tells a Story." `,
  },
  {
    label: 'Can I change my thumbnail?',
    description: `Yes, as an owner, you have the opportunity to purchase an updated version of your thumbnail (EraCons). This can be done annually, and your new thumbnail will be included in the updated yearly series cover page that is launched each February. It's important to note that while the thumbnail image can be updated, the memories associated with it remain unchanged, as CGM is committed to ensuring your memories are preserved forever.  `,
  },
  {
    label: 'How will be the Auction Process / How will I join the auction?',
    description: `The auction process is straightforward. CGM will publish the EraCons for auction, at which point you can participate by placing bids on the items you're interested in. To join the auction, you'll need to be a token owner and follow our auction listings (that will be launched January), which capture token owners at the start of the event. Make sure you're subscribed to our communications to get timely notifications about when and how to participate in the auctions. `,
  },
  {
    label: 'How will be the share distribution of each auction?',
    description:
      <Grid container>
        <Grid item xs={12} sx={{ fontFamily: 'GucciSans', }}>
          For each EraCons auctioned, the share distribution is pre-determined as follows:
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0,  marginTop: 0, marginBottom: 0 }}>
              <ListItemIcon sx={{ minWidth: '20px' }}>
                <CircleIcon sx={{ width: 10, height: 10 }} />
              </ListItemIcon>
              <ListItemText
                sx={{marginTop: 0, marginBottom: 0}}
                primary={<React.Fragment>
                  <Typography sx={{ marginTop: 0, marginBottom: 0, fontFamily: 'GucciSans' }}>
                    10% of the sales proceeds will be allocated to the CGM platform. This is used to cover the costs of maintaining and operating the platform.
                  </Typography>
                </React.Fragment>}
              />
            </ListItem>,
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0,  marginTop: 0, marginBottom: 0 }}>
              <ListItemIcon sx={{ minWidth: '20px' }}>
                <CircleIcon sx={{ width: 10, height: 10 }} />
              </ListItemIcon>
              <ListItemText
                sx={{marginTop: 0, marginBottom: 0}}
                primary={<React.Fragment>
                  <Typography sx={{ marginTop: 0, marginBottom: 0, fontFamily: 'GucciSans' }}>
                    20% is designated for the chosen charity of the year. However, this percentage is optional and based on the preferences of the EraCons owner.
                  </Typography>
                </React.Fragment>}
              />
            </ListItem>,
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0,  marginTop: 0, marginBottom: 0 }}>
              <ListItemIcon sx={{ minWidth: '20px' }}>
                <CircleIcon sx={{ width: 10, height: 10 }} />
              </ListItemIcon>
              <ListItemText
                sx={{marginTop: 0, marginBottom: 0}}
                primary={<React.Fragment>
                  <Typography sx={{ marginTop: 0, marginBottom: 0, fontFamily: 'GucciSans' }}>
                    The remaining 70% of the auction proceeds are given to the owner of the EraCons. This ensures that the creators or owners receive the majority share for their art and memories.
                  </Typography>
                </React.Fragment>}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
  },
  // {
  //   label: 'What is NFT?',
  //   description: `A Non-Fungible Token (NFT) is used to identify something or someone in a unique way. This type of Token is perfect to be used on platforms that offer collectible items, access keys, lottery tickets, numbered seats for concerts and sports matches, etc.`,
  // },
  // {
  //   label: 'What is ERC-721?',
  //   description: 'ERC-721 is an Ethereum-based token standard for NFT, this type allows the digital representation of unique collectibles. This NFT creates intrinsic asset value for your unique collectibles that can be sold for millions of dollars.',
  // },
  // {
  //   label: 'What is Metamask?',
  //   description: <Grid container>
  //     <Grid item xs={12}>
  //       MetaMask is one of the top crypto wallets and can be used on your browser. As a crypto wallet, you will be able to hold digital currency and track your transactions.
  //     </Grid>
  //     <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
  //       Here is a video to show how you can create a MetaMask wallet:
  //     </Grid>
  //     <Grid item xs={12} sx={{ mb: 1 }}>
  //       <iframe width="604" height="340" src="https://www.youtube.com/embed/Af_lQ1zUnoM" title="MetaMask Tutorial for Beginners - How to Set Up MetaMask" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //     </Grid>
  //     <Grid item xs={12}>
  //       For further information, please visit <a href="https://metamask.io/faqs/" target="_blank" style={{textDecoration: 'none'}} >Metamask FAQ</a>.
  //     </Grid>
  //   </Grid>
  //   ,
  // },
  // {
  //   label: 'How do I reserve a Whitelist spot?',
  //   description: <Grid container>
  //     <Grid item xs={12}>
  //       Here are the steps on how you can reserve a Whitelist spot:
  //     </Grid>
  //     <Grid item xs={12} sx={{ mt: 1 }}>
  //       <iframe width="604" height="340" src="https://www.youtube.com/embed/naa8fmUrfRY" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //     </Grid>
  //   </Grid>,
  // },
  // {
  //   label: 'How do I Mint my Cybergems token?',
  //   description: <Grid container>
  //     <Grid item xs={12}>
  //       Here are the steps in minting your Cybergems on minting day:
  //     </Grid>
  //     <Grid item xs={12} sx={{ mt: 1 }}>
  //       <iframe width="604" height="340" src="https://www.youtube.com/embed/M3llbxjJ3-8" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //     </Grid>
  //   </Grid>
  //   ,
  // },
  // {
  //   label: 'Where can I see my Cybergems?',
  //   description: `If you own a Cybergems NFT, you can see your ownership of the NFT in your MetaMask wallet and also, Cybergems Catalog and OpenSea listing.`,
  // },
  // {
  //   label: 'How do I rename my Gems?',
  //   description: <Grid container>
  //     <Grid item xs={12}>
  //       You can rename the gems before minting; however, there is a gas fee if you want to rename the minted gems again.
  //     </Grid>
  //     <Grid item xs={12} sx={{ mt: 1 }}>
  //       <iframe width="604" height="340" src="https://www.youtube.com/embed/JVs7cEulzLA" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //     </Grid>
  //   </Grid>,
  // },
  // {
  //   label: 'How do I get proof of ownership?',
  //   description: <Grid container>
  //     <Grid item xs={12}>
  //       You can request proof of ownership after you have minted your gem. Here's how:
  //     </Grid>
  //     <Grid item xs={12} sx={{ mt: 1 }}>
  //       <iframe width="604" height="340" src="https://www.youtube.com/embed/B0AxfVSYcUw" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  //     </Grid>
  //   </Grid>,
  // },
  // {
  //   label: 'How do I claim whitelist spot from raffle?',
  //   description: `The winners will be contacted by the Cybergems NFT administrator via DM [Direct Message] on Twitter, and instructions for claiming the prize will be sent via email.`,
  // },
  // {
  //   label: 'Is it safe to connect my wallet to Cybergems?',
  //   description: `Yes, connecting to Cybergems does not grant us the ability to perform transactions on your behalf. We only ask that you sign a message to confirm that you are the rightful owner of the wallet.`,
  // },
  // {
  //   label: 'I have encountered an issue. Who can I reach out to?',
  //   description: `Please contact our support team by sending an email to inquiry@cybergems.com or sending us a direct message on Twitter @cybergemsNFT.`,
  // },
];

export { faqSteps }