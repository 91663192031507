import React, { useState } from "react";
import { Box, Typography, Snackbar, Fab, CardMedia, Card, IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import useWidth from "../hooks/useWidth";
import MintedGemImage from "../images/final_gem_logo.png";
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import gemLogo from "../images/final_gem.png"


const GemComingSoonComponent = () => {

    const url = window.location.pathname;

    const navigate = useNavigate();
    const breakpoints = useWidth();

    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [email, setEmail] = useState("");

    const handleClick = () => {
        window.open('https://presale.magiceden.io/pay/661f2e4468628cb92ed3f0e6', '_blank');
    }

    // const handleEmailUs = (e) => {
    //     window.location.href = 'mailto:inquiry@cybergems.com';
    // }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('EMAIL', email);

            const response = await fetch("https://cybergems.us18.list-manage.com/subscribe/post?u=97bed3c9dc97bb34e235d92bc&id=e3ed7d583d&f_id=006cc2e1f0", {
                method: "POST",
                body: formData,
                mode: "no-cors"
            });

            if (response) {
                setSnackbarMessage("Thank you for subscribing!");
                clearInput();
            }

        } catch (error) {
            console.error("Subscription Error:", error);

            setSnackbarMessage("Failed to subscribe");

        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    }

    const clearInput = () => {
        setEmail("");
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }


    const [minimized, setMinimized] = useState(false);

    const toggleMinimize = () => {
        setMinimized(!minimized);
    };

    const [displayContent, setDisplayContent] = useState(false);

    const toggleDisplayContent = () => {
        console.log("herexxx")
        setDisplayContent(!displayContent);
    };


    return (
        <Box sx={{ width: "100%", margin: "auto", overflowX: "hidden", maxHeight: '100vh', height: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : '80vh', alignSelf: 'center', alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
            <Box sx={{ width: "90%", margin: "auto", display: "inline-grid", alignSelf: 'center', alignItems: 'center' }}>
                {/* {(breakpoints == "xs" || breakpoints == "sm") && 
                    <Typography className="blink" sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 16, textTransform: "none", fontFamily: "GucciSans",
                            color: "#fff", mt: "90px", fontWeight: 700
                    }}>
                        ALPHA is LIVE!
                    </Typography>
                }
                <br/> 
                */}
                <img alt="CyberGems - A collection of 8,888 NFT gems" src={MintedGemImage}
                    style={{
                        height: (breakpoints == "xs" || breakpoints == "sm") ? "40vh" : "40vh",
                        margin: "auto", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : "-30px", marginBottom: "30px",
                    }}
                />

                <Typography sx={{
                    letterSpacing: "3px", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 24 : 30, fontFamily: 'GucciSans', mt: "-25px", fontWeight: "bold",
                    textShadow: "1px -1px 3px #15026100, -1px -1px 3px #150261cf, -1px 1px 3px #15026194, 1px 1px 3px #150261f2",
                    ml: "-9px"
                }}>
                    minting
                </Typography>

                <Typography sx={{
                    fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 20, fontFamily: 'GucciSans', mt: 2, letterSpacing: "0.45px",
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261, -2px 2px 5px #15026194, 2px 2px 5px #150261f2",
                    mb: "5px"
                }}>
                    Begin your eternal story today.
                </Typography>

                <Typography sx={{
                    color: "#fff", fontFamily: 'GucciSans', fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 20,
                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261, -2px 2px 5px #15026194, 2px 2px 5px #150261f2", letterSpacing: "0.45px"
                }}>
                    Join the exclusive presale.
                </Typography>


                {!displayContent ?
                    <label>
                        <button className="btn-navigate" onClick={(e) => toggleDisplayContent(e)}
                            style={{
                                color: "#fff",
                                color: "#fff",
                                ml: 1,
                                mt: "-2px",
                                mr: "4px",
                                fontFamily: 'GucciSans',
                                textShadow: 'rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px',
                                width: "auto",
                                display: 'block',
                                fontSize: 18,
                                textAlign: 'center',
                                position: 'absolute',
                                bottom: '10%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Subscribe
                        </button>
                        {/* <Fab
                        color="primary"
                        aria-label="Toggle content" 
                        onClick={(e) => toggleDisplayContent(e)}
                        sx={{
                            backgroundColor: '#111F37',
                            border: '2px solid #fff',
                            display: 'flex',
                            position: 'absolute',
                            bottom: '5%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: 50 }}
                            image={gemLogo}
                            alt="Gem Logo"
                        />
                    </Fab> */}
                    </label>
                    :

                    <Card sx={{ zIndex: 999, backgroundColor: '#111F37', border: '2px solid #fff', display: 'flex', position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, borderRadius: 4 }}>
                        <IconButton sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }} onClick={toggleDisplayContent}>
                            {!displayContent ? <MaximizeIcon fontSize='small' /> : <MinimizeIcon fontSize='small' />}
                        </IconButton>

                        <div style={{ overflow: 'hidden', width: '100%', height: breakpoints == "xs" || breakpoints == "sm" ? '580px' : '655px', borderRadius: 4 }}>
                            <iframe
                                aria-label='Welcome to the CyberGems Community!'
                                frameborder="0"
                                scrolling="no"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: 4
                                }}
                                src='https://forms.zohopublic.com/marketing428/form/EventRegistration/formperma/S4yNP-3ZV_bMe5JSXcJxu4OkYA26iQMXzNcLcocPUy0'>
                            </iframe>
                        </div>

                    </Card>
                }



                {/* {(breakpoints == "xs" || breakpoints == "sm") && 
                    <Box sx={{textAlign: "center", mt: 2.4, width: "100%"}}>
                        <Snackbar
                            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                            open={snackbarOpen}
                            autoHideDuration={2000}
                            onClose={handleCloseSnackbar}
                            message={snackbarMessage}
                        />

                        <div class="form-input" style={{fontFamily: "GucciSans", display: "inline-block"}}>
                            <label for="email"></label>

                            <form onSubmit={handleSubmit} style={{textAlign: "center"}}>
                                <input type="email" placeholder="Your Email" 
                                    style={{
                                        fontFamily: "GucciSans",
                                        padding: "0.8rem 1rem",
                                        width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "15%",
                                        borderRadius: "5rem",
                                        outline: "none",
                                        border: "0.1rem solid #017af4",
                                        fontSize: "14px",
                                        boxShadow: "0 0 10px #017af4",
                                        height: "40px",
                                        margin: "0 auto", 
                                        display: "block" 
                                    }} 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                /> 
                                                
                                <button type="submit" className="btn-navigate" 
                                    style={{
                                        color: "#fff", 
                                        margin: "0 auto",
                                        mt: "-2px", 
                                        fontFamily: 'GucciSans', 
                                        textShadow: 'rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px',
                                        display: "block",
                                        width: "100%",
                                        marginTop: "10px"
                                    }}
                                >
                                    {loading ? "..." : "Subscribe"}
                                </button>
                            </form>
                        </div>
                    </Box>
                }


                {(breakpoints == "md" || breakpoints == "lg" || breakpoints == "xl") && 
                    <Box sx={{textAlign: "center", mt: 3, justifyContent: "center", display: "flex", flexDirection: "column", width: "100%"}}>
                        <Snackbar
                            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                            open={snackbarOpen}
                            autoHideDuration={2000}
                            onClose={handleCloseSnackbar}
                            message={snackbarMessage}
                        />

                        <div class="form-input" style={{fontFamily: "GucciSans"}}>
                            <label for="email"></label>

                            <form onSubmit={handleSubmit}>
                                <input type="email" placeholder="Your Email" 
                                    style={{
                                        fontFamily: "GucciSans",
                                        padding: "0.8rem 1rem",
                                        width: (breakpoints == "xs" || breakpoints == "sm") ? "50%" : "15%",
                                        borderRadius: "5rem",
                                        outline: "none",
                                        border: "0.1rem solid #017af4",
                                        fontSize: "14px",
                                        boxShadow: "0 0 10px #017af4",
                                        height: "40px",
                                    }} 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                /> 
                                
                                &nbsp;

                                <button type="submit" className="btn-navigate" 
                                    style={{
                                        color: "#fff", 
                                        ml: 1, 
                                        mt: "-2px", 
                                        mr: "4px", 
                                        fontFamily: 'GucciSans', 
                                        textShadow: 'rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px' 
                                    }}
                                >
                                {loading ? "..." : "Subscribe"}
                                </button>
                            </form>
                        </div>
                    </Box>
                } */}

                {(breakpoints == "xs" || breakpoints == "sm") &&
                    <Typography onClick={(e) => navigate("/series/alpha")} className="blink" sx={{
                        cursor: 'pointer', fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 16, textTransform: "none", fontFamily: "GucciSans",
                        color: "#fff", mt: "20px", fontWeight: 700
                    }}>
                        ALPHA is LIVE!
                    </Typography>
                }

                {/* <div style={{marginTop: (breakpoints == "xl") ? "45px" : (breakpoints == "xs" || breakpoints == "sm") ? "80px" : "16px", marginBottom: "8px" }}>
                <FooterComponent />
            </div> */}

                {/* <button className="btn-navigate"
                    onClick={(e) => handleEmailUs(e)}
                    style={{ height: "38px" }}
                >
                    <Typography sx={{ color: "#fff", ml: 1, mt: "-2px", mr: "4px", fontFamily: 'GucciSans', textShadow: 'rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px' }}>
                        Email Us
                    </Typography>
                </button> 
            */}

            </Box>
        </Box>
    )
}

export default GemComingSoonComponent