import { useState, useEffect, useRef } from 'react';
import CgNftService from '../services/CgNftService';
import ApiService from '../services/apiService';
import useAPIRequest from "../hooks/useAPIRequest";
import useApiRequestForCOA from './useAPIRequestForCOA';
import { enqueueSnackbar } from 'notistack';

function useCybergemNft(){

    const [isLoading, setIsLoading] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef(new AbortController()); // for cancelling api requests to be triggered on view

    // const {isLoading: isLoadingCybergemList, data: cybergemListData, error: errorCybergemList, makeAPIRequest: fetchCybergemList } = useAPIRequest()
    const {isLoading: isLoadingCybergemInfo, data: cybergemInfoData, error: errorCybergemInfo, makeAPIRequest: fetchCybergemInfo } = useAPIRequest()
    const {isLoading: isLoadingAttachMemory, data: cybergemAttachMemory, dataSuccess: successAttachment, error: errorCybergemAttachMemory, makeAPIRequest: fetchCybergemAttachMemory } = useAPIRequest()
    // const {isLoading: isLoadingCybergemDetailView, data: cybergemDetailView, error: errorCybergemDetailView, makeAPIRequest: fetchCybergemDetailView} = useAPIRequest();
    const {isLoading: isLoadingCybergemDownloadCOA, data: cybergemDownloadCOA, error: errorCybergemDownloadCOA, makeAPIRequest: fetchCybergemDownloadCOA} = useApiRequestForCOA();
    const {isLoading: isLoadingCybergemDetailView, data: cybergemDetailViewData, error: errorCybergemDetailView, makeAPIRequest: fetchCybergemDetailView} = useAPIRequest();
    const {isLoading: isLoadingRetryCOA, data: cybergemRetryCOA, error: errorRetryCOA, makeAPIRequest: fetchRetryCOA} = useApiRequestForCOA();
    const {isLoading: isLoadingImageViewer, dataSuccess: cybergemMemoryViewer, error: errorMemoryViewer, makeAPIRequest: fetchMemoryViewer} = useAPIRequest();

    // useEffect(()=>{
    //     return () => {
    //         abortControllerRef.current.abort(); // Abort the ongoing request when the component unmounts or the effect re-runs
    //     };
    // }, [])

    const attachMemory = async (tokenId, memory, thumbnail, memorytitle) => {
        fetchCybergemAttachMemory(CgNftService.attachMemory(tokenId, memory, thumbnail, memorytitle));
    };

    // const getCybergemList = async (page, limit) => {
    //     fetchCybergemList(CgNftService.getCybergemList(page, limit));
    // }

    const getCybergemInfo = async () => {
        fetchCybergemInfo(CgNftService.getCybergemInfo());
    }

    const getViewCybergem = async (token_id) => {
       fetchCybergemDetailView(CgNftService.getViewCybergem(token_id));
    }

    const cancelApiRequest = () => {
        ApiService.cancelRequest();
    };

    const downloadCOA = async (token_id) => {
        fetchCybergemDownloadCOA(CgNftService.downloadCOA(token_id));
    };

    const retryCOA = async (token_id) => {
        fetchRetryCOA(CgNftService.retryCOA(token_id));
    };

    const getMemoryImage = async (cid) => {
        fetchMemoryViewer(CgNftService.getMemoryImage(cid));
    };


    return { data, isLoading, error, cancelApiRequest, 
        // getCybergemList, cybergemListData, 
        getCybergemInfo, cybergemInfoData,
        attachMemory, cybergemAttachMemory, isLoadingAttachMemory, successAttachment,
        downloadCOA, cybergemDownloadCOA, isLoadingCybergemDownloadCOA, errorCybergemDownloadCOA,
        getViewCybergem, cybergemDetailViewData, isLoadingCybergemDetailView, errorCybergemDetailView,
        retryCOA, cybergemRetryCOA, isLoadingRetryCOA, errorRetryCOA,
        getMemoryImage, cybergemMemoryViewer
    };

}

export default useCybergemNft;