import React from "react";
import {Skeleton, Paper, Grid, Typography, List, ListItemText} from "@mui/material";

const GemDetailsPageViewSkeleton = ({isDarkMode, breakpoints}) => {

    return (
        <>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5}>
                    <Skeleton animation="wave" variant="rect" sx={{margin: "auto", height: 581, width: 400, borderRadius: "8px"}} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6.5} xl={6.5}>
                    <Grid sx={{display: "flex"}}>
                        <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                    </Grid>

                    <Grid sx={{textAlign: "start"}}>
                        <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                    </Grid>

                    <Grid>
                        <Paper elevation={0} sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: 4,
                            border: "1px solid #8080802e",
                            mt: 2,
                        }}>
                            <Grid container sx={{mt: "5px", padding: "10px 16px 10px 24px"}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans'}}>
                                    ABOUT
                                </Typography>

                                <Grid container sx={{mt: "10px", textAlign: "start"}}>
                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        VARIATION
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        SERIES
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        TOKEN ID
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{textAlign: "start"}}>
                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        MEMORY TITLE
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {},
                                                    }}>
                                                        OWNER
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: 4,
                            border: "1px solid #8080802e",
                            mt: 2,
                        }}>
                            <Grid container sx={{mt: "5px", padding: "10px 16px 10px 24px"}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans'}}>
                                    PROPERTIES
                                </Typography>

                                <Grid container sx={{mt: "10px", textAlign: "start"}}>
                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        BACKGROUND
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        GLOW
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        GLITTER
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: 4,
                            border: "1px solid #8080802e",
                            mt: 2,
                        }}>
                            <Grid container sx={{mt: "5px", padding: "10px 16px 10px 24px"}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14, fontFamily: 'GucciSans'}}>
                                    STATS
                                </Typography>

                                <Grid container sx={{mt: "10px", textAlign: "start"}}>
                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, 
                                                    }}>
                                                        LEVEL
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {},
                                                    }}>
                                                        CLARITY
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {}, fontFamily: 'GucciSans'}}>
                                                        MULTIPLIER
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{textAlign: "start"}}>
                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {},
                                                    }}>
                                                        CARAT
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <List sx={{width: "100%", pt: "0px !important", pb: "0px !important"}}>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{fontSize: 12, fontFamily: 'GucciSans',
                                                        // color: (isDarkMode === "false") ? "rgb(0 0 0 / 45%)" : {},
                                                    }}>
                                                        RATING
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Skeleton animation="wave" variant="text" sx={{width: "60%"}} />
                                                } 
                                            />
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid sx={{mt: 2}}>
                        <Skeleton animation="wave" variant="rect" sx={{height: "45px", borderRadius: "8px"}} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GemDetailsPageViewSkeleton