const convertFileSizeToBytes = (size) => {

    const parts = size.split(/([KMGT])/i);
    const number = parseFloat(parts[0]);
    const unit = parts[1].toLowerCase();

    let bytes;

    switch (unit) {
        case "k":
            bytes = number * 1000;
        break;

        case "m":
            bytes = number * 1000 * 1000;
        break;

        case "g":
            bytes = number * 1000 * 1000 * 1000;
        break;

        case "t":
            bytes = number * 1000 * 1000 * 1000 * 1000;
        break;

        default:
            bytes = number;
        break;
    }
    
    return bytes;
}

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export {
    convertFileSizeToBytes,
    formatBytes
}
