import {Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

const ErrorMemoryDialog = (props) => {

    const {openErrorMemoryDialog, handleClose} = props;

    const navigate = useNavigate()

    return (
        <Dialog open={openErrorMemoryDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {borderRadius: 5, margin: "auto"}}}>
            <DialogContent className="btn-error" sx={{p: 2, textAlign: "center", borderRadius: 0}}>
                <ErrorIcon sx={{color: "#fff", height: 50, width: 50}} />
            </DialogContent>

            <DialogContent sx={{textAlign: "center", mt: 2, mb: 0}}>
                <Typography sx={{color: "gray", fontSize: 16, fontFamily: 'GucciSans'}}>
                    No memory attachments detected.
                </Typography>
            </DialogContent>

            <DialogActions sx={{justifyContent: "center", mb: 2}}>
                <button className="btn-error" onClick={(e) => navigate('/')} style={{fontFamily: 'GucciSans'}} >
                   Close
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorMemoryDialog