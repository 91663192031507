import {useState} from "react";
import {log} from "../console-config.log";

const useSessionStorage = () => {

    const [storedCacheData, setStoredCacheData] = useState(null);
    const [isFetchingCache, setFetchingCache] = useState(false);

    // CREATE
    const storeDataInSessionStorage = async (data) => {

        var ownedTokensString = JSON.stringify(data);

        try {
            sessionStorage.setItem("ownedTokensCached", ownedTokensString);
        }  catch (error) {
            log("Error storing data in cache:", error);

            setFetchingCache(false);
        }
    }

    // READ
    const retrieveDataFromSessionStorage = async () => {
        setFetchingCache(true);

        let cachedTokens = sessionStorage.getItem("ownedTokensCached");

        if (cachedTokens) {
            setFetchingCache(false);

            return cachedTokens;

        } else {
            setFetchingCache(false);

            return null;
        }
    }

    // DELETE
    const deleteDataFromSessionStorage = async (cacheName) => {
        try {
           
            sessionStorage.removeItem("ownedTokensCached");
            log(`Cache "${cacheName}" deleted.`);
        
        } catch (error) {
            log(`Error deleting cache "${cacheName}":`, error);
        }
    }


    return {storeDataInSessionStorage, retrieveDataFromSessionStorage, 
        deleteDataFromSessionStorage, storedCacheData, isFetchingCache
    }
}

export default useSessionStorage