import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ProfileComponent from "../component/profile/profile.component";
import useAuth from "../hooks/useAuth";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useWidth from "../hooks/useWidth";
import ErrorProfileDialog from "../component/dialog/error-message/error-profile.dialog";

const ProfileScreen = () => {

    const navigate = useNavigate();
    const {profile, isFetchingProfile, getProfile, errorProfile} = useAuth();

    const breakpoints = useWidth();

    const [showErrorDialog, setShowErrorDialog] = useState(false);

    // const storedToken = localStorage.getItem("auth_token");

    useEffect(() => {
        getProfile();
    }, [])

    useEffect(() => {
        if (errorProfile) {
            setShowErrorDialog(true);
        }
    }, [errorProfile])

  
    return (
        <>
            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "95%" : "85%", margin: "auto", mb: "30px", overflowX: "hidden"}}>
                <Box sx={{width: "90%", margin: "auto", mb: 2}}>   
                    <Grid sx={{textAlign: "start"}}>
                        <IconButton sx={{ml: "-10px"}} onClick={(e) => navigate(-1)}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>

                    <ProfileComponent 
                        profile={profile} 
                        isFetchingProfile={isFetchingProfile} 
                        breakpoints={breakpoints} 
                    />

                    {showErrorDialog && 
                        <ErrorProfileDialog 
                            showErrorDialog={showErrorDialog} 
                            setShowErrorDialog={setShowErrorDialog}
                        />
                    }
                </Box>
            </Box>
        </>
    )
}   

export default ProfileScreen