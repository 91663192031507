import React from "react";
import {Grid, Skeleton} from "@mui/material";
import useWidth from "../../hooks/useWidth";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{ popper: className }} />

    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[0.5],
            fontSize: 18,
            fontWeight: "bold",
            fontFamily: "GucciSans",
            textTransform: "capitalize",
        },
        [`& .${tooltipClasses.arrow}`]: {
            "&::before": {
                color: "#fff"
            }
        }
    })
);

const AllSeriesComponent = (props) => {

    const {isImageLoading, handleImageLoad, seriesImageSources} = props;

    const breakpoints = useWidth();
    const navigate = useNavigate();
   
    return (
        <Grid container sx={{mt: "10px", display: "block"}}>
            <div style={{display: "flex", justifyContent: "center", height: (breakpoints == "xs" || breakpoints == "sm") ? {} : "177px"}}>
                {seriesImageSources.slice(0, 3).map((series, index) => (
                    <div key={index}>
                        {!isImageLoading[index] && 
                            <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
                        }

                        <LightTooltip title={(index == 0) ? "Alpha" : series.series} arrow key={index} placement="bottom">
                            <img
                                src={series.image}
                                alt="CyberGems - A collection of 8,888 NFT gems"
                                style={{
                                    width: "100%", 
                                    height: "100%", 
                                    marginBottom: 0, 
                                    marginRight: 0, 
                                    transition: "transform 0.3s ease", 
                                    cursor: "pointer" 
                                }}
                                onLoad={() => handleImageLoad(index)}
                                onClick={() => navigate(`/series/${series.series}`)}
                            />
                        </LightTooltip>
                    </div>
                ))}
            </div> 
            
            <div style={{display: "flex", justifyContent: "center", height: (breakpoints == "xs" || breakpoints == "sm") ? {} : "177px"}}>
                {seriesImageSources.slice(3, 6).map((series, index) => (
                    <div key={index + 3}>
                        {!isImageLoading[index + 3] &&
                            <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
                        }

                        <LightTooltip title={series.series} arrow key={index} placement="bottom">
                            <img
                                src={series.image}
                                alt="CyberGems - A collection of 8,888 NFT gems"
                                style={{
                                    width: "100%", 
                                    height: "100%", 
                                    marginBottom: 0, 
                                    marginRight: 0, 
                                    transition: "transform 0.3s ease",
                                    cursor: "pointer"
                                }}
                                onLoad={() => handleImageLoad(index + 3)}
                                onClick={() => navigate(`/series/${series.series}`)}
                            />
                        </LightTooltip>
                    </div>
                ))}
            </div>

            <div style={{display: "flex", justifyContent: "center", height: (breakpoints == "xs" || breakpoints == "sm") ? {} : "177px"}}>
                {seriesImageSources.slice(6, 9).map((series, index) => (
                    <div key={index + 6}>
                        {!isImageLoading[index + 6] &&
                            <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
                        }
                        
                        <LightTooltip title={series.series} arrow key={index} placement="bottom">
                            <img
                                src={series.image}
                                alt="CyberGems - A collection of 8,888 NFT gems"
                                style={{
                                    width: "100%", 
                                    height: "100%",  
                                    marginBottom: 0, 
                                    marginRight: 0, 
                                    transition: "transform 0.3s ease", 
                                    cursor: "pointer" 
                                }} 
                                onLoad={() => handleImageLoad(index + 6)}
                                onClick={() => navigate(`/series/${series.series}`)}
                            />
                        </LightTooltip>
                    </div>
                ))}
            </div> 

            <div style={{display: "flex", justifyContent: "center", height: (breakpoints == "xs" || breakpoints == "sm") ? {} : "177px"}}>
                {seriesImageSources.slice(9, 12).map((series, index) => (
                    <div key={index + 9}>
                        {!isImageLoading[index + 9] &&
                            <Skeleton animation="wave" variant="rect" width="100%" height="100%" />
                        }

                        <LightTooltip title={series.series} arrow key={index} placement="top">
                            <img
                                src={series.image}
                               alt="CyberGems - A collection of 8,888 NFT gems"
                                style={{
                                    width: "100%", 
                                    height: "100%",  
                                    marginBottom: 0, 
                                    marginRight: 0, 
                                    transition: "transform 0.3s ease", 
                                    cursor: "pointer"
                                }}
                                onLoad={() => handleImageLoad(index + 9)}
                                onClick={() => navigate(`/series/${series.series}`)}
                            />
                        </LightTooltip>
                    </div>
                ))}
            </div>
        </Grid>
    )
}

export default AllSeriesComponent