import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Container, Grid, IconButton, Typography } from '@mui/material';
import CybergemLogo from '../icons/download.png';
import CybergemBanner from '../images/cybergem-banner.jpg'

import twitterIcon from "../icons/social-media/twitter-reverse.png";
import instagramIcon from "../icons/social-media/instagram-reverse.png";
import mediumIcon from "../icons/social-media/medium-reverse.png";
import useWidth from '../hooks/useWidth';
import EastIcon from "@mui/icons-material/East";
// import tiktokIcon from '../icons/social-media/tiktok.png';
// import youtubeIcon from '../icons/social-media/youtube.png';
// import discordIcon from '../icons/social-media/discord.png';

const CountdownItem = ({ value, label }) => (
  <Grid item>
    <Typography variant="h2">{value}</Typography>
    <Typography variant="subtitle1">{label}</Typography>
  </Grid>
);

const BackgroundCover = ({ imageUrl, children, breakpoints }) => {

  const backgroundStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh', // Set the height to cover the entire viewport
    width: '100%', // Set the width to cover the entire viewport
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    color: 'white',
    textAlign: 'center',
  };

  const handleClick = () => {
    window.open('https://forms.gle/XGPJcF12NZeBh2Yv5', '_blank');
  }


  const [isAndroidPhone, setAndroidPhone] = useState(false);
  const [isIOSPhone, setIOSPhone] = useState(false);

    useEffect(() => {
        const handleDeviceType = () => {
            const isAndroid = /Android/i.test(navigator.userAgent);
            const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

            setAndroidPhone(isAndroid);
            setIOSPhone(isIOS);
        }

        handleDeviceType();

        const resizeHandler = () => {
            handleDeviceType();
        }

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    }, []);

    const zoomLevel = window.devicePixelRatio || 1; // Get the device pixel ratio
    const marginTop = zoomLevel === 1.25 ? -300 : zoomLevel === 1.50 ? -310 : -450; // Adjust marginTop based on zoom level

  return (
    <div style={backgroundStyle}  >
      <Grid container className='container-coming' sx={{width: "100%", marginTop: isAndroidPhone ? "-380px" : isIOSPhone ? "-320px" : `${marginTop}px`, overflow: 'hidden' }}>
        <Grid item xs={12} sx={{ mb: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? "40px" : "75px"}}  >
          <img alt="CyberGems - A collection of 8,888 NFT gems" src={CybergemLogo} height={(breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? "75" : "80"} />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={{ justifyContent: "center", position: "relative", display: "flex", width: "100%" }}>
            <h3 style={{ fontFamily: "GucciSans", fontWeight: 'normal', textAlign: "center", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 20, width: (breakpoints == "xs" || breakpoints == "sm") ? "80%" : (breakpoints == "md") ? "40%" : "30%" }}>
              A collection of 8,888 NFT gems designed as time capsules, forever capturing life's precious memories.
            </h3>
          </Box>
        </Grid>
      </Grid>

      <div style={{position: "absolute", bottom: (breakpoints == "xl") ? 50 : (breakpoints == "lg") ? 45 : (breakpoints == "md") ? 30 : (breakpoints == "xs" || breakpoints == "sm") ? 120 : 100}}>
        <button class="btn-enter-grad" style={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "10px 12px" : "12px 20px"}} onClick={(e) => window.location.href = "/home"}>
          <span style={{fontFamily: "GucciSans", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "12px" : "14px"}}>
            Enter
          </span>   

        </button>
      </div>
      
      <div>
        {children}
      </div>
    </div>
  )
}

export default function ComingSoonScreen() {

  const breakpoints = useWidth();
 
  return (
    <BackgroundCover imageUrl={CybergemBanner} breakpoints={breakpoints}> 
    </BackgroundCover>
  );
}



