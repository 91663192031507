import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import AboutCybergemsComponent from "../component/about/about-cybergems.component";

import useWidth from "../hooks/useWidth";
import { useRef } from "react";

const AboutScreen = () => {

    const breakpoints = useWidth();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box
            sx={{
                padding: "16px 0px 16px 42px",
                pb: isSmScreen || isXsScreen ? 8 : "0px",
                paddingLeft: isSmScreen ? '5px' : '4rem',
                paddingRight: isSmScreen ? '5px' : '4rem',
                overflowY: "hidden",
            }}
        >
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AboutCybergemsComponent isSmScreen={isSmScreen} />
                </Grid> 
            </Grid>
        </Box>
    )
}

export default AboutScreen