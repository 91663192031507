import React from "react";
import { Box, Card, CardMedia, Fab, IconButton, Typography, Button } from "@mui/material";
import MinimizeIcon from '@mui/icons-material/Minimize';
import gemLogo from "../../images/final_gem.png";
import gemLogox from "../../images/gem_final.png";
import background from "../../images/form-bg.png";

const blinkingAnimation = `
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
`;

const MobileSubscribeFormComponent = ({ displayContent, toggleDisplayContent }) => {
    return (
        <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
            {displayContent &&
                <Box sx={{
                    position: 'relative',
                    textAlign: 'center',
                    zIndex: 10,
                    width: '350px',
                    paddingBottom: '5px',
                    maxWidth: '100%',
                }}>
                    <Fab
                        color="primary"
                        aria-label="Toggle content"
                        sx={{
                            backgroundColor: '#000',
                            border: '2px solid #000',
                            position: 'absolute',
                            top: '-30px',  // Position the Fab above the Card
                            transform: 'translateX(-50%)',
                            left: '50%',
                            zIndex: 1200, // Ensure the Fab is on top of the Card
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: 80 }}
                            image={gemLogox}
                            alt="CyberGems - A collection of 8,888 NFT gems"
                        />
                    </Fab>
                    <Card sx={{
                        zIndex: 999,
                        // backgroundImage: `url(${background})`,
                        // backgroundSize: 'cover',
                        // backgroundPosition: 'top',
                        // backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        color: '#000',
                        borderRadius: 4,
                        padding: 2,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <IconButton sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }} onClick={toggleDisplayContent}>
                            <MinimizeIcon fontSize='small' sx={{ color: '#fff' }} />
                        </IconButton>
                        <Box sx={{ overflow: 'hidden', width: '100%', height: '150px', borderRadius: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <style>{blinkingAnimation}</style> */}
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    letterSpacing: '0.5px',
                                    fontSize: '24px',
                                    // animation: 'blink 1s infinite',
                                    marginBottom: '8px',
                                    textAlign: 'center',
                                    // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    marginTop: '20px'
                                }}
                            >
                                Cybergems Public Sale
                            </Typography>
                            {/* <Typography sx={{ color: '#fff', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', marginBottom: '20px', fontSize: '18px', letterSpacing: '0.5px', textAlign: 'center' }}>Click here to join</Typography> */}
                            <Button
                                variant="contained"
                                sx={{ textTransform: 'none', backgroundColor: '#3f33ba', color: '#fff', borderRadius: 4 }}
                                onClick={() => window.location.href = 'https://opensea.io/collection/cybergems-official'}
                            >
                                Explore on OpenSea
                            </Button>
                        </Box>
                    </Card>
                </Box>
            }

            {!displayContent &&
                <label>
                    <Fab
                        color="primary"
                        aria-label="Toggle content" 
                        onClick={(e) => toggleDisplayContent(e)}
                        sx={{backgroundColor: '#000', border: '2px solid #000', display: 'flex', position: 'fixed', bottom: 30, right: 10}}
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: 50 }}
                            image={gemLogo}
                            alt="CyberGems - A collection of 8,888 NFT gems"
                        />
                    </Fab>
                </label>
            }
        </Box>
    );
}

export default MobileSubscribeFormComponent;
