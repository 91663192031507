import { Box, Button, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SeriesButtonGroupComponent = (props) => {

    const { imageSources, isSmScreen, hoveredImage, handleImageTitleHover, handleImageTitleHoverLeave, imageTitleHovered } = props;

    const navigate = useNavigate();

    const handleNavigateSeries = (e, src) => {
        if(src?.title === "Alpha") {
            navigate(`/series/${src?.title.toLowerCase()}`);
        } else {
            navigate('/minting')
        }
    }


    return (
        <>
            {isSmScreen ?
                <Box>
                    <ButtonGroup variant="text"
                        disableFocusRipple disableRipple
                        sx={{ '& .MuiButtonGroup-grouped:not(:last-child)': {borderBottomWidth: '10%',
                            borderColor: '#fff',
                            // borderColor: isDarkmode ? '#fff' : 'rgb(0 0 0 / 47%)',
                        }}}
                    >
                       {imageSources?.slice(0, 3).map((src, index) => (
                            <Button
                                key={src?.id} 
                                id={`button-${src?.id}`}
                                sx={{
                                    textTransform: index === 0 ? "uppercase" : "none",
                                    fontWeight: (hoveredImage == index || imageTitleHovered == src?.id) ? 550 : 500,
                                    color: (hoveredImage === index || imageTitleHovered === src?.id) ? '#2f2c91' : '#fff',
                                    padding: '1px 8px',
                                    fontSize: 16,
                                    lineHeight: 1,
                                    fontFamily: 'GucciSans',
                                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                                }}
                                onMouseEnter={() => handleImageTitleHover(index)}
                                onMouseLeave={handleImageTitleHoverLeave}
                                onClick={(e) => handleNavigateSeries(e, src)}
                            >
                                {src?.title}
                            </Button>
                        ))}
                    </ButtonGroup>

                    <br />

                    <ButtonGroup variant="text"
                        disableFocusRipple disableRipple
                        sx={{ mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': {borderBottomWidth: '10%',
                            borderColor: '#fff',  
                        }}}
                    >
                        {imageSources?.slice(3, 7).map((src, index) => (
                            <Button key={index} id={`button-${src?.id}`}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: (hoveredImage === index + 3 || imageTitleHovered === index + 3) ? 550 : 500,
                                    color: (hoveredImage === index + 3 || imageTitleHovered === index + 3) ? '#2f2c91' : "#fff",
                                    // color: (hoveredImage === index + 3 || imageTitleHovered === index + 3) ? '#2f2c91' : isDarkmode ? '#fff' : '#000',
                                    padding: '1px 8px', fontSize: 16, lineHeight: 1, fontFamily: 'GucciSans',
                                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                                }}
                                onMouseEnter={() => handleImageTitleHover(index + 3)}
                                onMouseLeave={handleImageTitleHoverLeave}
                                onClick={(e) => handleNavigateSeries(e, src)}
                            >
                                {src?.title}
                            </Button>
                        ))}
                    </ButtonGroup>

                    <br />

                    <ButtonGroup variant="text"
                        disableFocusRipple disableRipple
                        sx={{ mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': {borderBottomWidth: '10%',
                            borderColor: '#fff', 
                        }}}
                    >
                        {imageSources?.slice(7, 10).map((src, index) => (
                            <Button key={index} id={`button-${src?.id}`}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: (hoveredImage === index + 7 || imageTitleHovered === index + 7) ? 550 : 500,
                                    color: (hoveredImage === index + 7 || imageTitleHovered === index + 7) ? '#2f2c91' : '#fff',
                                    padding: '1px 8px', fontSize: 16, lineHeight: 1, fontFamily: 'GucciSans',
                                    textShadow: "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                                }}
                                onMouseEnter={() => handleImageTitleHover(index + 7)}
                                onMouseLeave={handleImageTitleHoverLeave}
                                onClick={(e) => handleNavigateSeries(e, src)}
                            >
                                {src?.title}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Box>

            :

                <Box>
                    <ButtonGroup variant="text"
                        disableFocusRipple disableRipple
                        sx={{ '& .MuiButtonGroup-grouped:not(:last-child)': {borderBottomWidth: '10%',
                            borderColor: '#fff',
                        }}}
                    >
                        {imageSources?.slice(0, 5).map((src, index) => (
                            <Button
                                key={index}
                                id={`button-${src?.id}`}
                                sx={{
                                    textTransform: (index == 0) ? "uppercase" : "none",
                                    fontWeight: (hoveredImage === src?.id || imageTitleHovered === src?.id) ? 550 : 500,
                                    color: (hoveredImage === src?.id || imageTitleHovered ===  src?.id) ? '#2f2c91' : '#fff',
                                    padding: '1px 8px', fontSize: 20, lineHeight: 1, fontFamily: 'GucciSans',
                                    textShadow: (hoveredImage === src?.id || imageTitleHovered === src?.id) ? {} : "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                                }}
                                onMouseEnter={() => handleImageTitleHover(src?.id)}
                                onMouseLeave={handleImageTitleHoverLeave}
                                onClick={(e) => handleNavigateSeries(e, src)}
                            >
                                {src?.title}
                            </Button>
                        ))}
                    </ButtonGroup>

                    <br />

                    <ButtonGroup variant="text"
                        disableFocusRipple disableRipple
                        sx={{ mt: 1, '& .MuiButtonGroup-grouped:not(:last-child)': {borderBottomWidth: '10%',
                            borderColor: '#fff', 
                            // borderColor: isDarkmode ? '#fff' : 'rgb(0 0 0 / 47%)', 
                        }}}
                    >
                        {imageSources?.slice(5, 10).map((src, index) => (
                            <Button key={index} id={`button-${src?.id}`}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: (hoveredImage === src?.id || imageTitleHovered === src?.id) ? 550 : 500,
                                    color: (hoveredImage === src?.id || imageTitleHovered === index + 5) ? '#2f2c91' : '#fff',
                                    padding: '1px 8px', fontSize: 20, lineHeight: 1, fontFamily: 'GucciSans',
                                    textShadow: (hoveredImage === src?.id || imageTitleHovered === src?.id) ? {} : "2px -2px 5px #15026100, -2px -2px 5px #150261cf, -2px 2px 5px #15026194, 2px 2px 5px #150261f2"
                                }}
                                onMouseEnter={() => handleImageTitleHover(src?.id)}
                                onMouseLeave={handleImageTitleHoverLeave}
                                onClick={(e) => handleNavigateSeries(e, src)}
                            >
                                {src?.title}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Box>
            }
        </>
    )
}

export default SeriesButtonGroupComponent